import React, { useEffect, useMemo, useState } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSeriesMatchID } from "./common/helper";
import { doScrollToTop, getSanitizedArray } from "../../../../../helpers/general";
import { matchInfo, matchObj } from "./common/ShimmerData";
import toss from "../../../../../Assets/toss.svg";
import serieslogo from "../../../../../Assets/series.svg";
import datelogo from "../../../../../Assets/date.svg";
import umpires from "../../../../../Assets/umpires.svg";
import thirdUmpire from "../../../../../Assets/thirdUmpire.svg";
import referee from "../../../../../Assets/referee.svg"
import Weather from "../../../NewsDetails/fantasyComponents/Weather"
import { MatchesCarousel } from "../../../NewsDetails/fantasyComponents/MatchCarousel"
import { logoData } from "../../../NewsDetails/fantasyComponents/constants";
import Playing11 from "../../MatchInstance/components/Playing11"
import usePost from "../../../../../hooks/usePost"
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { AnalyticsCategory, TrackGoogleAnalyticsEvent } from "../../../../../helpers/analytics";

const MatchInfo = () => {
  const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
  const { matchId } = getSeriesMatchID(seriesSlug, matchSlug);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectComparistionType, setSelectComparistionType] = useState("overall")
  const responce = usePost()
  const { data: votedata, error: voteError, isLoading: voteLoading } = responce
  const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/info`, {
    refreshInterval,
  });
  const LiveMatchStatus = data && data?.match?.status === "Live";
  useEffect(() => {
    if (LiveMatchStatus) {
      setRefreshInterval(5000);
    } else {
      setRefreshInterval(0);
    }
  }, [LiveMatchStatus]);
  const matchInfoData = isLoading ? matchInfo : data || {};
  const info = matchInfoData?.info;
  const pitchReport = info?.pitch_report;
  const pointTable = getSanitizedArray(info?.points_table);
  const teamFrom = getSanitizedArray(matchInfoData?.teamForm);
  const head2Head = matchInfoData?.head2head;
  const teamComparison = selectComparistionType === "overall" ? matchInfoData?.teamComparison : matchInfoData?.teamComparisonOnVenue
  const recentMatchesOnVenue = matchInfoData?.recentMatchesOnVenue;
  const head2HeadMatches = head2Head?.matches
  const matchData = isLoading ? matchObj : data?.match;
  const navigate = useNavigate()

  if (error) {
    return (
      <h2></h2>
    );
  }
  const shimmerClassName = matchInfoData?.isShimmer ? "bg-gray-300 loading text-gray-300" : ""
  const teamFromBgColor = {
    W: "bg-[#4AA450]",
    L: "bg-[#E06C6C]",
  };
  const handleVoteButton = (id) => {
    responce.makeRequest(id ? `v1/matches/${matchId}/vote` : null, { "teamId": id })
    TrackGoogleAnalyticsEvent(AnalyticsCategory.MATCH, 'match_vote', window.location.pathname)
  }
  return (
    <div className="mt-5 mx-[6px] md:mx-5">
      <div className="md:bg-white md:px-5 md:rounded-2xl md:border">
        {/* match info and umpires */}
        <div className="md:grid md:grid-cols-2 md:gap-4 ">
          <div>
            <div className="bg-[#F9FBFF] rounded-[14px]">
              <div id="match-info" className="my-5 rounded-[14px] border-[#DAE6F8] border">
                <div className="bg-[#E8F1FF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
                  <div className="flex items-center gap-1 md:justify-start justify-center col-span-2">
                    <span>Match Info</span>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-5 px-4 md:py-3 py-2 border-b border-[#DAE6F8] md:text-[16px] text-xs flex justify-between">
                  <div className="flex items-center col-span-2 gap-2">
                    <img src={toss} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                    <span className="opacity-50">Toss:</span>
                  </div>
                  <p className={`md:col-span-3 md:font-semibold font-medium flex items-center`}>
                    {matchData?.toss}
                  </p>
                </div>
                <div className="md:grid md:grid-cols-5 grid-cols-3 px-4 md:py-3 py-2  border-b border-[#DAE6F8] md:text-[16px] text-xs flex justify-between">
                  <div className="flex items-center col-span-2 gap-1">
                    <img src={serieslogo} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                    <span className="opacity-50">Series:</span>
                  </div>
                  <Link to={`/series/${matchData?.tournament_slug}/schedule`} onClick={doScrollToTop}>
                    <p className={`md:col-span-3 col-span-2 md:font-semibold font-medium md:text-left flex items-center`}>{matchData?.tournament_name}</p>
                  </Link>
                </div>
                <div className="md:grid md:grid-cols-5 grid-cols-3 px-4 md:py-3 py-2 border-[#DAE6F8] md:text-[16px] text-xs flex justify-between">
                  <div className="flex items-center col-span-2 gap-1">
                    <img src={datelogo} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                    <span className="opacity-50">Date & Time:</span>
                  </div>
                  <p className={`md:col-span-3 col-span-2 md:font-semibold font-medium md:text-left flex items-center`}>{matchData?.date_start}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-[#F9FBFF] rounded-[14px]">
              <div id="umpires" className="my-5 rounded-[14px] border-[#DAE6F8] border">
                <div className="bg-[#E8F1FF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
                  <div className="flex items-center gap-1 md:justify-start justify-center col-span-2">
                    <span>Umpires</span>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-4 px-4 md:py-3 py-2 border-b border-[#DAE6F8] md:text-[16px] text-xs flex justify-between">
                  <div className="flex items-center col-span-2 gap-2">
                    <img src={umpires} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                    <span className="opacity-50">Umpires:</span>
                  </div>
                  <p className={`md:col-span-2 md:font-semibold font-medium flex items-center text-end max-w-[200px] sm:max-w-auto`}>
                    {matchData?.umpires}
                  </p>
                </div>

                <div className="md:grid md:grid-cols-4 grid-cols-5 px-4 md:py-3 py-2  border-b border-[#DAE6F8] md:text-[16px] text-xs flex justify-between">
                  <div className="flex items-center col-span-2 gap-1">
                    <img src={thirdUmpire} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                    <span className="opacity-50">Third Umpire:</span>
                  </div>
                  <p className={`md:col-span-2 col-span-3 md:font-semibold font-medium md:text-left flex items-center`}>{matchData?.thirdUmpires}</p>
                </div>
                <div className="md:grid md:grid-cols-4 grid-cols-3 px-4 md:py-3 py-2 border-[#DAE6F8] md:text-[16px] text-xs flex justify-between">
                  <div className="flex items-center col-span-2 gap-1">
                    <img src={referee} alt="icon" className="w-[24px] h-[24px] md:w-[40px] md:h-[40px]" />
                    <span className="opacity-50">Match Referee:</span>
                  </div>
                  <p className={`md:col-span-2 md:font-semibold font-medium md:text-left flex items-center`}>{matchData?.referee}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Who Will Win? */}
        {
          voteError ?
            <div></div>
            :
            (data?.match?.status !== 'Completed' && data?.match?.status !== "Cancelled") &&
            <div className="md:grid md:gap-5 md:grid-cols-3 bg-white p-4 md:p-0 rounded-2xl md:rounded-none border border-[#DAE6F8] md:border-none">
              <div className="text-center md:text-start">
                <div className="font-bold text-[20px] md:text-[28px]">Who Will Win?</div>
                <div className="text-xs leading-3 text-[#808080]">{votedata ? votedata?.data?.totalVotes : matchData?.vote_info?.totalVotes} votes</div>
              </div>
              <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] mt-3 md:hidden">
              </div>
              <div className="grid grid-cols-2 col-span-2 gap-5 mt-4 md:mt-0">
                <div className="bg-[#3A32D10F] border border-[#3A32D1] rounded-full flex justify-center items-center cursor-pointer md:py-0 py-3 text-sm md:text-base" onClick={() => !votedata?.data?.isVoted && !matchData?.vote_info?.isVoted && handleVoteButton(matchData?.teama?.team_id)}>
                  {votedata?.data?.isVoted || matchData?.vote_info?.isVoted ?
                    <div className={`text-[#525252] font-bold`}>
                      {matchData?.teama?.short_name} : {!votedata ? matchData?.vote_info[matchData?.teama?.team_id]?.vote_per : votedata?.data?.[matchData?.teama?.team_id]?.vote_per || 0}%
                    </div>
                    : matchData?.teama?.short_name}
                </div>
                <div className="bg-[#3A32D10F] border border-[#3A32D1] rounded-full flex justify-center items-center cursor-pointer md:py-0 py-3 text-sm md:text-base" onClick={() => !votedata?.data?.isVoted && !matchData?.vote_info?.isVoted && handleVoteButton(matchData?.teamb?.team_id)}>
                  {votedata?.data?.isVoted || matchData?.vote_info?.isVoted ?
                    <div className={`text-[#525252] font-bold`}>
                      {matchData?.teamb?.short_name} : {!votedata ? matchData?.vote_info[matchData?.teamb?.team_id]?.vote_per : votedata?.data?.[matchData?.teamb?.team_id]?.vote_per || 0}%
                    </div>
                    : matchData?.teamb?.short_name}
                </div>
              </div>
            </div>
        }
        {/* venue Stats */}
        <div className="bg-white rounded-2xl border-[#DAE6F8] border md:border-none mt-4">
          <div className="my-4">
            <div className="text-[20px] font-bold md:block hidden">Venue Stats</div>
            <div className="md:border rounded-xl mt-4 p-4 border-[#DAE6F8] md:bg-[#F9FBFF]">
              <div className="text-center md:text-[20px] text-[16px] font-bold pb-4">Venue Stats Win %</div>
              <div className="flex">
                <div>
                  <div className="md:text-[26px] text-[16px] font-extrabold text-[#4AA450]">{info?.venueWinnerStats?.batting_first?.win_per}%</div>
                  <div className="md:text-[13px] text-[10px] text-nowrap">Batting First</div>
                </div>
                <div className="flex items-center w-full px-2">
                  <div style={{
                    width: `${info?.venueWinnerStats?.batting_first?.win_per || 0}%`,
                  }} className={`h-[12px] mr-0.5 bg-[#4AA450] md:rounded-l-full`}></div>
                  <div style={{
                    width: `${info?.venueWinnerStats?.bowl_first?.win_per || 0}%`,
                  }} className={`h-[12px] ml-0.5 bg-[#E77C32] md:rounded-r-full`}></div>
                </div>
                <div className="text-right">
                  <div className="md:text-[26px] text-[16px] font-extrabold text-[#E77C32]">{info?.venueWinnerStats?.bowl_first?.win_per}%</div>
                  <div className="md:text-[13px] text-[10px] text-nowrap">Bowl First</div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="md:grid md:grid-cols-2 gap-4 md:pt-4 text-xs p-4 md:p-0 pt-0 md:text-base">
            <div className="grid grid-cols-4 bg-[#F9FBFF] border-[#D8E7FF] border rounded-xl">
              <div className="abc bg-[#E8F1FF] py-4 rounded-xl font-bold text-center">
                <div>1st</div>
                <div>Innings</div>
              </div>
              <div className="py-4 text-center">
                <div className="font-bold">{info?.venueStats?.inning_1?.avg_score}</div>
                <div>Avg Runs</div>
              </div>
              <div className="py-4 text-center">
                <div className="font-bold">{info?.venueStats?.inning_1?.avg_wicket}</div>
                <div>Avg Wkts</div>
              </div>
              <div className="py-4 text-center">
                <div className="font-bold">{info?.venueStats?.inning_1?.avg_strike_rate}</div>
                <div>Avg SR</div>
              </div>
            </div>
            <div className="grid grid-cols-4 bg-[#F9FBFF] border-[#D8E7FF] mt-4 md:mt-0 border rounded-xl">
              <div className="abc bg-[#E8F1FF] py-4 font-bold rounded-xl text-center ">
                <div>2nd</div>
                <div>Innings</div>
              </div>
              <div className="py-4 text-center">
                <div className="font-bold">{info?.venueStats?.inning_2?.avg_score}</div>
                <div>Avg Runs</div>
              </div>
              <div className="py-4 text-center">
                <div className="font-bold">{info?.venueStats?.inning_2?.avg_wicket}</div>
                <div>Avg Wkts</div>
              </div>
              <div className="py-4 text-center">
                <div className="font-bold">{info?.venueStats?.inning_2?.avg_strike_rate}</div>
                <div>Avg SR</div>
              </div>
            </div>
            {
              info?.venueStats?.inning_3 &&
              <div className="grid grid-cols-4 bg-[#F9FBFF] border-[#D8E7FF] border rounded-xl mt-4 md:mt-0">
                <div className="abc bg-[#E8F1FF] py-4 rounded-xl text-center font-bold">
                  <div>1st</div>
                  <div>Innings</div>
                </div>
                <div className="py-4 text-center">
                  <div className="font-bold">{info?.venueStats?.inning_3?.avg_score}</div>
                  <div>Avg Runs</div>
                </div>
                <div className="py-4 text-center">
                  <div className="font-bold">{info?.venueStats?.inning_3?.avg_wicket}</div>
                  <div>Avg Wkts</div>
                </div>
                <div className="py-4 text-center">
                  <div className="font-bold">{info?.venueStats?.inning_3?.avg_strike_rate}</div>
                  <div>Avg SR</div>
                </div>
              </div>
            }
            {
              info?.venueStats?.inning_4 &&
              <div className="grid grid-cols-4 bg-[#F9FBFF] border-[#D8E7FF] mt-4 md:mt-0 border rounded-xl">
                <div className="abc bg-[#E8F1FF] py-4 rounded-xl text-center font-bold">
                  <div>2nd</div>
                  <div>Innings</div>
                </div>
                <div className="py-4 text-center">
                  <div className="font-bold">{info?.venueStats?.inning_4?.avg_score}</div>
                  <div>Avg Runs</div>
                </div>
                <div className="py-4 text-center">
                  <div className="font-bold">{info?.venueStats?.inning_4?.avg_wicket}</div>
                  <div>Avg Wkts</div>
                </div>
                <div className="py-4 text-center">
                  <div className="font-bold">{info?.venueStats?.inning_4?.avg_strike_rate}</div>
                  <div>Avg SR</div>
                </div>
              </div>
            }
          </div>
        </div>
        {/* pitch report */}
        <div className="my-5 rounded-[14px] border-[#DAE6F8] border bg-white">
          <div className="bg-[#E8F1FF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold text-center md:text-start">
            Pitch Report
          </div>
          <div className="grid md:grid-cols-2">
            <div className="">
              <div className="md:text-[16px] text-[10px] md:grid md:grid-cols-3 p-4 border-y border-[#DAE6F8] leading-4 flex justify-between">
                <p>Pitch</p>
                <p className={`font-bold ${!pitchReport.pitch_condition && !shimmerClassName ? "opacity-0" : null} ${shimmerClassName} min-h-4 min-w-4`}>{pitchReport.pitch_condition || "0"}</p>
              </div>
              <div className="md:text-[16px] text-[10px] md:grid md:grid-cols-3 p-4 border-[#DAE6F8] leading-4 flex justify-between">
                <p>Batting Condition</p>
                <p className={`font-bold ${!pitchReport.batting_condition && !shimmerClassName ? "opacity-0" : null} ${shimmerClassName} min-h-4 min-w-4`}>{pitchReport.batting_condition || "0"}</p>
              </div>
            </div>
            <div className="">
              <div className="md:text-[16px] text-[10px] md:grid md:grid-cols-3 p-4 border-y md:border-l border-[#DAE6F8] leading-4 flex justify-between">
                <p>Pace Bowling</p>
                <p className={`font-bold ${!pitchReport.pace_bowling_condition && !shimmerClassName ? "opacity-0" : null} ${shimmerClassName} min-h-4 min-w-4`}>{pitchReport.pace_bowling_condition || "0"}</p>
              </div>
              <div className="md:text-[16px] text-[10px] md:grid md:grid-cols-3 md:border-l p-4 border-[#DAE6F8] leading-4 flex justify-between">
                <p>Spin Bowling:</p>
                <p className={`font-bold ${!pitchReport.spine_bowling_condition && !shimmerClassName ? "opacity-0" : null} ${shimmerClassName} min-h-4 min-w-4`}>{pitchReport.spine_bowling_condition || "0"}</p>
              </div>
            </div>
          </div>
        </div>
        {/* Weather Report */}
        <Weather data={info?.weather} page="matchInfo"/>
      </div>
      <div className="bg-white md:p-5 rounded-2xl md:border mt-5">
        <Playing11 />
      </div>
      <div>
        <div className="md:bg-white md:px-5 md:mt-5 md:rounded-2xl md:border">
          {/* point table */}
          {
            pointTable?.length > 0 &&
            <div className="my-5 rounded-[14px] border-[#DAE6F8] border bg-white">
              <div className="md:bg-[#E8F1FF] rounded-t-[16px] text-base md:text-2xl leading-6 p-4 font-bold text-center md:text-start">
                Points Table
              </div>
              <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] md:hidden"></div>
              <div className="grid md:grid-cols-4 grid-cols-6 p-[14px] leading-4 md:border-t border-[#DAE6F8] md:text-[16px] text-[12px] text-[#6C6C6C]">
                <div className="md:col-span-1 col-span-2">Teams</div>
                <div className="md:col-span-2 col-span-3 grid grid-cols-5 text-center">
                  <div>P</div>
                  <div>W</div>
                  <div>L</div>
                  <div>T</div>
                  <div>PTS</div>
                </div>
                <div className="col-span-1 text-center">NRR</div>
              </div>
              {pointTable.map((object, i) => (
                <div
                  className={`grid md:grid-cols-4 grid-cols-6 p-[14px] md:bg-[#F9FBFF] rounded-b-2xl items-center leading-4 md:border-t border-[#DAE6F8] md:text-[16px] text-[12px]`}
                  key={i}
                >
                  <div className="md:col-span-1 col-span-2 flex items-center">
                    <div className={`md:h-10 md:w-10 h-6 w-6 mr-3 ${shimmerClassName}`}>
                      {object.thumb_url && <img src={object.thumb_url} alt="logo" />}
                    </div>
                    <div className={`text-[12px] font-semibold md:text-[16px] ${shimmerClassName} min-h-4 min-w-10`}>
                      {object.title}
                    </div>
                  </div>
                  <div className="md:col-span-2 col-span-3 grid grid-cols-5 text-center">
                    <div className={`${shimmerClassName} min-h-4 min-w-4`}>{object.played}</div>
                    <div className={`${shimmerClassName} min-h-4 min-w-4`}>{object.win}</div>
                    <div className={`${shimmerClassName} min-h-4 min-w-4`}>{object.loss}</div>
                    <div className={`${shimmerClassName} min-h-4 min-w-4`}>{object.draw}</div>
                    <div className={`${shimmerClassName} min-h-4 min-w-4`}>{object.points}</div>
                  </div>
                  <div className={`col-span-1 text-center ${shimmerClassName} min-h-4 min-w-4`}>
                    {object.netrr}
                  </div>
                </div>
              ))}
              <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t from-[#e2e2e2ab] to-transparent md:hidden'>
              </div>
              <div className="md:hidden text-[#3A32D1] text-center py-3" onClick={() => navigate(`/series/${matchData?.tournament_slug}/matches/${matchData?.slug}/pointtable`)}>
                {'Full Table >'}
              </div>
            </div>
          }
          {/* head to head */}
          {
            head2Head &&
            <div className="mt-4 md:mt-0 bg-white rounded-2xl md:rounded-none border border-[#DAE6F8] md:border-none">
              <div className='px-5 md:py-8 py-4 '>
                <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center justify-center min-w-[50px]`}>
                  <span className="custom-border-left mr-3 md:mr-6 md:block hidden"></span>
                  <h2 className='text-[16px] md:text-2xl leading-5 md:leading-8'>Head To Head</h2>
                  <span className="custom-border-right ml-3 md:ml-6 md:block hidden"></span>
                </div>
                <p className={` text-center text-[#787878] md:text-base text-[8px]`}>(Last 10 Matches)</p>
              </div>
              <div className="rounded-xl md:bg-[#F9FBFF] border-[#D8E7FF] md:border relative">
                <div className="md:bg-[#E8F1FF] rounded-t-xl md:py-5 grid grid-cols-3 px-5 md:px-0 md:place-items-center md:border-b border-[#D8E7FF] pb-2 md:pb-0">
                  <div className="flex items-center gap-2">
                    <img src={head2Head?.teams?.[0].logo} alt="logo" className="md:w-8 md:h-8 w-[22px] h-[22px]" />
                    <div className="md:text-[20px] text-[10px] font-semibold">{head2Head?.teams?.[0]?.short_name}</div>
                  </div>
                  <div className="md:font-extrabold font-bold text-[16px] text-center md:text-[32px]">{head2Head?.teams?.[0]?.win_count} - {head2Head?.teams?.[1]?.win_count}</div>
                  <div className="flex items-center gap-2 justify-end">
                    <div className="md:text-[20px] text-[10px] font-semibold">{head2Head?.teams?.[1]?.short_name}</div>
                    <img src={head2Head?.teams?.[1]?.logo} alt="logo" className="md:w-8 md:h-8 w-[22px] h-[22px]" />
                  </div>
                </div>
                <div>
                  <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] md:hidden">
                  </div>
                  {
                    head2HeadMatches?.map(({ teama, teamb, status_note, tournament_slug, slug }, index) => (
                      <Link to={`/series/${tournament_slug}/matches/${slug}/matchinfo`} onClick={doScrollToTop}>
                        <div className={`grid grid-cols-3 md:place-items-center px-5 md:px-0 py-3 md:py-4 ${index === head2HeadMatches?.length - 1 ? "" : "md:border-b border-[#D8E7FF]"}`}>
                          <div>
                            <div className="md:text-[20px] text-[10px] font-semibold">{teama?.scores}</div>
                            <div className="md:text-sm text-[9px] text-[#808080]">({teama?.overs})</div>
                          </div>
                          <div>
                            <div className="md:text-xs text-[9px] md:px-24 text-center">{status_note}</div>
                          </div>
                          <div className="text-end">
                            <div className="md:text-[20px] text-[10px] font-semibold">{teamb?.scores}</div>
                            <div className="md:text-sm text-[9px] text-[#808080]">({teamb?.overs})</div>
                          </div>
                        </div>
                        {
                          head2HeadMatches?.length - 1 !== index &&
                          <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] my-2 md:hidden">
                          </div>
                        }
                      </Link>
                    ))
                  }
                </div>
                {!isExpanded && head2HeadMatches?.length > 3 && (
                  <div className="absolute bottom-0 left-0 right-0 flex rounded-b-xl items-end justify-center w-full md:pb-4 h-[150px] bg-gradient-to-t from-white to-transparent">
                    <button
                      onClick={() => setIsExpanded(true)}
                      className="text-[#3A32D1] font-semibold text-xs bg-white rounded-full md:py-3 py-2 md:px-8 px-4 shadow-lg flex items-center gap-2"
                    >
                      Full Head to Head
                      <MdOutlineKeyboardArrowDown />
                    </button>
                  </div>
                )}
              </div>
            </div>
          }
          {/* Team Comparision */}
          {
            teamComparison &&
            <div className="bg-white border mt-4 md:mt-4 rounded-2xl md:rounded-none border-[#DAE6F8] md:border-none">
              <div className='px-5 md:py-8 py-4'>
                <div className={`md:min-h-[18px] md:min-w-[180px] flex justify-center items-center min-w-[50px]`}>
                  <span className="custom-border-left mr-3 md:mr-6 md:block hidden"></span>
                  <h2 className='text-[16px] md:text-2xl leading-[20px] md:leading-[32px]'>Team Comparison</h2>
                  <span className="custom-border-right ml-3 md:ml-6 md:block hidden"></span>
                </div>
                <p className={`text-center text-[#787878] md:text-base text-[9px]`}>(Last 10 Matches)</p>
              </div>
              <div className="text-center mb-4">
                <button className={`md:px-4 px-2 text-xs md:text-base py-1 md:py-2 border rounded-lg ${selectComparistionType === "overall" ? "bg-[#3A32D1] text-white border-[#3A32D1]" : ""}`} onClick={() => setSelectComparistionType("overall")}>Overall</button>
                <button className={`md:px-4 px-2 text-xs md:text-base py-1 md:py-2 border rounded-lg ml-4 ${selectComparistionType === "onvenue" ? "bg-[#3A32D1] text-white border-[#3A32D1]" : ""}`} onClick={() => setSelectComparistionType("onvenue")}>On Venue</button>
              </div>
              <div className="rounded-xl md:bg-[#F9FBFF] md:border-[#D8E7FF] md:border px-5 md:px-0">
                <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] md:hidden">
                </div>
                <div className="md:bg-[#E8F1FF] md:rounded-t-xl py-4 md:py-7 grid grid-cols-3 md:place-items-center md:border-b border-[#D8E7FF]">
                  <div className="flex items-center gap-2">
                    <img src={teamComparison?.teama?.logo} alt="logo" className="md:w-7 md:h-7 w-[22px] h-[22px]" />
                    <div className="md:text-[20px] text-[9px] font-semibold">{teamComparison?.teama?.short_name}</div>
                  </div>
                  <div></div>
                  <div className="flex items-center gap-2 justify-end">
                    <div className="md:text-[20px] text-[9px] font-semibold">{teamComparison?.teamb?.short_name}</div>
                    <img src={teamComparison?.teamb?.logo} alt="logo" className="md:w-7 md:h-7 w-[22px] h-[22px]" />
                  </div>
                </div>
                <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] md:hidden">
                </div>
                <div>
                  <div className="grid grid-cols-3 md:place-items-center py-3 md:py-5 md:border-b border-[#D8E7FF]">
                    <div className="text-[10px] md:text-[20px] font-bold md:font-semibold">{teamComparison?.teama?.matches_played}</div>
                    <div className="text-[9px] md:text-[15px] md:font-semibold text-center">Match Played</div>
                    <div className="text-[10px] md:text-[20px] font-bold md:font-semibold text-end">{teamComparison?.teamb?.matches_played}</div>
                  </div>
                  <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] mt-2 md:hidden">
                  </div>
                  <div className="grid grid-cols-3 md:place-items-center py-3 md:py-5 md:border-b border-[#D8E7FF]">
                    <div className="text-[10px] md:text-[20px] font-bold md:font-semibold">{teamComparison?.teama?.matches_won}</div>
                    <div className="text-[9px] md:text-[15px] md:font-semibold text-center">Matches Won</div>
                    <div className="text-[10px] md:text-[20px] font-bold md:font-semibold text-end">{teamComparison?.teamb?.matches_won}</div>
                  </div>
                  <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] mt-2 md:hidden">
                  </div>
                  <div className="grid grid-cols-3 md:place-items-center py-3 md:py-5 md:border-b border-[#D8E7FF]">
                    <div className="text-[10px] md:text-[20px] font-bold md:font-semibold">{teamComparison?.teama?.average_score}</div>
                    <div className="text-[9px] md:text-[15px] md:font-semibold text-center">Avg Score</div>
                    <div className="text-[10px] md:text-[20px] font-bold md:font-semibold text-end">{teamComparison?.teamb?.average_score}</div>
                  </div>
                  <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] mt-2 md:hidden">
                  </div>
                  <div className="grid grid-cols-3 md:place-items-center py-3 md:py-5 md:border-b border-[#D8E7FF]">
                    <div className="text-[10px] md:text-[20px] font-bold md:font-semibold">{teamComparison?.teama?.highest_score}</div>
                    <div className="text-[9px] md:text-[15px] md:font-semibold text-center">Highest Score</div>
                    <div className="text-[10px] md:text-[20px] font-bold md:font-semibold text-end">{teamComparison?.teamb?.highest_score}</div>
                  </div>
                  <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] mt-2 md:hidden">
                  </div>
                  <div className="grid grid-cols-3 md:place-items-center py-3 md:py-5">
                    <div className="text-[10px] md:text-[20px] font-bold md:font-semibold">{teamComparison?.teama?.lowest_score}</div>
                    <div className="text-[9px] md:text-[15px] md:font-semibold text-center">Lowest Score</div>
                    <div className="text-[10px] md:text-[20px] font-bold md:font-semibold text-end">{teamComparison?.teamb?.lowest_score}</div>
                  </div>
                </div>
              </div>
            </div>
          }
          {/* Match Info */}
          <div id="team-form" className="my-5 rounded-[14px] border-[#DAE6F8] border">
            <div className="md:bg-[#E8F1FF] bg-[#FFFFFF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
              <div className="md:flex items-center gap-2 md:justify-start justify-center">
                <img src={logoData.teamForm.logo} alt={logoData.teamForm.heading} className="w-5 h-6 md:block hidden" />
                <p className="text-center">{logoData.teamForm.heading}</p>
                <p className="md:text-[10px] text-[9px] text-[#4F4F4F] font-normal md:text-start text-center">(Recent 5 Matches, Latest First)</p>
              </div>
            </div>
            <MatchesCarousel data={teamFrom} pageName={"matchInfo"} />
          </div>
        </div>


      </div>
      <div className="bg-white px-5 pb-5 mt-5 rounded-2xl border border-[#DAE6F8]">
        {/* recent matches */}
        {recentMatchesOnVenue &&
          <div>
            <div className='px-5 md:py-8 py-4'>
              <div className={`md:min-h-[18px] md:min-w-[180px] flex justify-center items-center min-w-[50px]`}>
                <span className="custom-border-left mr-3 md:mr-6 hidden md:block"></span>
                <h2 className='text-[16px] md:text-2xl leading-[20px] md:leading-[32px]'>Recent Matches</h2>
                <span className="custom-border-right ml-3 md:ml-6 hidden md:block"></span>
              </div>
              <p className={` text-center text-[#787878] md:text-base text-[9px]`}>On Venue</p>
            </div>
            <div>
              <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] md:hidden">
              </div>
              {
                recentMatchesOnVenue?.map(({ teama, teamb, tournament_title, status_note, winning_team_id, tournament_slug, slug }, index) => (
                  <div key={`${index}_${tournament_title}`} className={`${index !== 0 ? "md:mt-4" : ""}`}>
                    <Link to={`/series/${tournament_slug}/matches/${slug}/matchinfo`} onClick={doScrollToTop}>
                      <div className={`md:border border-[#DAE6F8] py-4 rounded-lg md:place-items-center grid grid-cols-3`}>
                        <div className="flex items-center gap-1 md:gap-2">
                          <img src={teama?.logo} alt="logo" className="md:w-9 md:h-9 w-[22px] h-[22px]" />
                          <div>
                            <div className="text-sm md:text-[20px] font-semibold">
                              {teama?.short_name}
                            </div>
                            <div className="md:text-sm text-[12px]">
                              {teama?.scores}
                              <span className="md:text-[10px] text-[9px] text-[#808080] ml-0.5">{teama?.overs}</span>
                            </div>
                          </div>
                        </div>
                        <div className="text-center col-span-1 md:px-0 px-2">
                          <div className="font-semibold md:text-base text-[10px] text-[#4aa450]">{status_note}</div>
                          <Link to={`/series/${tournament_slug}/schedule`}>
                            <div className="md:text-xs text-[9px] text-[#808080]">{tournament_title}</div>
                          </Link>
                        </div>
                        <div className="flex items-center gap-1 md:gap-2 text-end justify-end">
                          <div>
                            <div className="text-end text-sm md:text-[20px] font-semibold">{teamb?.short_name}</div>
                            <div className="md:text-sm text-[12px]">
                              <span className="md:text-[10px] text-[9px] text-[#808080] mr-0.5">{teamb?.overs}</span>
                              {teamb?.scores}
                            </div>
                          </div>
                          <img src={teamb?.logo} alt="logo" className="md:w-9 md:h-9 w-[22px] h-[22px]" />
                        </div>
                      </div>
                    </Link>
                    {
                      recentMatchesOnVenue.length - 1 !== index &&
                      <div className="bg-gradient-to-r from-[#DAE6F800] via-[#DAE6F8] to-[#DAE6F800] h-[1px] md:hidden">
                      </div>
                    }
                  </div>
                ))
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default MatchInfo;
