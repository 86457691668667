export const MatchFormatShortNames = {
    "Women T20": "W-T20",
    "Women T100": "W-T100",
    "Women ODI": "W-ODI",
    "First Class": "FC",
    "woment20": "W- T20",
    "t10": "T10"
}

const getNewsObj = () => {
  return {
    "isShimmer": true,
    "id": 0,
    "title": "",
    "slug": "",
    "image": "",
    "date": ""
  }
}

export const getClassByFormat = (format) => {
    const formatClasses = {
      "T20": "bg-[#C89037] bg-opacity-20 text-[#C89037]",
      "ODI": "bg-[#4D9FFF] bg-opacity-20 text-[#4D9FFF]",
      "TEST": "bg-[#6B63FF] bg-opacity-20 text-[#6B63FF]",
    };
    return formatClasses[format] || "bg-[#6B63FF] bg-opacity-20 text-[#6B63FF]";
};

export const shimmerObj = {
  "featured1": [
    getNewsObj(),
    getNewsObj(),
    getNewsObj()
  ],
  "featured2": [
    getNewsObj(),
    getNewsObj(),
    getNewsObj()
  ],
  "latestNews": [
    getNewsObj(),
    getNewsObj(),
    getNewsObj()
  ],
  "cricketNews": [
    getNewsObj(),
    getNewsObj(),
    getNewsObj()
  ]
}