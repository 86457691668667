import React, { useEffect, useState } from 'react'
import NewsList from '../../../../common/NewsList'
import { useParams } from 'react-router-dom';
import { useAxiosSWR } from "root/axiosInstance";
import { getSeriesMatchID } from './common/helper';
import { getSanitizedArray } from '../../../../../helpers/general';
import { mockNewsData } from './common/ShimmerData';

const News = () => {
  const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
  const { matchId } = getSeriesMatchID(seriesSlug, matchSlug);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/news`,{
    refreshInterval,
  });
  const LiveMatchStatus = data && data?.match?.status === "Live";
  useEffect(() => {
    if (LiveMatchStatus) {
      setRefreshInterval(5000);
    } else {
      setRefreshInterval(0);
    }
  }, [LiveMatchStatus]);
  const newsData = getSanitizedArray(isLoading ? mockNewsData : data?.info?.news)
  if (error) {
    return (
      <h2></h2>
    );
  }
  return (
    <div className="px-4">
      {/* News Data Comming Soon... */}
      <NewsList data={newsData}/>
    </div>
  )
}

export default News
