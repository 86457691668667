import React, { useRef, useState } from 'react'
import SeriesStatsCommon from '../../../Home/common/SeriesStats'
import { useParams } from 'react-router-dom';
import { getSeriesMatchID } from './common/helper';

const SeriesStats = () => {
    const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
    const { seriesId } = getSeriesMatchID(seriesSlug, matchSlug);
    return (
        <div className='md:p-5 md:bg-white rounded-2xl md:border mt-5 md:mx-5 mx-[6px]'>
            <SeriesStatsCommon seriesId={seriesId} />
        </div>
    )
}

export default SeriesStats
