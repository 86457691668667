import PropTypes from "prop-types";
import React, { useEffect } from "react";

const getVideoIdFromUrl = (url) => {
  // Regular expression to extract video ID from YouTube watch URL
  const youtubeUrlRegex =
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
  const match = url.match(youtubeUrlRegex);
  return match ? match[1] : null;
};
const YouTubeModal = ({ open, onClose, videoUrl }) => {
  const embedUrl = `https://www.youtube.com/embed/${getVideoIdFromUrl(
    videoUrl
  )}`;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, onClose]);

  return (
    <>
      {open && (
        <div className="fixed z-50 inset-0 overflow-y-auto flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-screen">
            <button
              onClick={onClose}
              className="absolute top-0 right-0 m-4 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#D8D8D8"
                x="0px"
                y="0px"
                width="30"
                height="30"
                viewBox="0 0 50 50"
              >
                <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
              </svg>
            </button>
            {embedUrl ? (
              <div
                className="
                w-full h-screen 
                xl:px-24 xl:py-12
                lg:px-16 lg:py-10
                md:px-12 md:py-8
                sm:px-16 sm:py-12
                px-10 py-7
                "
              >
                <div className="h-full w-full">
                  <iframe
                    className="w-full h-full"
                    src={embedUrl}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            ) : (
              <div className="text-white">Invalid YouTube URL</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

YouTubeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  videoUrl: PropTypes.string,
};

export default YouTubeModal;
