import React, { useEffect, useState } from 'react';
import { useAxiosSWR } from 'root/axiosInstance';
import { getId } from './helper';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../../../helpers/dates';
import SEOMeta from '../../Home/common/SEOMeta';
import { NewsContainer } from '../../../NewsV2';

const SeriesNews = () => {
  const { id: slug } = useParams();
  const seriesId = getId(slug);
  const { data, isLoading, error } = useAxiosSWR(`/v1/series/${seriesId}/news?type=top_story`);
  const newsData = data?.news || [];
  const [seoMeta, setSEOMeta] = useState(null);
  useEffect(() => {
    if (data?.series) {
      const seriesData = data.series;
      seriesData.game_format = seriesData.game_format || '';
      setSEOMeta({
        title: `${seriesData.title} ${seriesData.season} News | ${seriesData.game_format.toUpperCase()} | ${seriesData.abbr} | Cricketgully`,
        description: `News of ${seriesData.title} ${seriesData.season} scheduled on ${formatDate(seriesData.date_start, false)} to ${formatDate(seriesData.date_end, false)} | ${seriesData.game_format.toUpperCase()} | ${seriesData.abbr}`,
        canonicalURL: `/series/${seriesData.slug}/schedule`,
      });
    }
  }, [data]);

  const [pageNum, setPageNum] = useState(1);
  const hasNextPage = data?.total_pages && data?.total_pages > pageNum;

  const handleSeeMore = () => {
    if (hasNextPage) {
      setPageNum((prevPage) => prevPage + 1);
    }
  };

  if (isLoading) {
    return <div></div>;
  }
  if (error) {
    return <div></div>;
  }

  return (
    <div>
      {seoMeta && <SEOMeta data={seoMeta} />}
      <NewsContainer
        newsData={newsData}
        title='Top Stories'
        hasNextPage={hasNextPage}
        handleSeeMore={handleSeeMore}
        isLoading={isLoading}
        hasError={error}
        pageNum={pageNum}
      />
    </div>
  );
};

export default SeriesNews;
