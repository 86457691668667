import teamlogo from "../../../../Assets/team.svg";
import captainlogo from "../../../../Assets/player-cap.svg";
import userlogo from "../../../../Assets/user-banned.svg";
import cricketlogo from "../../../../Assets/cricket.svg";
import cricketlogo2 from "../../../../Assets/cricket2.svg";
import injurylogo from "../../../../Assets/injury.svg";
import pitchlogo from "../../../../Assets/pitch.svg";
import weatherlogo from "../../../../Assets/weather.svg";
import playingXI from '../../../../Assets/playingXI.svg';

export const TableContentsData = [
    {
      lable: "matchInfo",
      id:"match-info",
      logo: cricketlogo,
      heading: 'Match Info'
    },
    {
      lable: "teams",
      id:"match-teams",
      logo: cricketlogo2,
      heading: 'Teams'
    },
    {
      lable: "injuryNews",
      id:"injury",
      logo: injurylogo,
      heading: 'Injury News'
    },
    {
      lable: "teamForm",
      id:"team-form",
      logo: teamlogo,
      heading: 'Team Form'
    },
    {
      lable: "pitch",
      id:"pitch-report",
      logo: pitchlogo,
      heading: 'Pitch'
    },
    {
      lable: "c_vc",
      id:"captain-vice-captain",
      logo: captainlogo,
      heading: 'C & VC'
    },
    {
      lable: "weather",
      id:"weather-report",
      logo: weatherlogo,
      heading: 'Weather Report'
    },
    {
      lable: "avoidablePlayers",
      id:"avoidable-players",
      logo: userlogo,
      heading: 'Avoidable Players'
    },
];

  export const logoData = {
    matchInfo: {
      logo: cricketlogo,
      heading: 'Match Info'
    },
    teams: {
      logo: cricketlogo2,
      heading: 'Teams'
    },
    injury: {
      logo: injurylogo,
      heading: 'Injury'
    },
    pitchReport: {
      logo: pitchlogo,
      heading: 'Pitch Report'
    },
    weatherReport: {
      logo: weatherlogo,
      heading: 'Weather Report'
    },
    teamForm: {
      logo: teamlogo,
      heading: 'Team Form'
    },
    captainViceCaptain: {
      logo: captainlogo,
      heading: 'Captain & Vice Captain'
    },
    avoidablePlayers: {
      logo: userlogo,
      heading: 'Avoidable Players'
    },
    playerStats: {
      logo: null,
      heading: 'Players Stats in current series'
    }
  };
  