import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import TeamViewHome from "./TeamViewInstance/TeamViewHome";
import TeamViewRecent from "./TeamViewInstance/TeamViewRecentV2";
import TeamViewUpcoming from "./TeamViewInstance/TeamViewUpcomingV2";
import TeamViewVideos from "./TeamViewInstance/TeamViewVideos";
import TeamViewTeamPlayers from "./TeamViewInstance/TeamViewTeamPlayers";
import { useAxiosSWR } from "root/axiosInstance";
import { useHorizontalScroll } from "../../../../hooks/useHorizontalScroll";
import TeamViewNews from "./TeamViewInstance/TeamViewNews";
import AppContext, { URL_TYPES } from "src/context";
import { useLocation } from "react-router-dom";

const PAGES = [
  { key: "Home", value: "home" },
  { key: "Recent", value: "recent" },
  { key: "Upcoming", value: "upcoming" },
  { key: "News", value: "news" },
  { key: "Videos", value: "videos" },
  { key: "Team Players", value: "teamplayers" },
];

const SelectTeamView = () => {
  const { id: slug, type } = useParams();
  const [teamsPageType, setTeamsPageType] = useState(type || "home");
  const containerRef = useRef();
  useHorizontalScroll(containerRef, teamsPageType);

  useEffect(() => {
    setTeamsPageType(type || "home");
  }, [type]);

  const handleSeriesTypeChange = (type) => {
    setTeamsPageType(type);
  };

  const renderComponent = () => {
    switch (teamsPageType) {
      case "home":
        return <TeamViewHome />;
      case "recent":
        return <TeamViewRecent />;
      case "upcoming":
        return <TeamViewUpcoming />;
      case "news":
        return <TeamViewNews />;
      case "videos":
        return <TeamViewVideos />;
      case "teamplayers":
        return <TeamViewTeamPlayers />;
      default:
        return null;
    }
  };

  const teamId = useMemo(() => {
    if (slug) {
      return slug.slice(slug.lastIndexOf("-") + 1)
    }
    return null;
  }, [slug]);

  const { isLoading, data } = useAxiosSWR(teamId ? `/v1/teams/${teamId}` : null);

  const appContext = useContext(AppContext);
  const { pathname: pathName } = useLocation();
  const teamName = data?.name;
  useEffect(() => {
      const urlInfoObj = {
          type: URL_TYPES.TEAMS_HOME,
          path: pathName,
          reqState: {
            data: {
              title: teamName,
            }
          },
      };
      appContext.updateUrlInfo(urlInfoObj);
  }, [pathName, teamName]);

  return (
    <div className="">
      <div className="md:mt-4">
        <h1 className="md:text-[24px] text-[18px] leading-6 font-semibold mb-4 border md:bg-[#FFFFFF] bg-[#312AB7] md:pl-[30px] md:py-6 md:rounded-[20px] -mt-1">
          <div className="md:block hidden">
            <div className="flex items-center justify-center gap-2">
              <div className="h-[45px] w-[45px] rounded-full">
                {data?.logo && <img src={data.logo} alt="logo" height="45" width="45" />}
              </div>
              <div>{data ? data.name : (isLoading ? '' : '')}</div>
            </div>
          </div>
          <div className="flex">
            <div className="flex overflow-x-auto justify-center mx-auto">
              <div
                ref={containerRef}
                className="flex text-sm overflow-x-auto w-full hideScrollbar md:p-1 md:mt-2 text-nowrap gap-2"
              >
                {PAGES.map(({ key, value }) => (
                  <Link
                    to={`/teams/${slug}/${value}`}
                    key={key}
                    data-testid={value}
                  >
                    <div
                      className={`px-5 md:py-2 py-4 md:rounded-xl ${
                        teamsPageType === value
                          ? "text-[#FFFFFF] md:bg-blue-700 bg-[#312AB7] p-1 md:font-medium border-b-2 border-[#FFFFFF]"
                          : "md:border-2 md:border-[#EEEEEF] md:text-[#000000] text-[#FFFFFF] opacity-60"
                        }`}
                      onClick={() => handleSeriesTypeChange(value)}
                    >
                      {key}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </h1>
        <div>
          {renderComponent()}
        </div>
      </div>
    </div>
  );
};

export default SelectTeamView;
