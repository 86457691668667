import React from "react";
import ShowOverScore from "./ShowOverScore";

function formatCommentary(commentary) {
  try {
    const parts = commentary?.split(',');
    const bowlerToBatsman = parts?.[0].trim();
    const runs = parts?.[1]?.trim();
    const description = parts.slice(2)?.join(',')?.trim();
    return { bowlerToBatsman, runs, description };
  } catch (error) {
    return { bowlerToBatsman: '', runs: '', description: '' };
  }
}
const TextRunColors = {
  0: "text-[#94A3AD]",
  1: "text-[#4994EC]",
  2: "text-[#5d6bd2]",
  3: "text-[#4994EC]",
  4: "text-[#4AA450]",
  6: "text-[#985DD2]",
  w: "text-[#E06C6C]",
  FH: "text-[#FF3434]",
  NB: "text-[#E19045]",
};
export const RunColors = {
  0: "bg-[#94A3AD]",
  1: "bg-[#4994EC]",
  2: "bg-[#5d6bd2]",
  3: "bg-[#4994EC]",
  4: "bg-[#4AA450]",
  6: "bg-[#985DD2]",
  w: "bg-[#E06C6C]",
  FH: "bg-[#FF3434]",
  NB: "bg-[#E19045]",
};
const CommonCommentary = ({ data, ShimmerClassName }) => {
  const { bowlerToBatsman, runs, description } = formatCommentary(data?.commentary)
  return (
      <div className="border-b md:py-6 py-4">
        <div className="flex">
          <div className="font-bold text-xs md:text-base text-[#050505] flex justify-center min-w-9">{data?.over}</div>
          <p className={`${ShimmerClassName} md:text-base text-xs h-full w-full ml-3`}>
            <span className="font-semibold">{bowlerToBatsman}:</span><span className={`font-semibold ${TextRunColors[data?.score] || "text-blue-700"
              }`}> {runs}</span>
          </p>  
        </div>
        <div className={`flex mt-3`}>
          <div
            className={`${RunColors[data?.score] || "bg-blue-700"} ${data?.score.length > 2 ? "text-xs" : "text-sm"
              } min-w-9 h-9 flex items-center justify-center rounded-full text-white`}
          >
            {data?.score}
          </div>
          <div className="text-[10px] md:text-[14px] md:leading-4 text-[#797979] ml-3">
            {description}
          </div>
        </div>
      </div>
  );
};

export default CommonCommentary;
