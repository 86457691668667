import React from "react";
import { useAxiosSWR } from "root/axiosInstance";
import flightArrow from "../../../../../Assets/flightsmode.svg";
import { useParams } from 'react-router-dom';
import { getSanitizedArray } from "../../../../../helpers/general";

const TeamViewTeamPlayers = () => {
  const { id: fullId } = useParams();
  const id = fullId.split('-').pop();

  const {
    data: teamData,
    error: teamError,
    isLoading: teamIsLoading,
  } = useAxiosSWR(`/v1/teams/${id}`);

  const {
    data,
    error: isError,
    isLoading,
  } = useAxiosSWR(`/v1/teams/${id}/players`);

  if (isLoading || teamIsLoading) {
    return <p></p>;
  }

  if (isError || teamError) {
    return <h2></h2>;
  }

  const renderPlayers = (players) => {
    return (
      <>
        {getSanitizedArray(players).map((player, index) => (
          <div key={index} className={`md:p-0 px-3 py-4 border-t grid grid-cols-6 justify-start md:border-none md:mb-4 items-center ${index % 2 === 0 ? "border-r" : ""}`}>
            <div className="col-span-1 rounded-full bg-[#c6c6c6] font-semibold text-white md:h-[66px] h-[40px] md:w-[66px] w-[40px] text-lg flex justify-center items-center">
              {player.name[0]}
            </div>
            <div className="col-span-5 md:ml-2 ml-[18px]">
              <p className="flex items-center">
                <span className="font-bold md:text-[14px] text-xs">{player.name}</span>
                {player.is_international && (
                  <img src={flightArrow} alt="flightArrow" className="md:ml-[100px] ml-2 opacity-50 md:h-[17px] h-[17px]" />
                )}
              </p>
              <p className="font-bold text-[#929292] text-[12px]">{player.playing_role}</p>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="md:mx-0 mx-4 bg-white md:rounded-2xl rounded-xl border md:p-10 py-4">
      <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center min-w-[50px] md:mb-0 mb-4`}>
        <span className="custom-border-left mr-3 md:mr-6"></span>
        <h1 className='text-[20px] md:text-2xl'>{teamData?.name} Team Players</h1>
        <span className="custom-border-right ml-3 md:ml-6"></span>
      </div>

      {/* BATTERS */}
      <div className="md:text-[22px] md:border-none border-[#DAE6F8] text-xs md:bg-[#FFFFFF] bg-[#E8F1FF] md:pt-10 px-4 py-1 md:text-start text-center md:pb-[24px] md:font-bold font-medium md:px-0">BATTERS</div>
      {data?.bat?.length > 0 ? (
        <div className="grid grid-cols-2 md:gap-4">{renderPlayers(data?.bat)}</div>
      ) : (
        <p className="text-gray-600">No batters data available</p>
      )}

      {/* ALL ROUNDERS */}
      <div className="md:text-[22px] text-xs md:border-none border-[#DAE6F8] md:bg-[#FFFFFF] bg-[#E8F1FF] md:pt-10 px-4 py-1 md:text-start text-center md:pb-[24px] md:font-bold font-medium md:px-0">ALL ROUNDERS</div>
      {data?.all?.length > 0 ? (
        <div className="grid grid-cols-2 md:gap-4">{renderPlayers(data?.all)}</div>
      ) : (
        <p className="text-gray-600">No all rounders data available</p>
      )}

      {/* WICKET KEEPERS */}
      <div className="md:text-[22px] text-xs md:border-none border-[#DAE6F8] md:bg-[#FFFFFF] bg-[#E8F1FF] md:pt-10 px-4 py-1 md:text-start text-center md:pb-[24px] md:font-bold font-medium md:px-0">WICKET KEEPERS</div>
      {data?.wk?.length > 0 ? (
        <div className="grid grid-cols-2 md:gap-4">{renderPlayers(data?.wk)}</div>
      ) : (
        <p className="text-gray-600">No wicket keepers data available</p>
      )}

      {/* BOWLERS */}
      <div className="md:text-[22px] text-xs md:border-none border-[#DAE6F8] md:bg-[#FFFFFF] bg-[#E8F1FF] md:pt-10 px-4 py-1 md:text-start text-center md:pb-[24px] md:font-bold font-medium md:px-0">BOWLERS</div>
      {data?.bowl?.length > 0 ? (
        <div className="grid grid-cols-2 md:gap-4">{renderPlayers(data?.bowl)}</div>
      ) : (
        <p className="text-gray-600 mb-4">No bowlers data available</p>
      )}
    </div>
  );
};

export default TeamViewTeamPlayers;
