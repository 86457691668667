import React from 'react';
import { formatDate } from '../../helpers/dates';
import { doScrollToTop, getNewsDetailsLinkPath } from '../../helpers/general';
import { Link } from 'react-router-dom';
import { shimmerData } from '../../helpers/constants';

const HomeNewsContainer = ({ title, data, subTitle = "", isLoading, isError, LinkTo }) => {
    const newsData = isLoading ? shimmerData : data || [];
    const shimmerClassName = newsData?.[0]?.isShimmer ? "bg-gray-300 loading text-opacity-0" : ""

    if (isError) {
        return (
            <div className='p-4'></div>
        )
    }

    const firstNewsContainerData = newsData?.[0];
    const otherNewsContainerData = newsData?.slice(1);

    return (
        <div className='bg-white md:border md:rounded-2xl'>
            <div className='px-5 md:py-8 py-4'>
                <div className={`md:min-h-[18px] md:min-w-[180px] ${shimmerClassName} flex items-center min-w-[50px]`}>
                    <span className="custom-border-left mr-3 md:mr-6"></span>
                    <h2 className='text-[16px] md:text-2xl'>{title}</h2>
                    <span className="custom-border-right ml-3 md:ml-6"></span>
                </div>
                <p className={`${shimmerClassName} text-center text-[#787878] md:text-base text-sm`}>{subTitle}</p>
            </div>

            <div className='px-[10px] md:px-5'>
                <div className={`${otherNewsContainerData.length > 0 ? "border-b pb-4 md:pb-6 md:border-b-0 " : ""}`}>
                    <Link to={getNewsDetailsLinkPath(firstNewsContainerData?.slug, firstNewsContainerData?.id, firstNewsContainerData?.type)} onClick={doScrollToTop}>
                        <div>
                            <img src={firstNewsContainerData?.image} alt="news" className={` ${shimmerClassName} w-full md:h-[288px] h-[178px] object-cover rounded-xl`} />
                        </div>
                        <div>
                            <p className={`${shimmerClassName} min-h-6 mt-3 news-title md:leading-[22px] leading-[14px] md:font-bold font-medium md:text-lg text-sm`}>
                                {firstNewsContainerData?.title}
                            </p>
                            <p className={`${shimmerClassName}  md:text-xs text-[10px] md:mt-3 mt-1 md:leading-[16px] leading-3 md:font-semibold font-medium text-[#787878]`}>
                                {formatDate(firstNewsContainerData?.date)}
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
            {
                otherNewsContainerData.length > 0 &&
                <div className='flex md:block overflow-x-auto hideScrollbar overflow-y-hidden items-stretch px-[10px] md:px-5'>
                    <div className='flex gap-4 md:gap-0 py-4 md:pb-0 md:grid md:grid-cols-2'>
                        {otherNewsContainerData?.map((news, index) => (
                            <div key={news?.id} className={`md:border md:w-full w-[140px] ${index === 0 || index === 1 ? "md:border-t-0 md:pb-4" : "md:border-b-0 md:py-4"} ${index % 2 === 0 ? "md:border-l-0 md:pr-4" : "md:pl-4 md:border-r-0"}`}>
                                <Link key={index} to={getNewsDetailsLinkPath(news?.slug, news?.id, news?.type)} onClick={doScrollToTop}>
                                    <div>
                                        <img src={news?.image} alt="news image" className={`${shimmerClassName} md:h-[124px] h-[84px] w-[140px] md:w-full rounded-xl object-cover`} />
                                    </div>
                                    <div>
                                        <p className={`${shimmerClassName} mt-2 news-title min-h-7 md:min-h-12 md:text-base text-[12px] leading-[14px] md:leading-[22px]`}>
                                            {news?.title}
                                        </p>
                                        <p className={`${shimmerClassName} mt-1 md:text-sm text-[10px] leading-[12px] md:leading-[16px] md:font-semibold font-medium text-[#787878]`}>
                                            {formatDate(news?.date)}
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            }
            <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t from-[#e2e2e2ab] to-transparent'>

            </div>
            <div className='text-[#3A32D1] text-center py-3 md:text-lg text-xs font-semibold'>
                <Link
                    key='btnSeeMoreNews2'
                    to={LinkTo}
                    onClick={doScrollToTop}
                >
                    See More
                </Link>
            </div>
        </div>
    );
};

export default HomeNewsContainer;