import React, { useState } from 'react';
import { NewsContainer } from '../../../../NewsV2';
import { useParams } from 'react-router-dom';
import { useAxiosSWR } from '../../../../../../axiosInstance';

const TeamViewNews = () => {
    const { id: fullId } = useParams();
    const id = fullId.split('-').pop();
    const { data, error, isLoading } = useAxiosSWR(`/v1/teams/${id}/news`);
    const [pageNum, setPageNum] = useState(1);
    const hasNextPage = data?.total_pages > pageNum;

    const handleSeeMore = () => {
        if (hasNextPage) {
            setPageNum((prevPage) => prevPage + 1);
        }
    };

    if (isLoading) {
        return <div></div>;
    }
    if (error) {
        return <div></div>;
    }

    return (
        <div>
                <NewsContainer
                    newsData={data}
                    title='Top Team News'
                    hasNextPage={hasNextPage}
                    handleSeeMore={handleSeeMore}
                    isLoading={isLoading}
                    hasError={error}
                    pageNum={pageNum}
                />
        </div>
    );
}

export default TeamViewNews;