import React, { useMemo, useRef, useState } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import ShowOverScore from "./common/ShowOverScore";
import { getSeriesMatchID } from "./common/helper";
import { getSanitizedArray } from "../../../../../helpers/general";
import { highlights } from "./common/ShimmerData";
const FilterTypes = [
  { key: "All", value: "all" },
  { key: "Fours", value: "fours" },
  { key: "Sixes", value: "sixes" },
  { key: "Wickets", value: "wickets" },
];

const PAGE_SIZE = 20;

const Highlights = () => {
  const [selectInnings, setSelectInnings] = useState(0);
  const [selectFilterType, setFilterType] = useState("all");
  const [refreshInterval, setRefreshInterval] = useState(0);
  const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
  const { matchId } = getSeriesMatchID(seriesSlug, matchSlug);
  const {
    data,
    error: isError,
    isLoading,
  } = useAxiosSWR(`v1/matches/${matchId}/highlights`, {
    refreshInterval,
  });
  const LiveMatchStatus = data && data?.match?.status === "Live";
  useEffect(() => {
    if (LiveMatchStatus) {
      setRefreshInterval(5000);
    } else {
      setRefreshInterval(0);
    }
  }, [LiveMatchStatus]);

  const inningsData = getSanitizedArray(isLoading ? highlights?.info?.innings : data?.info?.innings || []);
  useEffect(() => {
    setSelectInnings(0);
  }, [inningsData]);
  const highlightData = inningsData.length > 0 ? inningsData[selectInnings]?.highlights[selectFilterType] : [];
  if (isError) {
    return <h2></h2>;
  }
  const shimmerClassName = highlightData?.[0]?.isShimmer ? "bg-gray-300 loading" : ""
  return (
    <div className="mt-8 md:flex-row px-4">
      <div className="flex text-sm cursor-pointer overflow-auto">
        {inningsData.map((i, idx) => (
          <div
            key={idx}
            className={`text-sm text-[#6C6C6C] mx-1  border-b px-1 pb-2.5 ${
              selectInnings === idx
                ? `border-b-[3px] text-blue-700 font-semibold border-blue-700`
                : ""
            }`}
            onClick={() => {
              setSelectInnings(idx);
            }}
          >
            {i.name}
          </div>
        ))}
      </div>
     {inningsData.length > 0 ? <div className="flex text-sm border rounded-full max-w-max cursor-pointer p-1 mt-4 overflow-x-auto hideScrollbar">
        {FilterTypes.map(({ key, value }) => (
          <div
            key={value}
            className={`px-6 py-1 ${
              selectFilterType === value
                ? "text-[#3A32D1] bg-[#D8E7FF] rounded-full p-1 font-medium"
                : ""
            }`}
            onClick={ !shimmerClassName ? () => setFilterType(value) : null}
          >
            {key}
          </div>
        ))}
      </div> : "Highlights is comming soon ..."}
      <HighlightsByInning data={highlightData} selectFilterType={selectFilterType} selectInnings={selectInnings} shimmerClassName={shimmerClassName}/>
    </div>
  );
};

const HighlightsByInning = ({ data, selectFilterType, selectInnings, shimmerClassName }) => {
  const containerRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setCurrentPage(1);
  }, [selectFilterType,selectInnings]);
  const newData = useMemo(() => {
    const X = data.slice(0, currentPage * PAGE_SIZE);
    return X;
  }, [data, currentPage]);
  const handleSeeMoreClick = () => {
    setCurrentPage((prev) => {
      if (data.length > currentPage * PAGE_SIZE) {
        return prev + 1;
      }
      return prev;
    });
  };
  return (
    <div className="mt-3" ref={containerRef}>
      {newData?.map((dataObj, i) => (
        <div className="grid grid-cols-8 my-2" key={i}>
          <div className="col-span-1 md:text-base text-[13px] font-semibold md:pl-6 sm:pl-4">
            <ShowOverScore over={dataObj.over} score={dataObj.score} />
          </div>
          <div className={`${shimmerClassName} col-span-7 md:text-base text-[12px]`}>
            {dataObj.commentary}
          </div>
        </div>
      ))}
      <div className="text-center">
        {data.length > currentPage * PAGE_SIZE && (
          <button
            onClick={handleSeeMoreClick}
            className="text-blue-700 text-sm"
          >
            See more
          </button>
        )}
      </div>
    </div>
  );
};

export default Highlights;
