import { useRef, useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { useAxiosSWR } from "root/axiosInstance";
import { useHorizontalScroll } from "src/hooks/useHorizontalScroll";
import { getSanitizedArray } from "../../../helpers/general";
import SEOMeta from "../Home/common/SEOMeta";
import AppContext, { URL_TYPES } from "src/context";

const SubPagesArray = [{
    key: "t20s",
    display: "T20",
}, {
    key: "odis",
    display: "ODI",
},
{
    key: "tests",
    display: "TEST",
}];

const PagesArray = [
    {
    key: "teams",
    display: "Teams"
},
    {
    key: "batsmen",
    display: "Batters",
}, {
    key: "bowlers",
    display: "Bowlers",
},
{
    key: "all-rounders",
    display: "All Rounders",
}];

const SubCats = ["t20s", "odis", "tests"];

export const TopRanking = () => {
    const { data, isLoading, error, mutate } = useAxiosSWR("/v1/misc/ranking?limit=10");
    const { type, subType } = useParams();
    const [cat, setCat] = useState(type);
    const [subCat, setSubCat] = useState(subType);

    const appContext = useContext(AppContext);
    const { pathname: pathName } = useLocation();
    useEffect(() => {
        const urlInfoObj = {
            type: URL_TYPES.RANKING,
            path: pathName,
            reqState: null,
        };
        appContext.updateUrlInfo(urlInfoObj);
    }, [pathName]);

    const containerRef = useRef();
    const subContainerRef = useRef();
    const navigate = useNavigate();
    useHorizontalScroll(containerRef, `_cat_${cat}`);
    useHorizontalScroll(subContainerRef, `_sub_cat_${subCat}`);


    useEffect(() => {
        if (!cat) {
            setCat(PagesArray[0].key);
            setSubCat(SubPagesArray[0].key);
            navigate(`/ranking/${PagesArray[0].key}/${SubPagesArray[0].key}`);
        }

        const validCat = PagesArray.find(p => p.key === cat);
        if (!validCat) {
            navigate(`/ranking/${PagesArray[0].key}/${SubPagesArray[0].key}`);
            return;
        }

        const isValidSubCat = SubPagesArray.find(sp => sp.key === subCat);
        if (!isValidSubCat) {
            navigate(`/ranking/${cat}/${SubPagesArray[0].key}`);
            return;
        }
    }, []);

    if (isLoading) {
        return (<div className="p-4">Loading..</div>)
    }

    if (error) {
        return (<div className="p-4">
            {/* use mutate here */}
            <h3></h3>
        </div>)
    }

    if (!data) {
        return;
    }

    return (
        <div className="md:mx-0 mx-5">
            <SEOMeta slug="ranking" />
            <div>
                <div className="mb-4">
                    <div className="border bg-[#FFFFFF] md:rounded-[20px] rounded-2xl mt-4 pb-5">
                        <div>
                            <div className='px-5 md:py-7 py-4'>
                                <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center min-w-[50px]`}>
                                    <span className="custom-border-left mr-3 md:mr-6"></span>
                                    <h1 className='md:text-[32px] text-[20px] mx-6 font-bold md:leading-[22px] leading-6 md:text-center'>Ranking</h1>
                                    <span className="custom-border-right ml-3 md:ml-6"></span>
                                </div>
                            </div>
                            <div className="px-5">
                                <div ref={containerRef} className="flex text-nowrap md:grid md:grid-cols-4 text-sm gap-4 overflow-x-auto hideScrollbar rounded-xl cursor-pointer border p-[1px]">
                                    {PagesArray.map(({ key, display }) => (
                                        <div className="text-center" key={key} data-testid={`_cat_${key}`}>
                                            <Link
                                                to={`/ranking/${key}/${SubPagesArray[0].key}`}
                                            >
                                                <div
                                                    className={`px-5 py-2 rounded-xl font-bold ${cat === key ? "text-white bg-blue-700" : ""}`}
                                                    onClick={() => {
                                                        setCat(key);
                                                        setSubCat(SubPagesArray[0].key)
                                                    }}
                                                >
                                                    {display}
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border bg-[#FFFFFF] md:rounded-[20px] rounded-2xl py-5">
                <div className="px-5">
                    <div ref={subContainerRef} className="text-sm grid grid-cols-3 gap-4 overflow-x-auto hideScrollbar rounded-xl cursor-pointer border p-[1px]">
                        {SubPagesArray.map(({ key, display }) => (
                            <div className="text-center" key={key} data-testid={`_sub_cat_${key}`}>
                                <Link to={`/ranking/${cat}/${key}`}>
                                    <div
                                        className={`px-5 py-2 rounded-xl font-bold ${subCat === key ? "text-white bg-blue-700" : ""}`}
                                        onClick={() => setSubCat(key)}
                                    >
                                        {display}
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                {/* Table header */}
                {cat === "teams" && <TeamTable data={data} cat={cat} subCat={subCat} />}
                {(cat === "batsmen" || cat === "bowlers" || cat === "all-rounders") && <PlayerTable data={data} subCat={subCat} cat={cat} />}
            </div>
        </div>
    );
}

const PLAYERS_ICON_URL = 'https://cdn.cricketgully.com/cg-spa-static-assets/dhoni_avtaar.jpeg';

const ImageRankers = ({ data }) => {
    if (!Array.isArray(data) || data.length !== 3) {
        return;
    }
    const [rank1, rank2, rank3] = data;
    return (
        <div className="my-4" style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <div data-testid="rank-2">
                <div data-testid="image_container">
                    <div className="w-max relative mx-auto mb-2">
                        {
                            rank2.url ?
                                <img className="object-cover rounded-full h-[60px] w-[60px] md:h-[120px] md:w-[120px] border-[6px] md:border-8 border-[#E6E6E6]" src={rank2.url} alt="logo" />
                                :
                                <div className="flex justify-center items-center bg-[#c6c6c6] text-xl md:text-6xl text-white rounded-full h-[60px] w-[60px] md:h-[120px] md:w-[120px] border-[3px] md:border-4 border-[#E6E6E6]" >
                                    {rank2.name[0]}
                                </div>
                        }

                        <div className="absolute font-bold w-[25px] md:text-base text-xs md:w-[35px] py-0.5 rounded-full bg-[#E6E6E6] -bottom-2" style={{
                            left: '50%',
                            transform: 'translateX(-50%)',
                            textAlign: 'center',
                        }}>2</div>
                    </div>
                </div>
                <div className="flex flex-col items-center" data-testid="info_container">
                    <h3 className="text-sm font-bold md:text-2xl">{rank2.point}</h3>
                    <div className="text-xs md:text-base text-[#808080]">{rank2.name}</div>
                </div>
            </div>
            <div data-testid="rank-1">
                <div data-testid="image_container">
                    <div className="relative w-max mx-auto ">
                        {
                            rank1.url ?
                                <img className="object-cover rounded-full h-[100px] w-[100px] md:h-[200px] md:w-[200px] border-[6px] md:border-8 border-[#F5E2B7]" src={rank1.url} alt="logo" />
                                :
                                <div className="flex justify-center items-center bg-[#c6c6c6] text-4xl md:text-8xl text-white rounded-full h-[100px] w-[100px] md:h-[200px] md:w-[200px] border-[6px] md:border-8 border-[#F5E2B7]" >
                                    {rank1.name[0]}
                                </div>
                        }
                        <div className="absolute font-bold w-[25px] md:text-base text-xs md:w-[35px] py-0.5 -bottom-2 rounded-full bg-[#F5E2B7]" style={{
                            left: '50%',
                            transform: 'translateX(-50%)',
                            textAlign: 'center',
                        }}>1</div>
                    </div>
                </div>

                <div className="flex flex-col items-center" data-testid="info_container">
                    <h3 className="text-sm font-bold md:text-2xl">{rank1.point}</h3>
                    <div className="text-xs md:text-base text-[#808080]">{rank1.name}</div>
                </div>
            </div>
            <div data-testid="rank-3">
                <div data-testid="image_container">
                    <div className="w-max relative mx-auto mb-2">
                        {
                            rank3.url ?
                                <img className="object-cover rounded-full h-[60px] w-[60px] md:h-[120px] md:w-[120px] border-[6px] md:border-8 border-[#DB9562]" src={rank3.url} alt="logo" />
                                :
                                <div className="flex justify-center items-center bg-[#c6c6c6] text-xl md:text-6xl text-white rounded-full h-[60px] w-[60px] md:h-[120px] md:w-[120px] border-[3px] md:border-4 border-[#DB9562]" >
                                    {rank3.name[0]}
                                </div>
                        }
                        <div className="absolute font-bold w-[25px] md:text-base text-xs md:w-[35px] py-0.5 -bottom-2 rounded-full bg-[#DB9562]" style={{
                            left: '50%',
                            transform: 'translateX(-50%)',
                            textAlign: 'center',
                        }}>3</div>
                    </div>
                </div>
                <div className="flex flex-col items-center" data-testid="info_container">
                    <h3 className="text-sm font-bold md:text-2xl">{rank3.point}</h3>
                    <div className="text-xs md:text-base text-[#808080]">{rank3.name}</div>
                </div>
            </div>
        </div>
    )
}
const PlayerTable = ({ data, subCat, cat }) => {
    const dataToShow = getSanitizedArray(data?.[cat]?.[subCat]);
    return (
        <>
            <ImageRankers
                data={dataToShow.slice(0, 3).map(t => ({
                    point: t.rating,
                    name: t.player,
                    id: t.pid
                }))}
            />
            <div className="bg-[#EEF0F3] md:rounded-xl leading-5 font-bold md:mx-5">
                <div className="grid grid-cols-12 text-center items-center ">
                    <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2.5 text-start col-span-1 md:text-base text-sm">
                        Pos
                    </div>

                    <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2.5 text-start col-span-6 md:text-base text-sm">
                        Player
                    </div>

                    <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2.5 text-start col-span-3 md:text-base text-sm">
                        Team Name
                    </div>

                    <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2.5 text-start col-span-2 md:text-base text-sm">
                        Points
                    </div>
                </div>
            </div>
            {/** table data */}
            <div key={`${cat}_${subCat}`} className="bg-white divide-y divide-gray-200">
                {dataToShow.map((rec) => {
                    return (
                        <div key={`${cat}_${subCat}_${rec.team}`}>
                            <div
                                className="grid grid-cols-12 items-center cursor-pointer hover:bg-[#3a32d109]"
                            >
                                <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2 flex items-center col-span-1 text-xs md:text-base justify-center">
                                    {rec.rank}
                                </div>
                                <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2 flex items-center col-span-6 text-xs md:text-base">
                                    <div className="md:w-10 md:h-10 w-8 h-8 bg-[#D9D9D9] rounded-full mr-2 text-white flex justify-center items-center">{rec.player[0]}</div>
                                    {rec.player}
                                </div>
                                <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2 flex items-center col-span-3 text-xs md:text-base">
                                    {rec.team}
                                </div>
                                <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2 flex items-center col-span-2 text-xs md:text-base">
                                    {rec.rating}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

const TeamTable = ({ data, subCat, cat }) => {
    const dataToShow = getSanitizedArray(data?.[cat]?.[subCat]);
    return (
        <>
            <ImageRankers
                data={dataToShow.slice(0, 3).map(t => ({
                    point: t.rating,
                    url: t.logo_url,
                    name: t.team,
                    id: t.tid,
                }))}
            />
            {/** table header */}
            <div className="bg-[#EEF0F3] rounded-xl leading-5 font-bold mx-5">
                <div className="grid grid-cols-12 text-center items-center text-xs md:text-base">
                    <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2.5 text-start col-span-1">
                        Pos
                    </div>

                    <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2.5 text-start col-span-5">
                        Team
                    </div>

                    <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2.5 text-start col-span-2">
                        Matches
                    </div>

                    <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2.5 text-start col-span-2">
                        Rating
                    </div>

                    <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2.5 text-start col-span-2">
                        Points
                    </div>
                </div>
            </div>
            {/** table data */}
            <div key={`${cat}_${subCat}`} className="bg-white divide-y divide-gray-200 mx-5">
                {dataToShow.map((rec) => {
                    return (
                        <div key={`${cat}_${subCat}_${rec.team}`}>
                            <div
                                className="grid grid-cols-12 items-center cursor-pointer hover:bg-[#3a32d109] text-xs md:text-base"
                            >
                                <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2 flex items-center col-span-1">
                                    {rec.rank}
                                </div>
                                <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2 flex items-center col-span-5">
                                    <div className="md:w-10 md:h-10 w-8 h-8 rounded-full mr-2 text-white flex justify-center items-center">
                                        <img src={rec.logo_url} alt="" />
                                    </div>
                                    {rec.team}
                                </div>
                                <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2 flex items-center col-span-2">
                                    {rec.matches}
                                </div>
                                <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2 flex items-center col-span-2">
                                    {rec.rating}
                                </div>
                                <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2 flex items-center col-span-2">
                                    {rec.points}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}