import { useState, useCallback } from 'react';
import axiosInstance from '../../axiosInstance';

const usePostMultipart = () => {
  const [reqObj, setReqObj] = useState({
    isLoading: false,
    error: null,
    data: null,
  });
  
  // Just to set error manually from the consumer's side.   
  const setPostError = useCallback((error) => {
    setReqObj({ isLoading: false, error, data: null });
  }, []);

  const makeRequest = useCallback(async (url, data, customHeaders = {}) => {
    setReqObj({  isLoading: true, error : null, data: null });
    try {
      const response = await axiosInstance.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...customHeaders,
        },
      });
      setReqObj({ isLoading: false, error: null, data: response });
      return response;
    } catch (error) {
      console.log(error)
      setReqObj({ isLoading: false, error, errorObj2: error.response?.data?.message || error?.message, data: null });
    }
  }, []);

  return { makeRequest, setPostError, ...reqObj };
};

export default usePostMultipart;


