import liveImg from "../../../../../Assets/Group 5.svg";

export const NoMatch = ({ text, gameAndDate }) => (
    <div className="col-span-4 border-[#E3E3E4] rounded-[18px] md:w-[340px] w-auto md:h-[157px] h-[100px] flex flex-col justify-center items-center">
        <img src={liveImg} alt="No matches" className="md:w-[80px] w-[50px] md:h-[80px] h-[50px]" />
        <p className="md:text-[14px] text-xs mt-2 text-[#333333]">
            {/* {console.log(`${text} Format --> ${gameAndDate}`)} */}
            {text}
        </p>
    </div>
);