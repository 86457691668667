export const commentary = {
    "live_score": {
        "isShimmer": true,
        "runs": 0,
        "overs": 0,
        "target": 0,
        "runrate": 0,
        "wickets": 0,
        "required_runrate": 0
    },
    "batsmen": Array(2).fill(
        {
            "name": "",
            "runs": 0,
            "fours": 0,
            "sixes": 0,
            "batsman_id": 0,
            "balls_faced": 0,
            "strike_rate": "0",
            "position": ""
        }),
    "bowlers": Array(2).fill(
        {
            "econ": "0",
            "name": "",
            "overs": 0,
            "maidens": 0,
            "wickets": 0,
            "bowler_id": 0,
            "runs_conceded": 0,
            "bowling": "true"
        }),
    "key_stats": {
        "partnership": "0(0)",
        "last5_overs": "0/0 0.00",
        "last_wicket": "",
        "toss": "",
        "reviews_remaining": [
            "",
            ""
        ]
    },
    "innings": [
        {
            "name": "",
            "commentaries": Array(20).fill().map((_, index) => {
                if (index % 7 === 6) {
                    return {
                        "event": "overend",
                        "score": "0",
                        "over": 0,
                        "commentary": "",
                        "timestamp": 0,
                        "bats": [
                            {
                                "runs": 0,
                                "fours": 0,
                                "sixes": 0,
                                "batsman_id": 0,
                                "balls_faced": 0,
                                "batsman_name": ""
                            },
                            {
                                "runs": 0,
                                "fours": 0,
                                "sixes": 0,
                                "batsman_id": 0,
                                "balls_faced": 0,
                                "batsman_name": ""
                            }
                        ],
                        "runs": 0,
                        "bowls": [
                            {
                                "overs": 0,
                                "maidens": 0,
                                "wickets": 0,
                                "bowler_id": 0,
                                "runs_conceded": 0,
                                "bowler_name": ""
                            }
                        ]
                    };
                } else {
                    return {
                        "event": "ball",
                        "score": 0,
                        "over": "0",
                        "commentary": "",
                        "timestamp": 0
                    };
                }
            }),
            "isLive": true,
            "teamName": ""
        }
    ]
}
export const scorecard = {
    info: {
        live_score: {
            runs: 0,
            overs: 0,
            target: 0,
            runrate: 0.0,
            wickets: 0,
            required_runrate: ""
        },
        scorecard: {
            innings: [
                {
                    iid: 0,
                    fows: Array(9).fill({
                        name: "",
                        runs: "0",
                        balls: "0",
                        number: 0,
                        how_out: "",
                        bowler_id: "0",
                        dismissal: "",
                        batsman_id: "0",
                        overs_at_dismissal: "0",
                        score_at_dismissal: 0
                    }),
                    name: "",
                    number: 0,
                    result: 0,
                    review: {
                        batting: {
                            batting_team_total_review: "0",
                            batting_team_review_failed: "0",
                            batting_team_review_success: "0",
                            batting_team_review_retained: "0",
                            batting_team_review_available: "0"
                        },
                        bowling: {
                            bowling_team_total_review: "0",
                            bowling_team_review_failed: "0",
                            bowling_team_review_success: "0",
                            bowling_team_review_retained: "0",
                            bowling_team_review_available: "0"
                        }
                    },
                    scores: "0/0",
                    status: 0,
                    batsmen: Array(11).fill({
                        name: "",
                        role: "",
                        run0: "0",
                        runs: "0",
                        fours: "0",
                        sixes: "0",
                        batting: "false",
                        how_out: "",
                        position: "",
                        role_str: "",
                        bowler_id: "0",
                        dismissal: "",
                        batsman_id: "0",
                        balls_faced: "0",
                        strike_rate: "0.0",
                        first_fielder_id: "0",
                        third_fielder_id: "",
                        second_fielder_id: "",
                        substitute: "false",
                        in: "false",
                        out: "false",
                        is_current: false
                    }),
                    bowlers: Array(7).fill({
                        econ: "0.00",
                        name: "",
                        run0: "0",
                        overs: "0",
                        wides: "0",
                        bowling: "true",
                        maidens: "0",
                        noballs: "0",
                        wickets: "0",
                        position: "",
                        bowler_id: "0",
                        runs_conceded: "0",
                        substitute: "false",
                        in: "false",
                        out: "false"
                    }),
                    fielder: Array(6).fill({
                        catches: 0,
                        stumping: 0,
                        fielder_id: "0",
                        fielder_name: "",
                        is_substitute: "false"
                    }),
                    max_over: "0",
                    equations: {
                        runs: 0,
                        overs: "0",
                        runrate: "0",
                        wickets: 0,
                        bowlers_used: 0
                    },
                    powerplay: {
                        p1: {
                            endover: "0",
                            startover: "0"
                        }
                    },
                    extra_runs: {
                        byes: 0,
                        total: 0,
                        wides: 0,
                        legbyes: 0,
                        noballs: 0,
                        penalty: "0"
                    },
                    short_name: "",
                    did_not_bat: [],
                    issuperover: "false",
                    last_wicket: {
                        name: "",
                        runs: "0",
                        balls: "0",
                        number: 0,
                        how_out: "",
                        bowler_id: "0",
                        dismissal: "",
                        batsman_id: "0",
                        overs_at_dismissal: "0",
                        score_at_dismissal: 0
                    },
                    scores_full: "0/0 (0 ov)",
                    batting_team_id: 0,
                    fielding_team_id: 0,
                    current_partnership: {
                        runs: 0,
                        balls: 0,
                        overs: 0,
                        batsmen: Array(2).fill({
                            name: "",
                            runs: 0,
                            balls: 0,
                            batsman_id: 0
                        })
                    }
                }
            ],
            is_followon: 0,
            day_remaining_over: ""
        }
    }
};
export const highlights = {
    "info": {
        "live_score": {
            "runs": 0,
            "overs": 0,
            "target": 0,
            "runrate": 0.0,
            "wickets": 0,
            "required_runrate": ""
        },
        "innings": [
            {
                "name": "",
                "highlights": {
                    "all": Array(20).fill({
                        "isShimmer": true,
                        "event": "",
                        "score": 0,
                        "over": "0",
                        "commentary": "",
                        "timestamp": 0
                    },),
                },
                "teamName": ""
            }
        ]
    }
}
export const pointTable = {
    "isShimmer": true,
    "series": {
        "cid": 0,
        "title": "",
        "game_format": "",
        "total_matches": 0,
        "date_start": "",
        "date_end": "",
        "slug": "",
        "tournament_type": ""
    },
    "groups": [
        {
            "name": "",
            "table": Array(10).fill(
                {
                    "tid": 0,
                    "title": "",
                    "abbr": "",
                    "thumb_url": "",
                    "played": "0",
                    "win": "0",
                    "loss": "0",
                    "draw": "0",
                    "netrr": "0.0",
                    "points": "0",
                    "lastfivematchresult": "",
                    "matches": []
                })
        }
    ]
}
export const play11 = {
  "info": {
    "live_score": {
      "runs": 0,
      "overs": 0,
      "target": 0,
      "runrate": 0,
      "wickets": 0,
      "required_runrate": ""
    },
    "playing11": {
      "teama": {
        "squads": Array(11).fill({
          "in": "false",
          "out": "false",
          "name": "",
          "role": "",
          "role_str": "",
          "player_id": "",
          "playing11": "true",
          "substitute": "false"
        }),
        "team_id": 0
      },
      "teamb": {
        "squads": Array(11).fill({
          "in": "false",
          "out": "false",
          "name": "",
          "role": "",
          "role_str": "",
          "player_id": "",
          "playing11": "true",
          "substitute": "false"
        }),
        "team_id": 1
      }
    }
  }
};

export const matchObj = {
        "isShimmer": true,
        "match_id": 0,
        "title": "",
        "short_title": "",
        "subtitle": "",
        "status": "",
        "date_start_utc": "",
        "date_start": "",
        "status_note": "",
        "format": "0",
        "game_state": 0,
        "game_state_str": "",
        "teama": {
            "team_id": 0,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "0/0",
            "overs": "0",
            "max_overs": "0"
        },
        "teamb": {
            "team_id": 1,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "",
            "overs": "",
            "max_overs": "0"
        },
        "venue_id": 0,
        "toss": "",
        "tournament_name": "",
        "tournament_abbr": "",
        "cid": 0,
        "venue": "",
        "match_date": "",
        "ball": "",
        "slug": "",
        "tournament_slug": "",
        "dateStrings": {
            "string1": "",
            "string2": ""
        },
        "live_inning_number": 0,
        "last_five_overs": "0",
        "man_of_the_match": "",
        "man_of_the_series": ""
}
export const matchInfo = {
    "isShimmer": true,
    "info": {
        "pitch_report": {
            "pitch_condition": "",
            "batting_condition": "",
            "pace_bowling_condition": "",
            "spine_bowling_condition": ""
        },
        "points_table": [
            {
                "tid": 0,
                "title": "",
                "abbr": "",
                "thumb_url": "",
                "played": "0",
                "win": "0",
                "loss": "0",
                "draw": "0",
                "netrr": "0.0",
                "points": "0",
                "lastfivematchresult": ""
            },
            {
                "tid": 0,
                "title": "",
                "abbr": "",
                "thumb_url": "",
                "played": "0",
                "win": "0",
                "loss": "0",
                "draw": "0",
                "netrr": "0.00",
                "points": "0",
                "lastfivematchresult": ""
            }
        ],
        "team_form": [
            {
                "title": "",
                "abbr": "",
                "thumb_url": "",
                "lastfivematchresult": ", , , ,"
            },
            {
                "title": "",
                "abbr": "",
                "thumb_url": "",
                "lastfivematchresult": ", , , ,"
            }
        ]
    },
    "teamForm": [
      {
        "team_id": 15707,
        "short_name": "BD",
        "logo": "https://images.entitysport.com/assets/uploads/2023/07/Rectangle-3352.png",
        "lastFive": [
          "L",
          "W"
        ],
        "lastFiveMatches": [
          {
            "cid": 128782,
            "match_id": 79104,
            "title": "Speen Ghar Tigers vs Boost Defenders",
            "short_title": "SG vs BOS",
            "tournament_title": "Shpageeza Cricket League",
            "tournament_abbr": "SCL 2024",
            "subtitle": "Match 3",
            "status_str": "Completed",
            "status_note": "Boost Defenders won by 7 wickets",
            "format": "T20",
            "teama": {
              "team_id": 15711,
              "name": "Speen Ghar Tigers",
              "short_name": "SG",
              "logo": "https://images.entitysport.com/assets/uploads/2023/07/Rectangle-3354-1.png",
              "scores": "166/7",
              "overs": "20",
              "max_overs": "20"
            },
            "teamb": {
              "team_id": 15714,
              "name": "Boost Defenders",
              "short_name": "BOS",
              "logo": "https://images.entitysport.com/assets/uploads/2023/07/Rectangle-3354-2.png",
              "scores": "170/3",
              "overs": "19.2",
              "max_overs": "20"
            },
            "winning_team_id": "15714",
            "date_start": "2024-08-13T04:30:00.000Z",
            "slug": "speen-ghar-tigers-vs-boost-defenders-sg-vs-bos-79104",
            "tournament_slug": "shpageeza-cricket-league-scl-2024-128782"
          },
          {
            "cid": 128782,
            "match_id": 79102,
            "title": "Speen Ghar Tigers vs Band-e-Amir Dragons",
            "short_title": "SG vs BD",
            "tournament_title": "Shpageeza Cricket League",
            "tournament_abbr": "SCL 2024",
            "subtitle": "Match 1",
            "status_str": "Completed",
            "status_note": "Band-e-Amir Dragons won by 19 runs.",
            "format": "T20",
            "teama": {
              "team_id": 15711,
              "name": "Speen Ghar Tigers",
              "short_name": "SG",
              "logo": "https://images.entitysport.com/assets/uploads/2023/07/Rectangle-3354-1.png",
              "scores": "148/9",
              "overs": "20",
              "max_overs": "20"
            },
            "teamb": {
              "team_id": 15707,
              "name": "Band-e-Amir Dragons",
              "short_name": "BD",
              "logo": "https://images.entitysport.com/assets/uploads/2023/07/Rectangle-3352.png",
              "scores": "167/6",
              "overs": "20",
              "max_overs": "20"
            },
            "winning_team_id": "15707",
            "date_start": "2024-08-12T04:30:00.000Z",
            "slug": "speen-ghar-tigers-vs-band-e-amir-dragons-sg-vs-bd-79102",
            "tournament_slug": "shpageeza-cricket-league-scl-2024-128782"
          }
        ]
      },
      {
        "team_id": 15711,
        "short_name": "SG",
        "logo": "https://images.entitysport.com/assets/uploads/2023/07/Rectangle-3354-1.png",
        "lastFive": [
          "L",
          "L"
        ],
        "lastFiveMatches": [
          {
            "cid": 128782,
            "match_id": 79105,
            "title": "Band-e-Amir Dragons vs Amo Sharks",
            "short_title": "BD vs AM",
            "tournament_title": "Shpageeza Cricket League",
            "tournament_abbr": "SCL 2024",
            "subtitle": "Match 4",
            "status_str": "Completed",
            "status_note": "Band-e-Amir Dragons won by 5 wickets",
            "format": "T20",
            "teama": {
              "team_id": 15707,
              "name": "Band-e-Amir Dragons",
              "short_name": "BD",
              "logo": "https://images.entitysport.com/assets/uploads/2023/07/Rectangle-3352.png",
              "scores": "148/5",
              "overs": "19.2",
              "max_overs": "20"
            },
            "teamb": {
              "team_id": 15710,
              "name": "Amo Sharks",
              "short_name": "AM",
              "logo": "https://images.entitysport.com/assets/uploads/2023/08/Amo-Sharks.png",
              "scores": "147/7",
              "overs": "20",
              "max_overs": "20"
            },
            "winning_team_id": "15707",
            "date_start": "2024-08-13T09:30:00.000Z",
            "slug": "band-e-amir-dragons-vs-amo-sharks-bd-vs-am-79105",
            "tournament_slug": "shpageeza-cricket-league-scl-2024-128782"
          },
          {
            "cid": 128782,
            "match_id": 79102,
            "title": "Speen Ghar Tigers vs Band-e-Amir Dragons",
            "short_title": "SG vs BD",
            "tournament_title": "Shpageeza Cricket League",
            "tournament_abbr": "SCL 2024",
            "subtitle": "Match 1",
            "status_str": "Completed",
            "status_note": "Band-e-Amir Dragons won by 19 runs.",
            "format": "T20",
            "teama": {
              "team_id": 15711,
              "name": "Speen Ghar Tigers",
              "short_name": "SG",
              "logo": "https://images.entitysport.com/assets/uploads/2023/07/Rectangle-3354-1.png",
              "scores": "148/9",
              "overs": "20",
              "max_overs": "20"
            },
            "teamb": {
              "team_id": 15707,
              "name": "Band-e-Amir Dragons",
              "short_name": "BD",
              "logo": "https://images.entitysport.com/assets/uploads/2023/07/Rectangle-3352.png",
              "scores": "167/6",
              "overs": "20",
              "max_overs": "20"
            },
            "winning_team_id": "15707",
            "date_start": "2024-08-12T04:30:00.000Z",
            "slug": "speen-ghar-tigers-vs-band-e-amir-dragons-sg-vs-bd-79102",
            "tournament_slug": "shpageeza-cricket-league-scl-2024-128782"
          }
        ]
      }
    ]
}
export const mockNewsData = Array(20).fill(
    { 
        isShimmer : true, 
        id : "", 
        slug : "", 
        image : "", 
        title : "", 
        excerpt : "", 
        data : ""
      }
    )
export const mockVideoData  = Array(5).fill(
        {
            "isShimmer": true,
            "id": 0,
            "title": "",
            "thumb_url": "",
            "video_url": ""
        }
    )
