import React from 'react';
import { useAxiosSWR } from "root/axiosInstance";
import { useParams } from 'react-router-dom';
import OverendCommentary from './common/OverendCommentary';
import CommonCommentary from './common/CommonCommentary';
import { getSeriesMatchID } from './common/helper';

const Live = () => {
  const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
  const { matchId } = getSeriesMatchID(seriesSlug, matchSlug);
  const { data, error: isError, isLoading } = useAxiosSWR(`v1/matches/${matchId}`);
  const infoData = data?.info;
  const commentryData = data?.info?.commentaries;

  if (isLoading) {
    return <h2></h2>;
  }
  if (isError) {
    return <h2></h2>;
  }
  const overendScore = commentryData.find(obj => obj.event === 'overend')?.score;
  return (
    <div className='mt-8 md:flex-row px-4'>
      <div className='w-full mb-5'>
        <div className="rounded-t border border-opacity-40">
          <div className="bg-blue-100">
            <div className='text-left text-sm grid grid-cols-8'>
              <p className='lg:px-2 md:px-2 px-0.5 py-2 font-medium col-span-3'>Batter</p>
              <p className="lg:px-2 md:px-2 px-0.5 py-2 text-gray-500 ">R</p>
              <p className="lg:px-2 md:px-2 px-0.5 py-2 text-gray-500 ">B</p>
              <p className="lg:px-2 md:px-2 px-0.5 py-2 text-gray-500 ">4s</p>
              <p className="lg:px-2 md:px-2 px-0.5 py-2 text-gray-500 ">6s</p>
              <p className="lg:px-2 md:px-2 px-0.5 py-2 text-gray-500 ">SR</p>
            </div>
          </div>
          <div>
            {infoData?.batsmen?.map((batsman, index) => (
              <div key={index} className="grid grid-cols-8 text-sm">
                <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2 font-semibold col-span-3'>{batsman.name}</p>
                <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2 font-semibold'>{batsman.runs}</p>
                <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2'>{batsman.balls_faced}</p>
                <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2'>{batsman.fours}</p>
                <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2'>{batsman.sixes}</p>
                <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2'>{batsman.strike_rate}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="overflow-x-auto rounded-b border border-opacity-40 mt-4 md:mt-0">
          <div >
            <div className="bg-blue-100">
              <div className='text-left text-sm grid grid-cols-8'>
                <p className='lg:px-2 md:px-2 px-0.5 py-2 font-medium col-span-3'>Bowlers</p>
                <p className="lg:px-2 md:px-2 px-0.5 py-2 text-gray-500 ">O</p>
                <p className="lg:px-2 md:px-2 px-0.5 py-2 text-gray-500 ">M</p>
                <p className="lg:px-2 md:px-2 px-0.5 py-2 text-gray-500 ">R</p>
                <p className="lg:px-2 md:px-2 px-0.5 py-2 text-gray-500 ">W</p>
                <p className="lg:px-2 md:px-2 px-0.5 py-2 text-gray-500 ">Econ</p>
              </div>
            </div>
            <div>
              {infoData?.bowlers?.map((bowler, index) => (
                <div key={index} className="grid grid-cols-8 text-sm">
                  <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2 font-semibold col-span-3'>{bowler.name}</p>
                  <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2 font-semibold'>{bowler.overs}</p>
                  <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2'>{bowler.maidens}</p>
                  <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2'>{bowler.runs_conceded}</p>
                  <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2'>{bowler.wickets}</p>
                  <p className='lg:px-2 md:px-1 px-0.5 sm:text-sm text-xs py-2'>{bowler.econ}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        {commentryData?.map((obj, index) => (
          <div key={index}>
            {obj.event === 'overend' ? (
              <OverendCommentary data={obj} />
            ) : (
              <CommonCommentary data={obj} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Live;
