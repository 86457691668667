import React from 'react'

const VideoIcon = () => {
  return (
    <svg
                    width="68"
                    height="68"
                    viewBox="0 0 68 68"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="opacity-100"
                  >
                    <g filter="url(#filter0_d_583_10249)">
                      <circle cx="34" cy="30" r="20" fill="white" />
                      <circle
                        cx="34"
                        cy="30"
                        r="19.9"
                        stroke="#E3E3E4"
                        strokeWidth="0.2"
                      />
                    </g>
                    <path
                      d="M40.0287 28.6311L32.6173 23.4843C31.5121 22.7169 30 23.5078 30 24.8533V35.1469C30 36.4924 31.5121 37.2833 32.6173 36.5158L40.0287 31.369C40.9834 30.7061 40.9834 29.2941 40.0287 28.6311Z"
                      fill="#3A32D1"
                    />
                    <defs className="z-50">
                      <filter
                        id="filter0_d_583_10249"
                        x="0"
                        y="0"
                        width="68"
                        height="68"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="7" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_583_10249"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_583_10249"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
  )
}

export default VideoIcon
