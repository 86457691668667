import React from "react";
import instagram from '../../Assets/instagram.svg';
import facebook from '../../Assets/facebook.svg';
import youtube from '../../Assets/youtube.svg';
import twitter from '../../Assets/x(twitter).svg';
import outward from "../../Assets/arrow_outward.svg";

const socialLinks = [
  {
    name: "Instagram",
    url: "https://www.instagram.com/cricketgullyofficial/",
    icon: instagram,
  },
  {
    name: "Facebook",
    url: "https://www.facebook.com/cricketgully.official/",
    icon: facebook,
  },
  {
    name: "Youtube",
    url: "https://www.youtube.com/channel/UCTKws3gbVaxeLJv4yXDorVQ",
    icon: youtube,
  },
  {
    name: "Twitter",
    url: "https://x.com/thecricketgully",
    icon: twitter,
    displayName: "X (Twitter)",
  },
];


const FollowUs = () => {
  return (
    <div className="bg-white rounded-xl">
      <h2 className='md:text-lg text-sm md:mb-4 font-bold text-center'>Follow Us</h2>
      <div className="text-sm md:space-y-3 md:block flex items-center md:justify-between justify-around">
        {socialLinks.map((link, index) => (
          <div key={index} className={`py-2 flex justify-between ${index !== socialLinks.length - 1 ? 'md:border-b border-[#e9e9e9]' : ''}`}>
            <a
              className="flex flex-col md:flex-row items-center"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={link.icon} alt={link.name} className="md:h-6 md:w-6 w-10 h-10" />
              <p className="md:pl-3 md:text-sm text-[10px] md:font-semibold font-medium md:text-[#3A3A3A] text-[#242526]">
                {link.displayName || link.name}
              </p>
            </a>
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={outward} alt="Outward" className="hidden md:block opacity-10 hover:opacity-95" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(FollowUs);