import React from 'react'
import { useAxiosSWR } from '../../../../../../../axiosInstance';
import ApexChart from '../../../../Home/common/ApexChart';

const WromChat = ({matchId}) => {
    const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/chart/scoreByOvers`);
    if(isLoading){
        return <div></div>
    }
    if(error){
        return <div>somthing want wrong </div>
    }
    return (
        <div>
            <ApexChart data={data} type="line" />
        </div>
    )
}

export default WromChat
