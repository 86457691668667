export function getRightOrder(teamA, teamB) {
    // if (!teamA.scores && !teamB.scores) {
    //     return [teamA, teamB];
    // }

    // if (!teamA.scores && teamB.scores) {
    //     return [teamB, teamA];
    // }

    // if (teamA.scores && !teamB.scores) {
    //     return [teamA, teamB]
    // }

    return [teamA, teamB];
}

/*
[ {
        "scores": null,
        "overs": null
      },
       {
        "scores": "157/1",
        "overs": "12.4"
      }
]
*/

export const shimmerData = [];
for (let i = 0; i < 5; i++) {
  shimmerData.push(
    {
      isShimmer: true,
      id: "",
      slug: "",
      teama: { logo: "", short_name: "" },
      teamb: { logo: "", short_name: "" },
      subtitle: "",
      format: "",
      status_note: "",
      tournament_name: "",
      status: "Live",
    },
    {
      isShimmer: true,
      id: "",
      slug: "",
      teama: { logo: "", short_name: "" },
      teamb: { logo: "", short_name: "" },
      subtitle: "",
      format: "",
      game_state_str: "",
      tournament_name: "",
      dateStrings: {
        "string1": "",
        "string2": ""
      },
      status: "Scheduled",
    },
    {
      isShimmer: true,
      id: "",
      slug: "",
      teama: { logo: "", short_name: "" },
      teamb: { logo: "", short_name: "" },
      subtitle: "",
      format: "",
      status_note: "",
      tournament_name: "",
      status: "Completed",
    }
  );
}