import React from 'react'
import { logoData } from './constants'
import weath1 from "../../../../Assets/Frame(1).svg";
import weath2 from "../../../../Assets/Frame(2).svg";
import weath3 from "../../../../Assets/Group.svg";
import rainyIcon from "../../../../Assets/rain.svg";

const Weather = ({ data, page }) => {
    const { temp, humidity, wind_speed } = data || {};
    if (temp === undefined && humidity === undefined && wind_speed === undefined) {
        return null;
    }
    return (
        <div>
            <div id="weather-report" className={`my-4 md:my-7 ${page === "matchInfo" ? "rounded-2xl" : "rounded-b-2xl"} md:rounded-[14px] border-[#DAE6F8] border bg-white`}>
                <div className={`bg-[#E8F1FF] border-[#DAE6F8] border-b md:rounded-t-[14px] ${page === "matchInfo" ? "rounded-t-2xl" : ""} md:text-2xl text-base leading-6 p-4 font-bold`}>
                    <div className="flex items-center gap-2 md:justify-start justify-center">
                        <img src={logoData.weatherReport.logo} alt={logoData.weatherReport.heading} className="w-5 h-6" />
                        {logoData.weatherReport.heading}
                    </div>
                    {/* <p className="text-[10px] text-[#4F4F4F] font-normal md:text-start text-center">{`----`}</p> */}
                </div>
                <div className="md:p-6 md:grid grid-cols-10 justify-center items-center">
                    <div className="col-span-2 flex md:justify-normal justify-center items-center gap-2 md:p-0 p-4 md:border-none border-b">
                        <img src={rainyIcon} alt={rainyIcon} className="w-[60px] h-[60px]" />
                        <div>
                            <span className="md:text-2xl text-[16px] font-semibold">{temp || 0}</span>
                            <span className="md:text-2xl text-[16px] font-semibold md:hidden">° C Cloudy</span>
                        </div>
                    </div>
                    <div className="col-span-2 md:text-2xl text-[16px] font-semibold md:block hidden">
                        <p>Cloudy</p>
                    </div>
                    <div className="flex justify-around md:col-span-6 md:p-0 py-4 px-2">
                        <div className="col-span-2 flex md:gap-2 gap-1">
                            <img src={weath1} alt={weath1} className="md:w-5 md:h-6 w-4 h-4" />
                            <span className="md:text-base text-[10px] md:opacity-90 opacity-60">{`${temp || 0} % Chance`}</span>
                        </div>
                        <div className="col-span-2 flex md:gap-2 gap-1">
                            <img src={weath2} alt={weath2} className="md:w-5 md:h-6 w-4 h-4" />
                            <span className="md:text-base text-[10px] md:opacity-90 opacity-60">{`${humidity || 0} % humidity`}</span>
                        </div>
                        <div className="col-span-2 flex md:gap-2 gap-1">
                            <img src={weath3} alt={weath3} className="md:w-5 md:h-6 w-4 h-4" />
                            <span className="md:text-base text-[10px] md:opacity-90 opacity-60">{`Wind ${wind_speed || 0}`}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Weather
