import React, { useState } from "react";
import { ShimmerEffect } from "./ShimmerEffect";
import { getDateFormate } from "../pages/Schedule/ScheduleInstance/helper";
import { getSanitizedArray } from "../../helpers/general";
const COLUMNS = [
  {
    display: "P",
    key: "played",
  },
  {
    display: "W",
    key: "win",
  },
  {
    display: "L",
    key: "loss",
  },
  {
    display: "D",
    key: "draw",
  },
  {
    display: "PTS",
    key: "points",
  },
  {
    display: "NRR",
    key: "netrr",
  },
];

const CommonPointTable = ({ data, shimmerClassName }) => {
  const tableData = getSanitizedArray(data.groups);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const handalClick = (newTeam) => {
    setSelectedTeam((prevTeam) => {
      if (prevTeam !== newTeam) {
        return newTeam;
      }
      return null;
    });
  };
  if(data.groups.length < 1){
    return <div className="text-xl font-semibold">No Point Table Found</div>
  }
  return (
    <div className="divide-y divide-gray-200">
      {tableData.map(({ name, table }, index) => (
        <div className="w-full text-xs md:text-sm">
          {tableData.length > 1 &&       
        <div className={`md:min-h-[18px] md:min-w-[180px] px-5 md:py-6 py-3 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center`}>
        <span className="custom-border-left mr-3 md:mr-6"></span>
        <h1 className='text-lg md:text-2xl'>{name}</h1>
        <span className="custom-border-right ml-3 md:ml-6"></span>
      </div>}
          <div className="bg-[#EEF0F3] rounded-xl leading-5 font-bold">
            <div className="grid grid-cols-12 text-center items-center">
              <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2.5 text-start col-span-5">
                Team
              </div>
              {COLUMNS.map((column, index) => (
                <div
                  key={index}
                  className="lg:px-6 md:px-4 sm:px-2 px-1 py-3 col-span-1"
                >
                  {column.display}
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white divide-y divide-gray-200">
            {table.map((team, index) => (
              <div key={index}>
                <div
                  className="grid grid-cols-12 items-center cursor-pointer hover:bg-[#3a32d109]"
                  onClick={() => handalClick(team)}
                >
                  <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2 flex items-center col-span-5">
                    <ShimmerEffect
                      src={team.thumb_url}
                      alt="icon"
                      className="md:h-10 md:w-10 h-6 w-6 mr-3"
                    />
                    <p className={`${shimmerClassName} min-h-6 min-w-32`}>{team.abbr}</p>
                  </div>
                  {COLUMNS.map((column, columnIndex) => (
                    <div
                      key={columnIndex}
                      className="col-span-1 lg:px-6 md:px-4 sm:px-2 px-0.5 py-2 text-center text-blue-800"
                    >
                      <p className={`${shimmerClassName}`}>
                      {team[column.key]}
                      </p>
                    </div>
                  ))}
                  <div className="col-span-1 flex justify-end md:mr-5">
                    {selectedTeam === team ? (
                      <div>
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 0.625389L12 6.62539L10.6 8.02539L6 3.45039L1.4 8.02539L0 6.62539L6 0.625389Z"
                            fill="#1C1B1F"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div>
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 8.37461L0 2.37461L1.4 0.974609L6 5.54961L10.6 0.974609L12 2.37461L6 8.37461Z"
                            fill="#1C1B1F"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
                  <div className={`team-scorecard ${selectedTeam === team ? "expanded" : "collapsed"
                    }`}>
                    <div>
                      <div>
                        <div className="mb-4 ">
                          <div className="grid grid-cols-6 rounded-t-2xl text-[10px] md:text-base p-4 bg-[#E8F1FF] border border-[#DAE6F8]">
                            <div className="col-span-2 leading-5 font-bold">
                              Opponent
                            </div>
                            <div className="col-span-1 leading-5 font-bold">
                              Description
                            </div>
                            <div className="col-span-1 leading-5 font-bold">
                              Date
                            </div>
                            <div className="col-span-2 leading-5 font-bold">
                              Result
                            </div>
                          </div>
                          <div className="rounded-b-2xl bg-[#F9FBFF] border border-t-0 border-[#DAE6F8]">
                            {getSanitizedArray(team.matches).map(
                              (item, itemIndex) => (
                                <div
                                  key={itemIndex}
                                  className="grid grid-cols-6 px-2 md:px-4 "
                                >
                                  <div className="col-span-2  font-medium text-[10  px] md:text-[14px] py-1 md:py-1.5">
                                    {item.opponent_name}
                                  </div>
                                  <div className="col-span-1 font-medium text-[10 px] md:text-[14px] py-1 md:py-1.5">
                                    {item.subtitle}
                                  </div>
                                  <div className="col-span-1 font-medium text-[10 px] md:text-[14px] py-1 md:py-1.5">
                                    {getDateFormate(item.date_start, "dd mmm")}
                                  </div>
                                  <div
                                    className={`col-span-2 font-medium text-[10 px] md:text-[14px] py-1 md:py-1.5 ${item.is_winning ? "text-[#3A32D1]" : "text-red-600"}`}
                                  >
                                    {item.status_note}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommonPointTable;
