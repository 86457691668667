import { MONTH_NAMES } from "../../../../helpers/dates";
import { getSanitizedArray } from "../../../../helpers/general";

/**
 * date str formate like a Wed May 22 2024 00:00:00 GMT+0530 (India Standard Time)
 * @param {string} dateStr - The date string to format.
 * @param {string} [format="yyyy-mm-dd"] - The desired date format. Default is "yyyy-mm-dd".
 *    - "yyyy-mm-dd"  : Returns date in "2024-05-31" format.
 *    - "yyyy-mmm-dd" : Returns date in "2024-May-31" format.
 *    - "dd mmm"      : Returns date in "31 May" format.
 *    - "YYYY-MM-DD"  : Returns date in "2024-05-31" format.
 * @returns {string} The formatted date string.
 */
export const getDateFormate = (dateStr, formate = "yyyy-mm-dd") => {
  const newDate = new Date(dateStr);
  const year = newDate.getFullYear();
  const month = newDate.getMonth();
  const day = newDate.getDate();
  let date = day < 10 ? "0" + day : day
  const monthNumber = month + 1;

  const paddedMonthNum = monthNumber < 10 ? '0' + monthNumber : monthNumber;
  switch (formate) {
    case "yyyy-mmm-dd":
      return `${year}-${MONTH_NAMES[month]}-${date}`
    case "dd mmm":
      return `${date} ${MONTH_NAMES[month]}`
    case "YYYY-MM-DD":
      return `${year}-${paddedMonthNum}-${date}`
    default:
      return `${year}-${paddedMonthNum}-${date}`;
  }
};

export const processOnData = ({ data, fromDate, toDate, schduleType }) => {
  const mockData = getScheduleMockData();

  const { matches, gameFormats: gFormats } = data.isShimmer ? mockData : data;

  const gameFormats = getSanitizedArray(gFormats);
  const datesList = data.isShimmer ? Object.keys(data.matches) : schduleType === "live" ? getSanitizedArray(data.dateList) : generateDatesFromRange(fromDate, toDate, schduleType);

  // Initialize new state values
  const newGameFormats = [];
  const newMatchObj = {};
  const newDatesMatches = {};
  const gameFormatsByDate = {};

  // Populate game formats
  gameFormats?.forEach((format) => {
    if (!newGameFormats.includes(format)) {
      newGameFormats.push(format);
    }
  });

  // Populate dates and matches
  datesList.forEach((date) => {
    const matchesForDate = matches?.[date]?.map((match) => match.match_id) || [];
    newDatesMatches[date] = [...matchesForDate];

    matches?.[date]?.forEach((match) => {
      newMatchObj[match.match_id] = match;
    });
  });

  for (const dateKey in newDatesMatches) {
    const matchIds = newDatesMatches[dateKey];
    matchIds?.forEach(matchId => {
      const match = newMatchObj[matchId];
      gameFormatsByDate[dateKey] = gameFormatsByDate[dateKey] || {};
      match.format && (gameFormatsByDate[dateKey][match.format] = match.format);
    });

    gameFormatsByDate[dateKey] && (gameFormatsByDate[dateKey] = Object.values(gameFormatsByDate[dateKey]).sort())
  }
  // Set new state
  const res = {
    datesList,
    gameFormats: newGameFormats,
    matchesById: newMatchObj,
    matchListByDates: newDatesMatches,
    gameFormatsByDate
  }

  return res;
};

function generateDatesFromRange(fromDate, toDate, schduleType) {
  const dates = [];
  if (schduleType === "upcoming") {
    let currentDate = new Date(fromDate);
    const endDate = new Date(toDate);
    while (currentDate < endDate) {
      dates.push(getDateFormate(new Date(currentDate)));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  let currentDate = new Date(toDate);
  const endDate = new Date(fromDate);
  while (currentDate <= endDate) {
    dates.splice(0, 0, getDateFormate(new Date(currentDate)));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  dates.pop();
  return dates;
};
export function getSnakeCaseId(str) {
  if (typeof str !== 'string') {
    return '';
  }
  return str.replace(/[^a-zA-Z0-9]/g, "_");
}
export const getScheduleMockData = () => {
  const LiveMockData = {
    isShimmer: true,
    "gameFormats": [
      "T20",
      "T20I",
      "T10"
    ],
    "dateList": [
      "1990-01-01",
      "1990-01-02"
    ],
    "matches": {
      "1990-01-01": [
        {
          "match_id": 77384,
          "title": "",
          "short_title": "",
          "subtitle": "",
          "status": "",
          "date_start_utc": "",
          "date_start": "",
          "status_note": "",
          "format": "T20",
          "game_state": 0,
          "game_state_str": "",
          "teama": {
            "team_id": 128506,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "",
            "overs": "",
            "max_overs": "0"
          },
          "teamb": {
            "team_id": 128508,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "",
            "overs": "",
            "max_overs": ""
          },
          "venue_id": 1213564,
          "toss": "",
          "tournament_title": "",
          "cid": 128677,
          "tournament_name": "",
          "tournament_type": "",
          "venue": "",
          "match_date": "",
          "priority": 0,
          "ball": "",
          "slug": "",
          "tournament_slug": ""
        }
      ],
      "1990-01-02": [
        {
          "match_id": 77644,
          "title": "",
          "short_title": "",
          "subtitle": "",
          "status": "",
          "date_start_utc": "",
          "date_start": "",
          "status_note": "",
          "format": "T20I",
          "game_state": 6,
          "game_state_str": "",
          "teama": {
            "team_id": 69630,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "",
            "overs": "",
            "max_overs": ""
          },
          "teamb": {
            "team_id": 69750,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "",
            "overs": "",
            "max_overs": ""
          },
          "venue_id": 1213567,
          "toss": "",
          "tournament_title": "",
          "cid": 128686,
          "tournament_name": "",
          "tournament_type": "",
          "venue": "Episkopi, ",
          "match_date": "",
          "priority": 0,
          "ball": "",
          "slug": "",
          "tournament_slug": ""
        },
        {
          "match_id": 77677,
          "title": "",
          "short_title": "",
          "subtitle": "",
          "status": "",
          "date_start_utc": "",
          "date_start": "",
          "status_note": "",
          "format": "T10",
          "game_state": 3,
          "game_state_str": "",
          "teama": {
            "team_id": 124742,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "",
            "overs": "",
            "max_overs": ""
          },
          "teamb": {
            "team_id": 124741,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "",
            "overs": "",
            "max_overs": ""
          },
          "venue_id": 1213566,
          "toss": "",
          "tournament_title": "",
          "cid": 128685,
          "tournament_name": "",
          "tournament_type": "",
          "venue": "",
          "match_date": "",
          "priority": 0,
          "ball": "",
          "slug": "",
          "tournament_slug": ""
        },
        {
          "match_id": 77813,
          "title": "",
          "short_title": "",
          "subtitle": "",
          "status": "",
          "date_start_utc": "",
          "date_start": "",
          "status_note": "",
          "format": "T10",
          "game_state": 3,
          "game_state_str": "",
          "teama": {
            "team_id": 116095,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "",
            "overs": "",
            "max_overs": ""
          },
          "teamb": {
            "team_id": 115927,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "",
            "overs": "",
            "max_overs": ""
          },
          "venue_id": 1213393,
          "toss": "",
          "tournament_title": "",
          "cid": 128693,
          "tournament_name": "",
          "tournament_type": "",
          "venue": "",
          "match_date": "",
          "priority": 0,
          "ball": "",
          "slug": "",
          "tournament_slug": ""
        },
        {
          "match_id": 77556,
          "title": "",
          "short_title": "",
          "subtitle": "",
          "status": "",
          "date_start_utc": "",
          "date_start": "",
          "status_note": "",
          "format": "T20",
          "game_state": 2,
          "game_state_str": "",
          "teama": {
            "team_id": 128511,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "",
            "overs": "",
            "max_overs": ""
          },
          "teamb": {
            "team_id": 128515,
            "name": "",
            "short_name": "",
            "logo": "",
            "scores": "",
            "overs": "",
            "max_overs": "20"
          },
          "venue_id": 18,
          "toss": "",
          "tournament_title": "",
          "cid": 128683,
          "tournament_name": "",
          "tournament_type": "",
          "venue": "",
          "match_date": "",
          "priority": 0,
          "ball": "",
          "slug": "",
          "tournament_slug": ""
        }
      ]
    },
    "hasNextPage": false
  };
  return LiveMockData;
}