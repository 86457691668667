import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAxiosSWR } from "root/axiosInstance";
import VideosContainer from '../../../videosV2';
import { getId } from './helper';
import SEOMeta from '../../Home/common/SEOMeta';
import { formatDate } from '../../../../helpers/dates';

const SeriesVideos = () => {
  const { id: slug } = useParams();
  const seriesId = getId(slug);
  const { data, error: isError, isLoading } = useAxiosSWR(`/v1/series/${seriesId}/videos`);
  const videoData = data?.videos || [];

  const [open, setOpen] = useState(false);
  const [youTubeUrl, setYouTubeUrl] = useState('');

  const onClose = () => {
    setOpen(false);
  };

  const [seoMeta, setSEOMeta] = useState(null);
  useEffect(() => {
    if (data?.series) {
      const seriesData = data.series;
      seriesData.game_format = seriesData.game_format || '';
      setSEOMeta({
        title: `${seriesData.title} ${seriesData.season} Videos | ${seriesData.game_format.toUpperCase()} | ${seriesData.abbr} | Cricketgully`,
        description: `Videos of ${seriesData.title} ${seriesData.season} scheduled on ${formatDate(seriesData.date_start, false)} to ${formatDate(seriesData.date_end, false)} | ${seriesData.game_format.toUpperCase()} | ${seriesData.abbr}`,
        canonicalURL: `/series/${seriesData.slug}/schedule`,
      });
    }
  }, [data]);

  return (
    <div className="bg-white rounded-2xl border overflow-auto md:mx-0 mx-4">
      {seoMeta && <SEOMeta data={seoMeta} />}
      <VideosContainer
        videosData={videoData || []}
        open={open}
        setOpen={setOpen}
        youTubeUrl={youTubeUrl}
        setYouTubeUrl={setYouTubeUrl}
        onClose={onClose}
        isLoading={isLoading}
        hasError={isError}
        title="Top India Team Videos"
      />
    </div>
  );
};

export default SeriesVideos;
