import React, { useState } from "react";
import usePost from "../../../hooks/usePost";

const MobileInputForm = ({ handleSuccess, mobileNumber }) => {
  const [phoneNumber, setPhoneNumber] = useState(mobileNumber);
  const reqObjLogin = usePost();
  const { isLoading, error } = reqObjLogin;
  
  const handlePhoneNumberChange = (event) => {
    let value = event.target.value;
    if (value.includes(".")) {
      value = value.replace(".", "");
    }
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
  }
  const handleLoginSubmit = async () => {
    try {
      if (mobileNumber !== phoneNumber) {
        if (phoneNumber.length === 10 ) {
          const payload = { mobile: phoneNumber };
          const response = await reqObjLogin.makeRequest(
            "v1/user/login",
            payload
          );
          if (response.status === "success") {
            const data = response?.data;
            handleSuccess(data?.requestId || "", phoneNumber);
          }
        }
      }else{
        handleSuccess( "" , phoneNumber)
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
    }
  };
  return (
    <div className="mb-4 ">
      <h2 className="font-bold text-center text-[16px] sm:text-[24px]">
        Login/Register With
      </h2>
      <p className="font-normal text-[#77787A] text-center text-[12px] sm:text-[16px] sm:mt-1 mt-2">
        Choose one of the options to go
      </p>
      {/* <div className="mt-10 mb-7">
        <div className="relative  ">
          <span className="absolute inset-y-0 left-0 pl-4 flex justify-center items-center font-bold">
            +91
          </span>
          <input
            type="number"
            id="contact"
            name="contact"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            className=" font-bold pl-12 p-4 block sm:w-[330px] w-full sm:h-[50px] h-60px rounded-xl border-gray-300 border outline-none focus:ring-1 focus:ring-[#3A32D1]"
            placeholder="Enter Phone number"
          />
        </div>
        {error && (
          <p className="text-red-700 pt-1">Enter a valid moible number</p>
        )}
      </div> */}
      {/* <div className="flex justify-center text-white">
        <button
          type="submit"
          disabled={phoneNumber.length !== 10 || isLoading}
          onClick={handleLoginSubmit}
          className={`sm:w-[330px] w-full sm:h-[46px] h-[60px] rounded-xl py-3 ${
            phoneNumber && phoneNumber.length === 10
              ? "bg-[#3A32D1]"
              : "bg-[#3A32D1] opacity-40 cursor-not-allowed"
          } ${!phoneNumber ? "shadow-none" : "shadow-lg"}`}
        >
          Log in
        </button>
      </div> */}
    </div>
  );
};

export default MobileInputForm;
