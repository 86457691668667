import { Link } from "react-router-dom";
import { getNewsDetailsLinkPath, doScrollToTop } from "../../helpers/general";
import { formatDate } from "../../helpers/dates";
import { ShimmerEffect } from "./ShimmerEffect";

const NewsList = ({ data, newsId, isError }) => {
  const dataArray = newsId ? data.filter((obj) => obj.id.toString() !== newsId) : data
  const classname = data?.[0]?.isShimmer ? "bg-gray-300 loading" : ""
  return (
    <div> 
      { isError ? (
        <h2 className="text-2xl font-semibold mb-4"></h2>
      ) : dataArray.length > 0 ? (
            dataArray.map((obj, i) => (
              <Link
                key={i}
                to={getNewsDetailsLinkPath(obj.slug, obj.id, obj.type)}
                onClick={doScrollToTop}
              >
                <div key={i} className={`border-[#DADADB] ${ i === dataArray.length - 1 ? "" : "border-b" }`}>
                  <div className="lg:grid lg:grid-cols-10 py-5">
                    <div className="lg:col-span-3 mx-3">
                      {obj.isShimmer ? <div className={`md:h-[250px] h-[170px] lg:h-[120px] w-full object-cover rounded-xl md:mb-0 mb-2 ${classname}`}></div> : <ShimmerEffect
                        src={obj.image}
                        alt="news"
                        className="md:h-[250px] h-[170px] lg:h-[120px] w-full object-cover rounded-xl md:mb-0 mb-2"
                      />}
                    </div>
                    <div className="lg:col-span-7 px-[10px] lg:w-auto mb-0 md:mb-3 md:pt-0 pt-1">
                      <p
                        className={`${classname} min-h-[56px] md:text-lg lg:text-xl mx-1 md:mx-0 font-semibold md:font-bold `}
                        dangerouslySetInnerHTML={{
                          __html: obj.title,
                        }}
                      ></p>
                      <p
                        className={`${classname} min-h-10 text-sm break-words text-[#242323] news-excript`}
                        dangerouslySetInnerHTML={{
                          __html: obj.excerpt,
                        }}
                      />
                        <p className={`${classname} min-h-4 min-w-16 text-xs mx-1 md:mx-0 text-[#6C6C6C]`}>
                          {!obj.isShimmer && formatDate(obj.date)}
                        </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <h2 className="text-xl font-semibold my-4 ml-4">No data available</h2>
          )}
        </div>
      );
    };

export default NewsList;
