import React from 'react'
import FantasyCommon from '../../../../common/FantasyCommon';
import { useParams } from 'react-router-dom';
import { getSeriesMatchID } from './common/helper';

const Fantasy2 = () => {
  const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
  const { matchId } = getSeriesMatchID(seriesSlug, matchSlug);
  return (
    <div className='md:mt-5 md:mx-5 md:p-5 md:bg-white md:rounded-2xl md:border mx-1'>
      <FantasyCommon matchId={matchId} pageType={"match-details"}/>
    </div>
  )
}

export default Fantasy2
