export const MONTH_NAMES = Object.freeze([
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]);

/* where date time is <= current date time*/
export const getTimeLabelForPastDateTime = (utcDateString) => {
  try {
    const now = new Date();
    const utcDate = new Date(utcDateString);

    if (utcDate.getTime() > now.getTime()) {
      return formatDate(utcDateString, true);
    }
    if (
      now.getFullYear() === utcDate.getFullYear() &&
      now.getDate() === utcDate.getDate() &&
      now.getMonth() === utcDate.getMonth()
    ) {
      /* here you arrived that means utc date is of today only and time of < current time. */
      const diff = now.getTime() - utcDate.getTime();
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      if (seconds < 60) {
        return `${seconds}s ago`;
      } else if (minutes < 60) {
        return `${minutes}m ago`;
      } else if (hours < 24) {
        return `${hours}h ago`;
      }
    } else {
      return formatDate(utcDateString, true);
    }


  } catch (error) {
    console.log('Error occurred ', error);
    return formatDate(utcDateString, true);
  }
};
window.getTimeLabelForPastDateTime = getTimeLabelForPastDateTime;

export const formatDate = (dateString, needTime = true) => {
  try {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    if (needTime) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // handle midnight
      minutes = minutes < 10 ? "0" + minutes : minutes;
      return `${day}-${MONTH_NAMES[monthIndex]}-${year} • ${hours}:${minutes} ${ampm}`;
    } else {
      return `${day}-${MONTH_NAMES[monthIndex]}-${year}`;
    }
  } catch (error) {
    return "";
  }
};

export const formatDay = (dateString) => {
  try {
    const date = new Date(dateString);
    const options = { weekday: "short" };
    const dayOfWeek = date.toLocaleDateString("en-US", options);
    return dayOfWeek;
  } catch (error) {
    return ""; // Or handle the error in a way that fits your application
  }
};

export const formatTime = (dateString) => {
  try {
    const date = new Date(dateString);
    const localFormattedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const utcFormattedTime = date.toUTCString().match(/(\d{2}:\d{2}:\d{2})/)[0];

    return {
      localTime: localFormattedTime,
      utcTime: utcFormattedTime,
    };
  } catch (error) {
    return {
      localTime: "",
      utcTime: "",
    };
  }
};

const RecentMatchformatDateTime = (dateString, options) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', options);
};

export const RecentMatchformatDates = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
};

export const RecentMatchformatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};

export const RecentMatchformatTimeGMT = (dateString) => {
  return RecentMatchformatDateTime(dateString, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'GMT'
  }) + ' GMT';
};

export const RecentMatchformatTimeLocal = (dateString) => {
  return RecentMatchformatDateTime(dateString, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  }) + ' LOCAL';
};

export function formattedDate(dateStr) {
  if (!dateStr) {
    return "";
  }
  const options = { day: '2-digit', month: 'short' };
  const date = new Date(dateStr);
  return date.toLocaleDateString('en-GB', options);
}


