import React, { useCallback, useEffect, useMemo, useState } from "react";
import { formatDate, RecentMatchformatDates } from "../../../../helpers/dates";
import { doScrollToTop, scrollSmooth } from "../../../../helpers/general";
import { useParams } from "react-router-dom";
import { useAxiosSWR } from "root/axiosInstance";
import { getId } from "./helper";
import SEOMeta from "../../Home/common/SEOMeta";
import { MatchBox } from "../../../common/MatchBoxes/MatchBox";

const SeriesFixtures = () => {
  const { id: slug } = useParams();
  const [seoMeta, setSEOMeta] = useState(null);

  const seriesId = getId(slug);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedVenue, setSelectedVenue] = useState("");

  const reqObj = useAxiosSWR(`/v1/series/${seriesId}/fixtures`);
  const { data, error, isLoading } = reqObj;
  const fixturesData = data?.fixtures || [];
  const seriesData = data?.series || {};

  // Function to group fixtures by date with filters applied
  const groupFixturesByDate = useCallback(() => {
    const groupedFixtures = {};

    fixturesData.forEach((obj) => {
      const date = formatDate(obj.date_start, false);

      if (
        (!selectedTeam || obj?.team_a?.name === selectedTeam || obj?.team_b?.name === selectedTeam) &&
        (!selectedVenue || obj.venue === selectedVenue)
      ) {
        if (!groupedFixtures[date]) {
          groupedFixtures[date] = [];
        }

        groupedFixtures[date].push(obj);
      }
    });

    return groupedFixtures;
  }, [fixturesData, selectedTeam, selectedVenue]);

  const groupedFixtures = groupFixturesByDate();

  const { venues, teams } = useMemo(() => {
    const venueSet = new Set();
    const teamSet = new Set();

    fixturesData.forEach((fixture) => {
      venueSet.add(fixture.venue);
      teamSet.add(fixture?.team_a?.name);
      teamSet.add(fixture?.team_b?.name);
    });

    return {
      venues: Array.from(venueSet).sort(),
      teams: Array.from(teamSet).sort(),
    };
  }, [fixturesData]);

  const venueDropdown = useMemo(
    () => (
      <select
        id="venue-dropdown"
        className="border w-1/2 bg-indigo-100 border-[#A19DF1] rounded-md px-2 p-1 focus:bg-white"
        onChange={(e) => setSelectedVenue(e.target.value)}
        value={selectedVenue}
      >
        <option value="">Venue</option>
        {venues.map((venue, index) => (
          <option key={index} value={venue}>
            {venue}
          </option>
        ))}
      </select>
    ),
    [venues, selectedVenue]
  );

  const teamDropdown = useMemo(
    () => (
      <select
        id="team-dropdown"
        className="border w-1/2 bg-indigo-100 border-[#A19DF1] rounded-md px-2 p-1 focus:bg-white"
        onChange={(e) => setSelectedTeam(e.target.value)}
        value={selectedTeam}
      >
        <option value="">Team</option>
        {teams.map((team, index) => (
          <option key={index} value={team}>
            {team}
          </option>
        ))}
      </select>
    ),
    [teams, selectedTeam]
  );

  useEffect(() => {
    if (fixturesData && fixturesData.length > 0) {
      const latestMatchIdByStatus = {};

      fixturesData.forEach((m) => {
        if (!latestMatchIdByStatus[m.status_str]) {
          latestMatchIdByStatus[m.status_str] = m.match_id;
        }
      });
      if (latestMatchIdByStatus.Live) {
        scrollSmooth(getIdForMatchDiv(latestMatchIdByStatus.Live));
      } else if (latestMatchIdByStatus.Scheduled) {
        scrollSmooth(getIdForMatchDiv(latestMatchIdByStatus.Scheduled));
      } else if (latestMatchIdByStatus.Completed) {
        doScrollToTop();
      }
    }
  }, [fixturesData]);

  useEffect(() => {
    if (seriesData.title && seriesData.season && seriesData.abbr) {
      seriesData.game_format = seriesData?.game_format || "";
      setSEOMeta({
        title: `${seriesData?.title} ${seriesData?.season} Schedule | ${seriesData?.game_format.toUpperCase()} | ${seriesData?.abbr} | Cricketgully`,
        description: `Fixtures of ${seriesData?.title} ${seriesData?.season} scheduled on ${formatDate(seriesData?.date_start, false)} to ${formatDate(seriesData?.date_end, false)} | ${seriesData?.game_format.toUpperCase()} | ${seriesData?.abbr}`,
        canonicalURL: `/series/${seriesData?.slug}/schedule`
      });
    }
  }, [seriesData.title, seriesData.season, seriesData.abbr, seriesData.game_format, seriesData.slug, seriesData.date_start, seriesData.date_end]);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div></div>;
  }

  return (
    <div className="bg-white md:rounded-2xl rounded-xl p-4 border px-2 overflow-auto md:mx-0 mx-4">
    <div className="mx-2 lg:mx-3 md:pt-3">
      {seoMeta && <SEOMeta data={seoMeta} />}
      <div
        className="mb-5
          lg:w-1/2 md:w-3/4 sm:w-full
          lg:gap-6 md:gap-4 sm:gap-2 gap-2
          flex justify-between"
      >
        {teamDropdown}
        {venueDropdown}
      </div>
      {Object.keys(groupedFixtures).map((date, index) => (
        <div key={index}>
          <div className={`my-6 md:min-h-[18px] md:min-w-[180px] min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center`}>
            {RecentMatchformatDates(date)}
            <span className="custom-border-right ml-6"></span>
          </div>
          <div className={`col-span-4 md:border border-[#E3E3E4] rounded-xl ${groupedFixtures[date]?.length > 0 ? '' : 'hidden'}`}>
            {groupedFixtures[date].map((match, idx) => (
              <div key={match.match_id} className={`${groupedFixtures[date]?.length - 1 !== idx ? `md:border-b` : "mt-4"}`}>
                <MatchBox
                  key={match.match_id}
                  match={match}
                  type="series"
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default SeriesFixtures;

function getIdForMatchDiv(match_id) {
  return `series_fixture_match_${match_id}`;
}