import React, { useState } from "react";
import { formatDate } from "../../../../helpers/dates";
import next from "../../../../Assets/next-svg.svg";
import privius from "../../../../Assets/prev-svg.svg";
import { useAxiosSWR } from "root/axiosInstance";
import { ShimmerEffect } from "../../../common/ShimmerEffect";
import { getSanitizedArray } from "../../../../helpers/general";
import { useTikTik } from "src/hooks/useTikTik";

const shimmerAry = [];
for (let i = 0; i < 5; i++) {
  shimmerAry.push({ image: "", title: "", date: "", isShimmer: true });
}

const LegendaryMomentum = () => {
  const { data, error: isError, isLoading } = useAxiosSWR("v1/misc/moments");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  useTikTik(data, setCurrentIndex);
  const legendaryMomentumData = getSanitizedArray(isLoading ? shimmerAry : data);
  const shimmerClassName = legendaryMomentumData?.[0]?.isShimmer
    ? "bg-gray-300 loading text-opacity-0"
    : "";

  const handleNext = () => {
    if (currentIndex < legendaryMomentumData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index);
  };

  // Swipe logic
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX); // Record starting touch position
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX); // Record touch movement
  };

  const handleTouchEnd = () => {
    // Determine swipe direction based on start and end positions
    const swipeDistance = touchStart - touchEnd;

    if (swipeDistance > 50) {
      handleNext(); // Swipe left
    }

    if (swipeDistance < -50) {
      handlePrevious(); // Swipe right
    }
  };

  if (isError) {
    return <h2></h2>;
  }

  return (
    <div>
      <div className="md:border md:py-4 pt-4 order-2 md:rounded-xl bg-white md:mt-5 mt-1 lg:order-1 h-[380px]">
        <h3 className="md:text-lg text-sm px-4 mb-4 font-bold leading-[18px] text-center">
          ⭐ Legendary Momentum
        </h3>
        {legendaryMomentumData.length > 0 && (
          <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            className="relative"
          >
            <div className="md:px-4 px-[10px] overflow-hidden relative">
              <ShimmerEffect
                className={`${shimmerClassName} h-[275px] w-full rounded-xl`}
                src={legendaryMomentumData[currentIndex].image}
                alt="Legendary Momentum"
              />
              <div className="absolute bottom-0 left-0 pl-8 py-4 w-[200px] max-w-[200px] text-[#FFFFFF]">
                <p className="text-lg">{legendaryMomentumData[currentIndex].title}</p>
                <p className="text-xs opacity-60">
                  {formatDate(legendaryMomentumData[currentIndex].date, false)}
                </p>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <button
                onClick={handlePrevious}
                disabled={currentIndex === 0}
                className={`disabled:opacity-50 ${
                  currentIndex === 0 ? "invisible" : "visible"
                }`}
              >
                <img src={privius} alt="Previous" className="" />
              </button>
              <div className="flex justify-center flex-grow overflow-hidden">
                {legendaryMomentumData.map((_, index) => (
                  <div
                    key={index}
                    className={`h-1 w-6 mx-0.5 rounded-full ${
                      index === currentIndex ? "bg-[#3A32D1]" : "bg-[#D8D6F6]"
                    } cursor-pointer`}
                    onClick={() => handleIndicatorClick(index)}
                  ></div>
                ))}
              </div>
              <button
                onClick={handleNext}
                disabled={currentIndex === legendaryMomentumData.length - 1}
                className={`disabled:opacity-50 ${
                  currentIndex === legendaryMomentumData.length - 1
                    ? "invisible"
                    : "visible"
                }`}
              >
                <img src={next} alt="Next" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LegendaryMomentum;
