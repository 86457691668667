import React, { useMemo } from 'react';
import { logoData } from './constants';
import { formatDate } from '../../../../helpers/dates';

const UpdatedAtText = React.memo(({ date }) => {
    if (!date) {
        return;
    }
    try {
        return <span data-utctime={date+ ""}>Updated {formatDate(date, true)}</span>
    } catch (error) {
        return '';
    }
});

const Teams = ({ data, updatedAt }) => {
    if (data?.length < 0) {
        return null
    }

    const memoizedValues = useMemo(() => {
        const teamNamesById = {
            [data?.[0]?.team_id]: data?.[0]?.name || '',
            [data?.[1]?.team_id]: data?.[1]?.name || '',
        };

        const getPlayers = (team) => ({
            playing11: team?.playing11 || [],
            bench: team?.bench || [],
            name: teamNamesById[team?.team_id] || '',
        });

        const teama = getPlayers(data?.[0]);
        const teamb = getPlayers(data?.[1]);

        return { [data?.[0]?.team_id]: teama, [data?.[1]?.team_id]: teamb };
    }, [data]);

    return (
        <div>
            <div id="match-teams" className="my-4 md:my-7 md:rounded-[14px] border-[#DAE6F8] border bg-white">
                <div className="bg-[#E8F1FF] md:rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
                    <div className="flex items-center gap-2 md:justify-start justify-center">
                        <img src={logoData.teams.logo} alt={logoData.teams.heading} className="md:w-5 md:h-6 w-4 h-4" />
                        {logoData.teams.heading}
                    </div>
                    <p className="md:text-[10px] text-[8px] md:text-start text-center text-[#4F4F4F] font-normal pt-1">
                        {memoizedValues[data?.[0]?.team_id]?.name || 'Team A'} • <UpdatedAtText date={updatedAt} />
                    </p>
                </div>
                <div className="md:px-8 md:py-6 p-4 border-t border-[#DAE6F8]">
                    <div>
                        <p className="md:text-2xl text-base italic text-justify font-semibold">
                            {memoizedValues[data?.[0]?.team_id]?.name || 'Team A'}
                        </p>
                        <p className="md:text-base text-xs md:py-6 py-2">
                            {memoizedValues[data?.[0]?.team_id]?.playing11.length > 0 ? (
                                memoizedValues[data?.[0]?.team_id]?.playing11.map((player) =>
                                (
                                    `${player.name} ${(player.role === "wk" || player.role === "c" || player.role === "vc") ? "(" + player.role + ")" : ''}`
                                )
                                ).join(', ')
                            ) : (
                                'No players available'
                            )}
                        </p>
                        <div className="bg-[#E8F1FF] border border-[#DAE6F8] rounded-[28px] md:px-5 py-2 px-3">
                            <p className="text-[#3A32D1]  opacity-50 md:text-base text-xs">
                                <span className="font-bold mr-1">BENCH:</span>
                                {memoizedValues[data?.[0]?.team_id]?.bench.length > 0 ? (
                                    memoizedValues[data?.[0]?.team_id]?.bench.map((player, index) => (
                                        <span key={index}>{player.name}{index < memoizedValues[data?.[0]?.team_id]?.bench.length - 1 ? ', ' : ''}</span>
                                    ))
                                ) : (
                                    'No players available'
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="md:px-8 md:py-6 p-4 border-t border-[#DAE6F8]">
                    <div>
                        <p className="md:text-2xl text-base italic text-justify font-semibold">
                            {memoizedValues[data?.[1]?.team_id]?.name || 'Team B'}
                        </p>
                        <p className="md:text-base text-xs md:py-6 py-2">
                            {memoizedValues[data?.[1]?.team_id]?.playing11.length > 0 ? (
                                memoizedValues[data?.[1]?.team_id]?.playing11.map((player) =>
                                (
                                    `${player.name} ${(player.role === "wk" || player.role === "c" || player.role === "vc") ? "(" + player.role + ")" : ''}`
                                )
                                ).join(', ')
                            ) : (
                                'No players available'
                            )}
                        </p>
                        <div className="bg-[#E8F1FF] border border-[#DAE6F8] rounded-[28px] md:px-5 py-2 px-3">
                            <p className="text-[#3A32D1] opacity-50 md:text-base text-xs">
                                <span className="font-bold mr-1">BENCH:</span>
                                {memoizedValues[data?.[1]?.team_id]?.bench.length > 0 ? (
                                    memoizedValues[data?.[1]?.team_id]?.bench.map((player, index) => (
                                        <span key={index}>{player.name}{index < memoizedValues[data?.[1]?.team_id]?.bench.length - 1 ? ', ' : ''}</span>
                                    ))
                                ) : (
                                    'No players available'
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Teams;
