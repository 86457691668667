import ReactGA from "react-ga4";

const InitializeGoogleAnalytics = () => {
    // Initialize UA
    ReactGA.initialize("G-7ZB9NR74E1");
  
    console.log("GA INITIALIZED");
};
const TrackGoogleAnalyticsEvent = (
    category,
    action,
    label
  ) => {
    console.log("GA event:", category, ":", action, ":", label);
    // Send UA Event
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};

const AnalyticsCategory ={
    MATCH: 'Match',
    SERIES: 'Series',
    ARTICLE: 'Article',
    USER: 'User',
}
export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, AnalyticsCategory };
