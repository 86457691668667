export const LOGIN_MODES = {
    OTP: 'otp',
    GOOGLE: 'google',
    APPLE: 'apple'
};

// local storage login keys
export const LS_LOGIN_KEYS = {
    TOKEN: 'cGullyLoginToken',
    MODE: 'cGullyLoginMode'
}
export const setCGullyLoginToken = (token) => {
    localStorage.setItem(LS_LOGIN_KEYS.TOKEN, token);
}

export const getCGullyLoginToken = () => {
    return localStorage.getItem(LS_LOGIN_KEYS.TOKEN) || '';
}

export const setCGullyLoginMode = (loginMode) => {
    if (Object.values(LOGIN_MODES).includes(loginMode)) {
        localStorage.setItem(LS_LOGIN_KEYS.MODE, loginMode);
        return;
    }
    throw new Error('Invalid value of login mode. It must be one of the ' + Object.values(LOGIN_MODES).join(", "))
}