import { CircularProgressBar } from "@tomickigrzegorz/react-circular-progress-bar";
import React from 'react';

const ChartBox = ({ percentage, logoUrl, teamCode, team }) => {
  const circleProps = {
    percent: percentage,
    colorSlice: "#4B78AB",
    colorCircle: "#EEEEEE",
    stroke: 5,
    number: false,
    size: '70px',
    animationSmooth: '500ms ease-out',
  };

  return (
    <div
      data-testid="chartContainer"
    >
      <div className="flex gap-1 md:block md:w-[75px]" style={{
        position: 'relative',
      }}>
        <div className={` ${team === "teamB" ? "order-2" : ""}`}>
        <CircularProgressBar {...circleProps} />
        </div>
        <div style={{ textAlign: "center", fontWeight: 700 }} className={`${team === "teamB" ? "right-[20px]" : "left-[20px]"} mt-1 md:hidden absolute top-[18px] flex-col items-center justify-center`}>
          {percentage}%
        </div>
        <div className={`flex items-center gap-2 md:hidden ${team === "teamB" ? "order-1" : ""}`}>
          <img
            src={logoUrl}
            className={`w-[25px] h-[25px] ${team === "teamB" ? "order-1" : ""}`}
            alt="Random image"
          />
          <div className="text-[14px] md:text-[12px] leading-[14px] font-bold ">
            {teamCode}
          </div>
        </div>
        <div
          className="hidden md:flex w-[75px] h-[75px] absolute top-[4px] left-[0px] flex-col items-center justify-center"
          data-testid="chart_1_text_container"
        >
          <img
            src={logoUrl}
            className="w-[22px] h-[22px]"
            alt="Random image"
          />
          <div className="text-[12px] font-bold">
            {teamCode}
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center", fontWeight: 700 }} className="hidden md:block">
        {percentage}%
      </div>
    </div>);
}

export const MemoizedChartBox = React.memo(ChartBox);