import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import InitializeGoogleAnalytics from "./helpers/analytics.js";


InitializeGoogleAnalytics();

const APP_ENV = process.env.APP_ENV || "local";

if (APP_ENV !== "local") {
  Sentry.init({
    dsn: "https://706db842e749aabc09851945bde1e34b@o4506919549927424.ingest.us.sentry.io/4507146244456448",
    // dsn: "https://709190a6db52e1014dd3bbbdaf9c9568@o4507833633341440.ingest.us.sentry.io/4507833635176448",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    environment: APP_ENV,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.cricketgully.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
