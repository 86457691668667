import React, { useRef, useState } from 'react';
import { getSeriesMatchID } from './common/helper';
import { useParams } from 'react-router-dom';
import Manhattan from './Live Stats/Manhattan';
import Runs from './Live Stats/Runs';
import RunRates from './Live Stats/RunRates';
import Wickets from './Live Stats/Wickets';
import WromChat from './Live Stats/WromChat';
import { useHorizontalScroll } from "../../../../../hooks/useHorizontalScroll";
const tabs = [
	{ lable: "Manhattan", value: "Manhattan" },
	{ lable: "Worm Chart", value: "WormChart" },
	{ lable: "Runs", value: "Runs" },
	{ lable: "Run Rates", value: "RunRates" },
	{ lable: "Wickets", value: "Wickets" },
];


const LiveStats = () => {
	const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
	const { matchId } = getSeriesMatchID(seriesSlug, matchSlug);
	const [activeTab, setActiveTab] = useState("Manhattan");
	const containerRef = useRef(null);
	useHorizontalScroll(containerRef, activeTab);
	const RenderCOmponent = {
		Manhattan: <Manhattan matchId={matchId} />,
		WormChart: <WromChat matchId={matchId} />,
		Runs: <Runs matchId={matchId} />,
		RunRates: <RunRates matchId={matchId} />,
		Wickets: <Wickets matchId={matchId} />,
	};

	return (
		<div className='p-4 mx-5 mt-5 rounded-2xl border bg-white'>
			<div className="flex text-nowrap hideScrollbar lg:justify-center gap-4 overflow-x-auto" ref={containerRef}>
				{tabs.map(({ lable, value }) => (
					<div
						key={value}
						className={`cursor-pointer px-9 py-2.5 ${activeTab === value ? "text-white bg-blue-700 rounded-xl p-1 font-medium" : "border rounded-xl"}`}
						onClick={() => setActiveTab(value)}
						data-testid={value}
					>
						{lable}
					</div>
				))}
			</div>
			<div className="mt-5">
				{activeTab && RenderCOmponent[activeTab]}
			</div>
		</div>
	)
};

export default LiveStats;
