import React, { useEffect, useState } from "react";
import next from "../../../../Assets/next-svg.svg";
import privius from "../../../../Assets/prev-svg.svg";
import { useAxiosSWR } from "root/axiosInstance";
import { ShimmerEffect } from "../../../common/ShimmerEffect";
import { getSanitizedArray } from "../../../../helpers/general";
import { useTikTik } from "src/hooks/useTikTik";
const shimmerAry = []
for (let i = 0; i < 5; i++) {
  shimmerAry.push({ image: "", isShimmer: true })
}
const Facts = () => {
  const { data, error: isError, isLoading } = useAxiosSWR("v1/misc/facts");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const factsData = getSanitizedArray(isLoading ? shimmerAry : data);
  const shimmerClassName = factsData?.[0]?.isShimmer ? "bg-gray-300 loading text-opacity-0" : "";
  useTikTik(data, setCurrentIndex);
  const handleNext = () => {
    if (currentIndex < factsData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index);
  };
  
  // Swipe logic
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX); // Record starting touch position
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX); // Record touch movement
  };

  const handleTouchEnd = () => {
    // Determine swipe direction based on start and end positions
    const swipeDistance = touchStart - touchEnd;

    if (swipeDistance > 50) {
      handleNext(); // Swipe left
    }

    if (swipeDistance < -50) {
      handlePrevious(); // Swipe right
    }
  };

  if (isError) {
    return <h2></h2>;
  }

  return (
    <div>
      <div className="md:py-4 pt-4 order-2 md:rounded-xl md:border bg-white md:mt-5 mt-1 overflow-hidden h-[380px] lg:order-1">
        <h3 className="md:text-lg text-sm mb-4 px-4 font-bold leading-[18px] text-center">🤔 Interesting Facts</h3>
        {factsData.length > 0 && (
          <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            className="relative"
          >
            <div className="md:px-4 px-[10px]">
              <ShimmerEffect
                className={`${shimmerClassName} h-[275px] w-full rounded-xl object-cover`}
                src={factsData[currentIndex].image}
                alt="Interesting Fact"
              />
            </div>
            <div className="flex justify-between items-center">
              <button
                onClick={handlePrevious}
                disabled={currentIndex === 0}
                className={`disabled:opacity-50 ${currentIndex === 0 ? "invisible" : "visible"
                  }`}
              >
                <ShimmerEffect src={privius} alt="Previous" />
              </button>
              <div className="flex justify-center flex-grow overflow-hidden">
                {factsData.map((_, index) => (
                  <div
                    key={index}
                    className={`h-1 w-6 mx-0.5 rounded-full ${index === currentIndex ? "bg-[#3A32D1]" : "bg-[#D8D6F6]"
                      } cursor-pointer`}
                    onClick={() => handleIndicatorClick(index)}
                  ></div>
                ))}
              </div>
              <button
                onClick={handleNext}
                disabled={currentIndex === factsData.length - 1}
                className={`disabled:opacity-50 ${currentIndex === factsData.length - 1
                    ? "invisible"
                    : "visible"
                  }`}
              >
                <ShimmerEffect src={next} alt="Next" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Facts;