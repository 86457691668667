import instagram from "../../../Assets/instagram.svg";
import youtube from "../../../Assets/youtube.svg";
import twitter from "../../../Assets/x(twitter).svg";
import facebook from "../../../Assets/facebook.svg";
import whatsapp from "../../../Assets/whatsapp.svg";
import telegram from "../../../Assets/telegram.svg";
import { useState } from "react";

export const socialLinksV2 = [
  {
    name: "Telegram",
    url: "https://t.me/cricketgully",
    icon: telegram,
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/cricketgullyofficial/",
    icon: instagram,
  },
  {
    name: "WhatsApp",
    icon: whatsapp,
  },
  {
    name: "Facebook",
    url: "https://www.facebook.com/cricketgully.official/",
    icon: facebook,
  },
  {
    name: "Youtube",
    url: "https://www.youtube.com/channel/UCTKws3gbVaxeLJv4yXDorVQ",
    icon: youtube,
  },
  {
    name: "Twitter",
    url: "https://x.com/thecricketgully",
    icon: twitter,
    displayName: "X (Twitter)",
  },
];

function share(social) {
  const url = window.location.href;
  if (social === "facebook") {
    const navUrl = "https://www.facebook.com/sharer/sharer.php?u=" + url;
    window.open(navUrl, "_blank");
    return;
  }

  if (social === "twitter") {
    const navUrl = "https://twitter.com/intent/tweet?text=" + url + `\n Follow us on @thecricketgully`;
    window.open(navUrl, "_blank");
    return;
  }

  if (social === "instagram") {
    const navUrl = "https://www.instagram.com/direct/new/?text=" + url;
    window.open(navUrl, "_blank");
    return;
  }

  if (social === "telegram") {
    const navUrl = "https://t.me/share/url?url=" + url;
    window.open(navUrl, "_blank");
    return;
  }

  if (social === "whatsapp") {
    const navUrl = "https://wa.me/?text=" + url;
    window.open(navUrl, "_blank");
    return;
  }
}

export const SocialLinksComponent = ({ socialLinks, className, type, closeIcon, textInputValue }) => {
  const [isCopied, setIsCopied] = useState(false);
  const isShareType = type === 'share';

  const filteredSocialLinks = isShareType
    ? [...socialLinks.filter((link) => link.name !== 'Telegram' && link.name !== 'Youtube')]
    : socialLinks.filter((link) => link.name !== 'WhatsApp');

  const handleCopyLink = () => {
    navigator.clipboard.writeText(textInputValue);
    setIsCopied(true);
  };

  const handleShareClick = (link) => {
    const social = link.name.toLowerCase();
    share(social);
  };

  return (
    <div className={className}>
      {isShareType ? (
        <div className="flex justify-between px-2 md:my-2">
          <p className='md:text-2xl text-[16px] font-semibold mx-auto'>Share</p>
          <div onClick={closeIcon.props.onClick}>
            {closeIcon}
          </div>
        </div>
      ) : (
        <p className='text-2xl text-center pb-8 md:block hidden'>Follow us for more</p>
      )}
      {!isShareType && (
        <p className='text-sm font-semibold pb-2 text-center md:hidden'>Follow us</p>
      )}
      <div className={`text-sm flex items-center justify-between ${isShareType ? 'md:py-2' : 'md:px-[55px] md:gap-10 px-4'}`}>
        {filteredSocialLinks.map((link, index) => (
          <div key={index} className="py-2 flex">
            {isShareType ? (
              <button
                className="flex flex-col items-center gap-2"
                onClick={() => handleShareClick(link)}
              >
                <img src={link.icon} alt={link.name} className="md:w-10 w-7 md:h-10 h-7 object-cover rounded-lg" />
                <p className="md:text-xs text-[10px] md:font-medium">
                  {link.displayName || link.name}
                </p>
              </button>
            ) : (
              <a
                className="flex flex-col items-center gap-2"
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={link.icon} alt={link.name} className="md:w-12 md:h-12 w-10 h-10 object-cover rounded-lg" />
                <p className="md:text-[16px] text-[10px] opacity-60 md:font-medium">
                  {link.displayName || link.name}
                </p>
              </a>
            )}
          </div>
        ))}
      </div>
      {isShareType && (
        <>
          <div className="md:text-xs text-[10px] opacity-30 text-center md:pb-4 pb-2">or</div>
          <div className="flex gap-2 items-center">
            <div className="flex-1">
              <input
                type="text"
                value={textInputValue}
                className="w-full md:py-2 md:px-6 p-2 md:text-sm text-[10px] border-2 border-[#DEDEDE] bg-[#EEEEEF] rounded-xl"
                disabled
              />
            </div>
            <div className="bg-[#3A32D1] flex items-center py-2 px-6 rounded-xl">
              <button
                className="text-xs font-medium text-[#FFFFFF]"
                onClick={handleCopyLink}
              > {isCopied ? 'Link Copied' : 'Copy Link'}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const NewsNames = {
  "top_story": "Top Stories",
  "fantasy": "Fantasy",
  "news": "Cricket News",
  "weekly_recap": "Weekly Recap"
}