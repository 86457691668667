import { useEffect, useRef } from "react";

export const useHorizontalScroll = (containerRef, selectedTestId, config = {}) => {
    const ref = useRef(0);
    const { type: calledFrom } = config;
    useEffect(() => {
        if (containerRef.current) {
            let selectedLink;
            try {
                selectedLink = containerRef.current.querySelector(`[data-testid='${selectedTestId}']`);      
                if (selectedLink) {
                    const containerRect = containerRef.current.getBoundingClientRect();
                    const buttonRect = selectedLink.getBoundingClientRect();
                    const nearLeftEdge = buttonRect.left - containerRect.left < buttonRect.width;
                    const nearRightEdge = containerRect.right - buttonRect.right < buttonRect.width;
                    if (nearLeftEdge || nearRightEdge) {
                        if (calledFrom === 'fantasy' && ref.current <= 1) {
                            ref.current++;
                            // console.log(' ref current incremented to ', ref.current);
                            return;
                        } else {
                            // calledFrom === 'fantasy' && console.log(' ref current ', ref.current, selectedTestId);
                            selectedLink.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
                        }
                    }
                }
            } catch (error) {
                // console.log('selecting link failed ', error);
            }
        }
    }, [containerRef, selectedTestId]);
}