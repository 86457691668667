import { useState, useEffect } from "react";
import { MemoizedChartBox } from "./FakeChart";

export const FakeLiveMatchChartBox = ({ data, tieChance, type }) => {
    const [isOpen, setIsOpen] = useState(type === "matchinfo");

    useEffect(() => {
        setIsOpen(type === "matchinfo");
    }, [type]);

    if (!Array.isArray(data) || data.length !== 2) {
        return null;
    }

    const [t1, t2] = data;

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="flex items-center mx-auto md:mx-0 w-max px-3 py-1 flex-col md:border border-[#DAE6F8] rounded-xl relative" onClick={toggleDropdown}>
            <div className="font-semibold">
                Real Time Win %
            </div>
            <div className="absolute top-0 right-3">
                <button
                    className="md:hidden ml-2"
                >
                    {isOpen ? 
                        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5L5 1L9 5" stroke="#797979" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> 
                        :
                        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L5 5L9 0.999999" stroke="#797979" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    }
                </button>
            </div>
            <div
                className={`flex md:gap-2 md:mt-2 bg-gradient-to-r from-[#dae6f818] via-[#DAE6F8] to-[#dae6f818] 
                transition-all duration-300 ease-in-out overflow-hidden 
                ${isOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"} 
                md:max-h-none md:opacity-100`}
            >
                <div className={`bg-white md:gap-0 gap-4 md:my-0 ${tieChance ? "my-0.5" : "mt-0.5"} flex py-3 md:py-0`}>
                    <div>
                        <MemoizedChartBox
                            percentage={t1.percentage || 0}
                            teamCode={t1.name}
                            logoUrl={t1.logoUrl}
                            team="teamA"
                        />
                    </div>
                    <div>
                        <MemoizedChartBox
                            percentage={t2.percentage || 0}
                            teamCode={t2.name}
                            logoUrl={t2.logoUrl}
                            team="teamB"
                        />
                    </div>
                </div>
            </div>
            {tieChance && (
                <div
                    className={`font-medium md:mt-2 md:text-sm 
                    transition-all duration-300 ease-in-out overflow-hidden 
                    ${isOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"} 
                    md:max-h-none md:opacity-100`}
                >
                    <span className="text-[#808080]">Tie chance</span> 0%
                </div>
            )}
        </div>
    );
};
