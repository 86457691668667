import React from "react";
import { useParams } from "react-router-dom";
import { useAxiosSWR } from "root/axiosInstance";
import CommonPointTable from "../../../../common/PointTable";
import { getSeriesMatchID } from "./common/helper";
import { pointTable } from "./common/ShimmerData";

const PointTable = () => {
  const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
  const { seriesId } = getSeriesMatchID(seriesSlug, matchSlug);
  const { data, error, isLoading } = useAxiosSWR(
    `v1/series/${seriesId}/pointTable`
  );
  const pointtableData = isLoading ? pointTable : data
  const shimmerClassName = pointtableData?.isShimmer ? "bg-gray-300 loading text-gray-300" : ""
  if (error) {
    return (
      <h2></h2>
    );
  }

  return (
    <div className=" bg-white px-5 rounded-2xl border mt-5 mx-5 pt-5">
      <CommonPointTable data={pointtableData} shimmerClassName={shimmerClassName}/>
    </div>
  );
};

export default PointTable;
