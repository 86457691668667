import React, { useState } from 'react'
import { ShimmerEffect } from './ShimmerEffect'
import { formatDate } from '../../helpers/dates'
import YouTubeModal from '../modals/YouTubeModal'
import VideoIcon from './VideoIcon'

const VideoCommon = ({ data, shimmerClassName ,isLoading }) => {
  const [open, setOpen] = useState(false);
  const [youTubeUrl, setYouTubeUrl] = useState("");
  const onClose = () => {
    setOpen(false);
  };
  const handleOpenModal = (video_url) => {
    setOpen(true);
    setYouTubeUrl(video_url);
  };
  return (
    <div>
      {data.map((obj, i) => (
          <div className={`border-[#DADADB] ${i === data?.length - 1 ? "" : "border-b"}`} key={i} onClick={() => handleOpenModal(obj.video_url)}>
            <div className="lg:grid lg:grid-cols-10 my-2">
              <div className="relative lg:col-span-3 mx-3 mt-5 md:my-3">
                <ShimmerEffect
                  src={obj.thumb_url}
                  alt="news"
                  className={`${shimmerClassName} md:h-[250px] h-[170px] lg:h-[120px] w-full object-cover rounded-xl md:mb-0 mb-2`}
                />
                <div className="absolute inset-0 flex items-center justify-center ">
                  <VideoIcon/>
                </div>
              </div>
              <div className=" lg:col-span-7 px-[10px] lg:w-auto mb-0 md:mb-3 pt-2">
                <p
                  className={`${shimmerClassName} w-full min-h-12 md:text-lg lg:text-xl font-semibold md:font-bold`}
                  dangerouslySetInnerHTML={{
                    __html: obj.title,
                  }}
                ></p>
                {obj.createdAt && <span className="text-xs text-[#6C6C6C]">
                  {formatDate(obj.createdAt)}
                </span>}
              </div>
            </div>
          </div>
      ))}
      {!isLoading && <YouTubeModal open={open} onClose={onClose} videoUrl={youTubeUrl} />}
    </div>
  )
}

export default VideoCommon
