export const SeriesAPINewsFakeResponse = {
    status: "success",
    message: null,
    data: {
        news: [
            {
                "id": 325,
                "title": "Asam Men T20",
                "slug": "asam-men-t20",
                "excerpt": "Asam Men T20",
                "isFeatured": false,
                "image": "https://cdn.cricketgully.com/news_featured/whatsapp-image-2024-04-23-at-1-722c53d1f3.jpeg",
                "shareText": "Asam Men T20\nhttps://dev.cricketgully.com/news/asam-men-t20-325?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-08-13T09:28:49.283Z",
                "type": "news"
            },
            {
                "id": 321,
                "title": "hello",
                "slug": "ww",
                "excerpt": "dd",
                "isFeatured": false,
                "image": "https://cdn.cricketgully.com/news_featured/rohit-08dfd1ae19.png",
                "shareText": "hello\nhttps://dev.cricketgully.com/news/ww-321?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-08-08T13:45:34.366Z",
                "type": "news"
            },
            {
                "id": 320,
                "title": "Rohit Sharma's Net Worth Revealed: From Cricket to Riches ",
                "slug": "hitmans-net-worth",
                "excerpt": "Rohit Sharma, a cricketing legend, boasts a net worth of approximately ₹214 crore as of March 2024. Known for his record-breaking batting feats, including the highest ODI score of 264 runs, Sharma's wealth stems from his BCCI contract, IPL earnings of nearly ₹190 crore, and lucrative endorsements fueled by his massive social media following.",
                "isFeatured": false,
                "image": "https://cdn.cricketgully.com/news_featured/rohit-413a99158b.png",
                "shareText": "Rohit Sharma's Net Worth Revealed: From Cricket to Riches \nhttps://dev.cricketgully.com/news/hitmans-net-worth-320?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-08-08T10:29:42.224Z",
                "type": "news"
            },
            {
                "id": 313,
                "title": "wall 2",
                "slug": "wall-2-news",
                "excerpt": "wall2 news",
                "isFeatured": true,
                "image": "https://cdn.cricketgully.com/news_featured/cg-spa-desktop-logo-bd657e75a5.png",
                "shareText": "wall 2\nhttps://dev.cricketgully.com/news/wall-2-news-313?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-07-29T12:04:58.520Z",
                "type": "news"
            },
            {
                "id": 283,
                "title": "This is Test news May 15",
                "slug": "this-is-test-news-may-15",
                "excerpt": "Ghana.....This is test news of may 15",
                "isFeatured": false,
                "image": "https://cdn.cricketgully.com/images/teams-icons-a0e3a13ff9.jpeg",
                "shareText": "This is Test news May 15\nhttps://dev.cricketgully.com/news/this-is-test-news-may-15-283?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-05-15T08:34:31.961Z",
                "type": "news"
            },
            {
                "id": 280,
                "title": "IPL Status Table on 5th May Morning",
                "slug": "ipl-status-tables-on-5th-may-morning",
                "excerpt": "exp",
                "isFeatured": false,
                "image": "https://cdn.cricketgully.com/images/ms-dhoni-keeper.webp",
                "shareText": "IPL Status Table on 5th May Morning\nhttps://dev.cricketgully.com/news/ipl-status-tables-on-5th-may-morning-280?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-05-03T10:45:03.254Z",
                "type": "news"
            },
            {
                "id": 249,
                "title": "CSK vs LSG, IPL 2024 Highlights: Marcus Stoinis' Unbeaten Century Powers LSG to Thrilling 6-Wicket Win Over CSK",
                "slug": "csk-vs-lsg-ipl-2024-highlights-marcus-stoinis-unbeaten-century-powers-lsg-to-thrilling-6-wicket-win-over-csk",
                "excerpt": "This is excerpt",
                "isFeatured": false,
                "image": "https://cdn.cricketgully.com/images/meme1.webp",
                "shareText": "CSK vs LSG, IPL 2024 Highlights: Marcus Stoinis' Unbeaten Century Powers LSG to Thrilling 6-Wicket Win Over CSK\nhttps://dev.cricketgully.com/news/csk-vs-lsg-ipl-2024-highlights-marcus-stoinis-unbeaten-century-powers-lsg-to-thrilling-6-wicket-win-over-csk-249?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-04-24T05:18:58.551Z",
                "type": "news"
            },



            {
                "id": 239,
                "title": "Axar, Mukesh speak out against Impact Player rule: &#8216;It only works for a batter&#8217;s convenience&#8217;",
                "slug": "axar-mukesh-speak-out-against-impact-player-rule-it-only-works-for-a-batters-convenience",
                "excerpt": "<p>Michael Hussey, CSK&#8217;s batting coach, said the rule had made IPL games &#8220;fun&#8221; to watch, but admitted that it&#8217;s &#8220;scary&#8221; from a bowler&#8217;s point of view</p>\r\n",
                "isFeatured": false,
                "image": "https://blog.cricketgully.com/wp-content/uploads/2024/04/378156-J4x69c.jpeg",
                "shareText": "Axar, Mukesh speak out against Impact Player rule: &#8216;It only works for a batter&#8217;s convenience&#8217;\nhttps://dev.cricketgully.com/news/axar-mukesh-speak-out-against-impact-player-rule-it-only-works-for-a-batters-convenience-239?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-04-22T16:06:47.000Z",
                "type": "news"
            },
            {
                "id": 241,
                "title": "&#8216;He&#8217;s too good of a player&#8217; &#8211; Ganguly, Ponting back Pant to be part of India&#8217;s T20 World Cup squad",
                "slug": "hes-too-good-of-a-player-ganguly-ponting-back-pant-to-be-part-of-indias-t20-world-cup-squad",
                "excerpt": "<p>Ponting said Pant had been a little apprehensive early in the season but has &#8220;gotten better and better every game&#821;</p>\r\n",
                "isFeatured": false,
                "image": "https://blog.cricketgully.com/wp-content/uploads/2024/04/379522-gw0mu0.jpeg",
                "shareText": "&#8216;He&#8217;s too good of a player&#8217; &#8211; Ganguly, Ponting back Pant to be part of India&#8217;s T20 World Cup squad\nhttps://dev.cricketgully.com/news/hes-too-good-of-a-player-ganguly-ponting-back-pant-to-be-part-of-indias-t20-world-cup-squad-241?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-04-22T13:53:36.000Z",
                "type": "news"
            },
            {
                "id": 233,
                "title": "Rohit on India vs Pakistan Test series overseas: &#8216;That will be awesome&#8217;",
                "slug": "rohit-on-india-vs-pakistan-test-series-overseas-that-will-be-awesome",
                "excerpt": "<p>The two teams last played a bilateral series in 2012-13, when Pakistan visited India</p>\n",
                "isFeatured": false,
                "image": "https://blog.cricketgully.com/wp-content/uploads/2024/04/368982-zgTk3M.jpeg",
                "shareText": "Rohit on India vs Pakistan Test series overseas: &#8216;That will be awesome&#8217;\nhttps://dev.cricketgully.com/news/rohit-on-india-vs-pakistan-test-series-overseas-that-will-be-awesome-233?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-04-18T10:33:44.000Z",
                "type": "news"
            },
            {
                "id": 230,
                "title": "Asha, Sajana earn maiden call-ups for Bangladesh series",
                "slug": "asha-sajana-earn-maiden-call-ups-for-bangladesh-series",
                "excerpt": "<p>Left-arm spinner Radha and allrounder Hemalatha return to the squad, Rodrigues misses out due to back niggle</p>\n",
                "isFeatured": false,
                "image": "https://blog.cricketgully.com/wp-content/uploads/2024/04/356681-I3fDkG.jpeg",
                "shareText": "Asha, Sajana earn maiden call-ups for Bangladesh series\nhttps://dev.cricketgully.com/news/asha-sajana-earn-maiden-call-ups-for-bangladesh-series-230?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-04-15T15:34:10.000Z",
                "type": "news"
            },
            {
                "id": 232,
                "title": "High-scoring KKR vs miserly Royals as IPL&#8217;s top two square off",
                "slug": "high-scoring-kkr-vs-miserly-royals-as-ipls-top-two-square-off",
                "excerpt": "<p>These are two very different teams, but they&#8217;re similar in how innovative they can be in tactical terms</p>\n",
                "isFeatured": false,
                "image": "https://blog.cricketgully.com/wp-content/uploads/2024/04/379240-onhgZp.jpeg",
                "shareText": "High-scoring KKR vs miserly Royals as IPL&#8217;s top two square off\nhttps://dev.cricketgully.com/news/high-scoring-kkr-vs-miserly-royals-as-ipls-top-two-square-off-232?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-04-15T14:40:24.000Z",
                "type": "news"
            },
            {
                "id": 227,
                "title": "&#8216;It is not in my hands&#8217; &#8211; Kishan not thinking about T20 World Cup selection",
                "slug": "it-is-not-in-my-hands-kishan-not-thinking-about-t20-world-cup-selection",
                "excerpt": "<p>&#8220;When you take a break, people gossip about it a lot. But I feel it&#8217;s important to understand not everything is in players&#8217; hands,&#8221; Kishan says</p>\n",
                "isFeatured": false,
                "image": "https://blog.cricketgully.com/wp-content/uploads/2024/04/379104-MZvjPY.jpeg",
                "shareText": "&#8216;It is not in my hands&#8217; &#8211; Kishan not thinking about T20 World Cup selection\nhttps://dev.cricketgully.com/news/it-is-not-in-my-hands-kishan-not-thinking-about-t20-world-cup-selection-227?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-04-12T10:35:46.000Z",
                "type": "news"
            },
            {
                "id": 229,
                "title": "&#8216;Don&#8217;t want to change a single bit&#8217;: the CSK transition from MS Dhoni to Ruturaj Gaikwad",
                "slug": "dont-want-to-change-a-single-bit-the-csk-transition-from-ms-dhoni-to-ruturaj-gaikwad",
                "excerpt": "<p>CSK&#8217;s new leader reflects in the early stages of this year&#8217;s IPL and how he does not believe he is batting any differently</p>\n",
                "isFeatured": false,
                "image": "https://blog.cricketgully.com/wp-content/uploads/2024/04/378983-6AMOJn.jpeg",
                "shareText": "&#8216;Don&#8217;t want to change a single bit&#8217;: the CSK transition from MS Dhoni to Ruturaj Gaikwad\nhttps://dev.cricketgully.com/news/dont-want-to-change-a-single-bit-the-csk-transition-from-ms-dhoni-to-ruturaj-gaikwad-229?utm_campaign=social_share&utm_medium=social_share",
                "date": "2024-04-09T00:17:28.000Z",
                "type": "news"
            }




        ],
        series: {
            "cid": 128777,
            "title": "India A Women Tour of Australia A Women",
            "abbr": "AUS A-W vs IND A-W",
            "season": "2024",
            "game_format": "mixed",
            "total_matches": 7,
            "date_start": "2024-08-07",
            "date_end": "2024-08-25",
            "tournament_type": "tour",
            "slug": "india-a-women-tour-of-australia-a-women-aus-a-w-vs-ind-a-w-128777"
        }
    }
}

export const MatchInfoFakeResponse78995 = {
    "status": "success",
    "message": null,
    "data": {
        "match": {
            "match_id": 78995,
            "title": "Afghan XI vs Al Mulla Exchange - B",
            "short_title": "AFG-XI vs AMB",
            "subtitle": "Match 5",
            "status": "Live",
            "date_start_utc": "2024-08-14T19:00:00.000Z",
            "date_start": "Today, 12:30 AM",
            "status_note": "Al Mulla Exchange - B won by 4 runs.",
            "format": "T10",
            "format_code": "17",
            "game_state": 0,
            "game_state_str": "Match yet to begin",
            "teama": {
                "team_id": 128572,
                "name": "Al Mulla Exchange - B",
                "short_name": "AMB",
                "logo": "https://images.entitysport.com/assets/uploads/2024/08/ALL.png",
                "scores": "76/6",
                "overs": "10",
                "max_overs": "10",
                win_per: 82,
            },
            "teamb": {
                "team_id": 128092,
                "name": "Afghan XI",
                "short_name": "AFG-XI",
                "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                "scores": "72/6",
                "overs": "10",
                "max_overs": "10",
                win_per: 18,
            },
            "teama_old": 128092,
            "teamb_old": 128572,
            "venue_id": 1213372,
            "toss": "Al Mulla Exchange - B elected to bat",
            "live_odds": null,
            "tournament_name": "KCC T10 Summer Challengers Cup",
            "tournament_abbr": "KCC T10",
            "cid": 128778,
            "venue": "Kuwait City, Kuwait",
            "match_date": "2024-08-14",
            "ball": "",
            "slug": "afghan-xi-vs-al-mulla-exchange-b-afg-xi-vs-amb-78995",
            "tournament_slug": "kcc-t10-summer-challengers-cup-kcc-t10-128778",
            "dateStrings": {
                "string1": "Today",
                "string2": "12:30 AM"
            },
            "live_inning_number": 2,
            "last_five_overs": "32/5 6.40",
            "man_of_the_match": "Jeyaprakash Prasath",
            "man_of_the_series": "",
            "match_odds": null,
            "vote_info": {
                "128572": {
                    "vote_cnt": 1,
                    "vote_per": 100
                },
                "isVoted": true,
                "totalVotes": 1
            }
        },
        "info": {
            "pitch_report": {
                "pitch_condition": "Green Pitch",
                "batting_condition": "Average",
                "pace_bowling_condition": "Pace & Bounce",
                "spine_bowling_condition": "Average"
            },
            "weather": {
                "temp": 38.22,
                "clouds": 0,
                "weather": "Clear",
                "humidity": 16,
                "visibility": 10000,
                "wind_speed": 5.84,
                "weather_desc": "clear sky"
            },
            "venueStats": {
                "inning_2": {
                    "avg_score": 117,
                    "avg_wicket": 4,
                    "avg_strike_rate": 133
                },
                "inning_1": {
                    "avg_score": 130,
                    "avg_wicket": 7,
                    "avg_strike_rate": 112
                }
            },
            "venueWinnerStats": {
                "bowl_first": {
                    "win_count": 427,
                    "win_per": 52.72
                },
                "batting_first": {
                    "win_count": 383,
                    "win_per": 47.28
                }
            }
        },
        "teamForm": [
            {
                "team_id": 128572,
                "short_name": "AMB",
                "logo": "https://images.entitysport.com/assets/uploads/2024/08/ALL.png",
                "lastFive": [
                    "W",
                    "L",
                    "L",
                    "L",
                    "L"
                ],
                "lastFiveMatches": [
                    {
                        "cid": 128778,
                        "match_id": 78995,
                        "title": "Afghan XI vs Al Mulla Exchange - B",
                        "short_title": "AFG-XI vs AMB",
                        "tournament_title": "KCC T10 Summer Challengers Cup",
                        "tournament_abbr": "KCC T10",
                        "subtitle": "Match 5",
                        "status_str": "Completed",
                        "status_note": "Al Mulla Exchange - B won by 4 runs.",
                        "format": "T10",
                        "teama": {
                            "team_id": 128092,
                            "name": "Afghan XI",
                            "short_name": "AFG-XI",
                            "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                            "scores": "72/6",
                            "overs": "10",
                            "max_overs": "10"
                        },
                        "teamb": {
                            "team_id": 128572,
                            "name": "Al Mulla Exchange - B",
                            "short_name": "AMB",
                            "logo": "https://images.entitysport.com/assets/uploads/2024/08/ALL.png",
                            "scores": "76/6",
                            "overs": "10",
                            "max_overs": "10"
                        },
                        "winning_team_id": "128572",
                        "date_start": "2024-08-14T19:00:00.000Z",
                        "slug": "afghan-xi-vs-al-mulla-exchange-b-afg-xi-vs-amb-78995",
                        "tournament_slug": "kcc-t10-summer-challengers-cup-kcc-t10-128778"
                    },
                    {
                        "cid": 128162,
                        "match_id": 70517,
                        "title": "Afghan XI vs Gujarat Cricket Club",
                        "short_title": "AFG-XI vs GCC",
                        "tournament_title": "KCC T10 Challenge Cup",
                        "tournament_abbr": "KCCT10CC",
                        "subtitle": "Match 29",
                        "status_str": "Completed",
                        "status_note": "Gujarat Cricket Club won by 7 wickets",
                        "format": "T10",
                        "teama": {
                            "team_id": 128092,
                            "name": "Afghan XI",
                            "short_name": "AFG-XI",
                            "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                            "scores": "84/7",
                            "overs": "10",
                            "max_overs": "10"
                        },
                        "teamb": {
                            "team_id": 128094,
                            "name": "Gujarat Cricket Club",
                            "short_name": "GCC",
                            "logo": "https://images.entitysport.com/assets/uploads/2023/09/Gujarat-Cricket-Club.png",
                            "scores": "89/3",
                            "overs": "7.3",
                            "max_overs": "10"
                        },
                        "winning_team_id": "128094",
                        "date_start": "2023-10-20T11:00:00.000Z",
                        "slug": "afghan-xi-vs-gujarat-cricket-club-afg-xi-vs-gcc-70517",
                        "tournament_slug": "kcc-t10-challenge-cup-kcct10cc-128162"
                    },
                    {
                        "cid": 128162,
                        "match_id": 70367,
                        "title": "Afghan XI vs KRM Panthers",
                        "short_title": "AFG-XI vs KRM",
                        "tournament_title": "KCC T10 Challenge Cup",
                        "tournament_abbr": "KCCT10CC",
                        "subtitle": "Match 21",
                        "status_str": "Completed",
                        "status_note": "KRM Panthers won by 6 wickets",
                        "format": "T10",
                        "teama": {
                            "team_id": 128092,
                            "name": "Afghan XI",
                            "short_name": "AFG-XI",
                            "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                            "scores": "96/6",
                            "overs": "10",
                            "max_overs": "10"
                        },
                        "teamb": {
                            "team_id": 127660,
                            "name": "KRM Panthers",
                            "short_name": "KRM",
                            "logo": "https://images.entitysport.com/assets/uploads/2023/06/Rectangle-3348-2023-06-06T160049.679.png",
                            "scores": "100/4",
                            "overs": "9.4",
                            "max_overs": "10"
                        },
                        "winning_team_id": "127660",
                        "date_start": "2023-10-09T19:00:00.000Z",
                        "slug": "afghan-xi-vs-krm-panthers-afg-xi-vs-krm-70367",
                        "tournament_slug": "kcc-t10-challenge-cup-kcct10cc-128162"
                    },
                    {
                        "cid": 128162,
                        "match_id": 69958,
                        "title": "Afghan XI vs GAT",
                        "short_title": "AFG-XI vs GAT",
                        "tournament_title": "KCC T10 Challenge Cup",
                        "tournament_abbr": "KCCT10CC",
                        "subtitle": "Match 16",
                        "status_str": "Completed",
                        "status_note": "GAT won by 3 wickets",
                        "format": "T10",
                        "teama": {
                            "team_id": 128092,
                            "name": "Afghan XI",
                            "short_name": "AFG-XI",
                            "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                            "scores": "79/9",
                            "overs": "10",
                            "max_overs": "10"
                        },
                        "teamb": {
                            "team_id": 128093,
                            "name": "GAT",
                            "short_name": "GAT",
                            "logo": "https://images.entitysport.com/assets/uploads/2023/09/GAT.png",
                            "scores": "80/7",
                            "overs": "9.5",
                            "max_overs": "10"
                        },
                        "winning_team_id": "128093",
                        "date_start": "2023-10-04T19:00:00.000Z",
                        "slug": "afghan-xi-vs-gat-afg-xi-vs-gat-69958",
                        "tournament_slug": "kcc-t10-challenge-cup-kcct10cc-128162"
                    },
                    {
                        "cid": 128162,
                        "match_id": 69954,
                        "title": "Cochin Hurricanes vs Afghan XI",
                        "short_title": "COH vs AFG-XI",
                        "tournament_title": "KCC T10 Challenge Cup",
                        "tournament_abbr": "KCCT10CC",
                        "subtitle": "Match 12",
                        "status_str": "Completed",
                        "status_note": "Cochin Hurricanes won by 4 wickets",
                        "format": "T10",
                        "teama": {
                            "team_id": 127776,
                            "name": "Cochin Hurricanes",
                            "short_name": "COH",
                            "logo": "https://images.entitysport.com/assets/uploads/2023/07/Rectangle-3348-28.png",
                            "scores": "90/6",
                            "overs": "9.5",
                            "max_overs": "10"
                        },
                        "teamb": {
                            "team_id": 128092,
                            "name": "Afghan XI",
                            "short_name": "AFG-XI",
                            "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                            "scores": "89/7",
                            "overs": "10",
                            "max_overs": "10"
                        },
                        "winning_team_id": "127776",
                        "date_start": "2023-09-29T04:45:00.000Z",
                        "slug": "cochin-hurricanes-vs-afghan-xi-coh-vs-afg-xi-69954",
                        "tournament_slug": "kcc-t10-challenge-cup-kcct10cc-128162"
                    }
                ]
            },
            {
                "team_id": 128092,
                "short_name": "AFG-XI",
                "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                "lastFive": [
                    "L",
                    "L"
                ],
                "lastFiveMatches": [
                    {
                        "cid": 128778,
                        "match_id": 78995,
                        "title": "Afghan XI vs Al Mulla Exchange - B",
                        "short_title": "AFG-XI vs AMB",
                        "tournament_title": "KCC T10 Summer Challengers Cup",
                        "tournament_abbr": "KCC T10",
                        "subtitle": "Match 5",
                        "status_str": "Completed",
                        "status_note": "Al Mulla Exchange - B won by 4 runs.",
                        "format": "T10",
                        "teama": {
                            "team_id": 128092,
                            "name": "Afghan XI",
                            "short_name": "AFG-XI",
                            "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                            "scores": "72/6",
                            "overs": "10",
                            "max_overs": "10"
                        },
                        "teamb": {
                            "team_id": 128572,
                            "name": "Al Mulla Exchange - B",
                            "short_name": "AMB",
                            "logo": "https://images.entitysport.com/assets/uploads/2024/08/ALL.png",
                            "scores": "76/6",
                            "overs": "10",
                            "max_overs": "10"
                        },
                        "winning_team_id": "128572",
                        "date_start": "2024-08-14T19:00:00.000Z",
                        "slug": "afghan-xi-vs-al-mulla-exchange-b-afg-xi-vs-amb-78995",
                        "tournament_slug": "kcc-t10-summer-challengers-cup-kcc-t10-128778"
                    },
                    {
                        "cid": 128778,
                        "match_id": 78991,
                        "title": "CECC-B vs Al Mulla Exchange - B",
                        "short_title": "CEC-B vs AMB",
                        "tournament_title": "KCC T10 Summer Challengers Cup",
                        "tournament_abbr": "KCC T10",
                        "subtitle": "Match 1",
                        "status_str": "Completed",
                        "status_note": "Al Mulla Exchange - B won by 27 runs.",
                        "format": "T10",
                        "teama": {
                            "team_id": 128201,
                            "name": "CECC-B",
                            "short_name": "CEC-B",
                            "logo": "https://images.entitysport.com/assets/uploads/2023/12/Rectangle-3461-2023-12-15T163758.177.png",
                            "scores": "77/2",
                            "overs": "10",
                            "max_overs": "10"
                        },
                        "teamb": {
                            "team_id": 128572,
                            "name": "Al Mulla Exchange - B",
                            "short_name": "AMB",
                            "logo": "https://images.entitysport.com/assets/uploads/2024/08/ALL.png",
                            "scores": "104/8",
                            "overs": "10",
                            "max_overs": "10"
                        },
                        "winning_team_id": "128572",
                        "date_start": "2024-08-11T19:15:00.000Z",
                        "slug": "cecc-b-vs-al-mulla-exchange-b-cec-b-vs-amb-78991",
                        "tournament_slug": "kcc-t10-summer-challengers-cup-kcc-t10-128778"
                    }
                ]
            }
        ],
        "head2head": {
            "teams": [
                {
                    "team_id": 128572,
                    "name": "Al Mulla Exchange - B",
                    "short_name": "AMB",
                    "logo": "https://images.entitysport.com/assets/uploads/2024/08/ALL.png",
                    "win_count": 1
                },
                {
                    "team_id": 128092,
                    "name": "Afghan XI",
                    "short_name": "AFG-XI",
                    "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                    "win_count": 0
                }
            ],
            "matches": [
                {
                    "cid": 128778,
                    "match_id": 78995,
                    "title": "Afghan XI vs Al Mulla Exchange - B",
                    "short_title": "AFG-XI vs AMB",
                    "tournament_title": "KCC T10 Summer Challengers Cup",
                    "tournament_abbr": "KCC T10",
                    "subtitle": "Match 5",
                    "status_str": "Completed",
                    "status_note": "Al Mulla Exchange - B won by 4 runs.",
                    "format": "T10",
                    "teama": {
                        "team_id": 128572,
                        "name": "Al Mulla Exchange - B",
                        "short_name": "AMB",
                        "logo": "https://images.entitysport.com/assets/uploads/2024/08/ALL.png",
                        "scores": "76/6",
                        "overs": "10",
                        "max_overs": "10"
                    },
                    "teamb": {
                        "team_id": 128092,
                        "name": "Afghan XI",
                        "short_name": "AFG-XI",
                        "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                        "scores": "72/6",
                        "overs": "10",
                        "max_overs": "10"
                    },
                    "winning_team_id": "128572",
                    "date_start": "2024-08-14T19:00:00.000Z",
                    "slug": "afghan-xi-vs-al-mulla-exchange-b-afg-xi-vs-amb-78995",
                    "tournament_slug": "kcc-t10-summer-challengers-cup-kcc-t10-128778"
                }
            ]
        },
        "teamComparison": {
            "teama": {
                "team_id": 128092,
                "short_name": "AFG-XI",
                "name": "Afghan XI",
                "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                "matches_played": 6,
                "matches_won": 0,
                "average_score": 84,
                "highest_score": 96,
                "lowest_score": 72
            },
            "teamb": {
                "team_id": 128572,
                "short_name": "AMB",
                "name": "Al Mulla Exchange - B",
                "logo": "https://images.entitysport.com/assets/uploads/2024/08/ALL.png",
                "matches_played": 2,
                "matches_won": 2,
                "average_score": 90,
                "highest_score": 104,
                "lowest_score": 76
            }
        },
        "teamComparisonOnVenue": {
            "teama": {
                "team_id": 128092,
                "short_name": "AFG-XI",
                "name": "Afghan XI",
                "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                "matches_played": 6,
                "matches_won": 0,
                "average_score": 84,
                "highest_score": 96,
                "lowest_score": 72
            },
            "teamb": {
                "team_id": 128572,
                "short_name": "AMB",
                "name": "Al Mulla Exchange - B",
                "logo": "https://images.entitysport.com/assets/uploads/2024/08/ALL.png",
                "matches_played": 2,
                "matches_won": 2,
                "average_score": 90,
                "highest_score": 104,
                "lowest_score": 76
            }
        },
        "recentMatchesOnVenue": [
            {
                "cid": 128778,
                "match_id": 78995,
                "title": "Afghan XI vs Al Mulla Exchange - B",
                "short_title": "AFG-XI vs AMB",
                "tournament_title": "KCC T10 Summer Challengers Cup",
                "tournament_abbr": "KCC T10",
                "subtitle": "Match 5",
                "status_str": "Completed",
                "status_note": "Al Mulla Exchange - B won by 4 runs.",
                "format": "T10",
                "teama": {
                    "team_id": 128092,
                    "name": "Afghan XI",
                    "short_name": "AFG-XI",
                    "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                    "scores": "72/6",
                    "overs": "10",
                    "max_overs": "10"
                },
                "teamb": {
                    "team_id": 128572,
                    "name": "Al Mulla Exchange - B",
                    "short_name": "AMB",
                    "logo": "https://images.entitysport.com/assets/uploads/2024/08/ALL.png",
                    "scores": "76/6",
                    "overs": "10",
                    "max_overs": "10"
                },
                "winning_team_id": "128572",
                "date_start": "2024-08-14T19:00:00.000Z",
                "slug": "afghan-xi-vs-al-mulla-exchange-b-afg-xi-vs-amb-78995",
                "tournament_slug": "kcc-t10-summer-challengers-cup-kcc-t10-128778"
            },
            {
                "cid": 128778,
                "match_id": 78994,
                "title": "CECC-B vs Ishaq Strikers",
                "short_title": "CEC-B vs IQS",
                "tournament_title": "KCC T10 Summer Challengers Cup",
                "tournament_abbr": "KCC T10",
                "subtitle": "Match 4",
                "status_str": "Completed",
                "status_note": "CECC-B won by 28 runs.",
                "format": "T10",
                "teama": {
                    "team_id": 128201,
                    "name": "CECC-B",
                    "short_name": "CEC-B",
                    "logo": "https://images.entitysport.com/assets/uploads/2023/12/Rectangle-3461-2023-12-15T163758.177.png",
                    "scores": "94/6",
                    "overs": "10",
                    "max_overs": "10"
                },
                "teamb": {
                    "team_id": 128355,
                    "name": "Ishaq Strikers",
                    "short_name": "IQS",
                    "logo": "https://images.entitysport.com/assets/uploads/2024/03/northern-1-46.png",
                    "scores": "66/7",
                    "overs": "10",
                    "max_overs": "10"
                },
                "winning_team_id": "128201",
                "date_start": "2024-08-14T17:00:00.000Z",
                "slug": "cecc-b-vs-ishaq-strikers-cec-b-vs-iqs-78994",
                "tournament_slug": "kcc-t10-summer-challengers-cup-kcc-t10-128778"
            },
            {
                "cid": 128769,
                "match_id": 78824,
                "title": "AL Mulla Exchange vs Stack CC",
                "short_title": "AEC vs SCC",
                "tournament_title": "Kuwait T10 Summer Elite Cup",
                "tournament_abbr": "Kuwait T10",
                "subtitle": "2nd Semi Final",
                "status_str": "Completed",
                "status_note": "Stack CC won by 52 runs.",
                "format": "T10",
                "teama": {
                    "team_id": 128376,
                    "name": "AL Mulla Exchange",
                    "short_name": "AEC",
                    "logo": "https://images.entitysport.com/assets/uploads/2024/03/Al-Mulla-Exchange.png",
                    "scores": "44/10",
                    "overs": "8.3",
                    "max_overs": "10"
                },
                "teamb": {
                    "team_id": 126334,
                    "name": "Stack CC",
                    "short_name": "SCC",
                    "logo": "https://images.entitysport.com/assets/uploads//2022/10/Seville.png",
                    "scores": "96/6",
                    "overs": "10",
                    "max_overs": "10"
                },
                "winning_team_id": "126334",
                "date_start": "2024-08-13T19:00:00.000Z",
                "slug": "al-mulla-exchange-vs-stack-cc-aec-vs-scc-78824",
                "tournament_slug": "kuwait-t10-summer-elite-cup-kuwait-t10-128769"
            },
            {
                "cid": 128769,
                "match_id": 78823,
                "title": "Al Hajery Team Xl vs NCM Investments",
                "short_title": "ALH vs NCMI",
                "tournament_title": "Kuwait T10 Summer Elite Cup",
                "tournament_abbr": "Kuwait T10",
                "subtitle": "1st Semi Final",
                "status_str": "Completed",
                "status_note": "Al Hajery Team Xl won by 22 runs.",
                "format": "T10",
                "teama": {
                    "team_id": 128162,
                    "name": "Al Hajery Team Xl",
                    "short_name": "ALH",
                    "logo": "https://images.entitysport.com/assets/uploads/2023/12/FC-ALJH@2x.png",
                    "scores": "146/4",
                    "overs": "10",
                    "max_overs": "10"
                },
                "teamb": {
                    "team_id": 126307,
                    "name": "NCM Investments",
                    "short_name": "NCMI",
                    "logo": "https://images.entitysport.com/assets/uploads//2022/10/NCMI-CR1@2x.png",
                    "scores": "124/7",
                    "overs": "10",
                    "max_overs": "10"
                },
                "winning_team_id": "128162",
                "date_start": "2024-08-13T17:00:00.000Z",
                "slug": "al-hajery-team-xl-vs-ncm-investments-alh-vs-ncmi-78823",
                "tournament_slug": "kuwait-t10-summer-elite-cup-kuwait-t10-128769"
            },
            {
                "cid": 128778,
                "match_id": 78993,
                "title": "Kuwait Mavericks vs Cochin Hurricanes",
                "short_title": "KUM vs COH",
                "tournament_title": "KCC T10 Summer Challengers Cup",
                "tournament_abbr": "KCC T10",
                "subtitle": "Match 3",
                "status_str": "Completed",
                "status_note": "Cochin Hurricanes won by 6 runs.",
                "format": "T10",
                "teama": {
                    "team_id": 126295,
                    "name": "Kuwait Mavericks",
                    "short_name": "KUM",
                    "logo": "https://images.entitysport.com/assets/uploads//2022/10/KUM-CR1@2x.png",
                    "scores": "122/3",
                    "overs": "10",
                    "max_overs": "10"
                },
                "teamb": {
                    "team_id": 127776,
                    "name": "Cochin Hurricanes",
                    "short_name": "COH",
                    "logo": "https://images.entitysport.com/assets/uploads/2023/07/Rectangle-3348-28.png",
                    "scores": "128/5",
                    "overs": "10",
                    "max_overs": "10"
                },
                "winning_team_id": "127776",
                "date_start": "2024-08-12T19:00:00.000Z",
                "slug": "kuwait-mavericks-vs-cochin-hurricanes-kum-vs-coh-78993",
                "tournament_slug": "kcc-t10-summer-challengers-cup-kcc-t10-128778"
            }
        ]
    }
}

function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

export const getMatch78995 = () => {
    const teamAWinPer = randomNumber(1, 100);
    const teamBWinPer = 100-teamAWinPer;

    return ({
            "status": "success",
            "message": null,
            "data": {
              "match": {
                "match_id": 78995,
                "title": "Afghan XI vs Al Mulla Exchange - B",
                "short_title": "AFG-XI vs AMB",
                "subtitle": "Match 5...",
                "status": "Live",
                "date_start_utc": "2024-08-14T19:00:00.000Z",
                "date_start": "Today, 12:30 AM",
                "status_note": "Al Mulla Exchange - B won by 4 runs.",
                "format": "T10",
                "format_code": "17",
                "game_state": 0,
                "game_state_str": "Match yet to begin",
                "teama": {
                  "team_id": 128572,
                  "name": "Al Mulla Exchange - B",
                  "short_name": "AMB",
                  "logo": "https://images.entitysport.com/assets/uploads/2024/08/ALL.png",
                  "scores": "76/6",
                  "overs": "10",
                  "max_overs": "10",
                  win_per: teamAWinPer,
                },
                "teamb": {
                  "team_id": 128092,
                  "name": "Afghan XI",
                  "short_name": "AFG-XI",
                  "logo": "https://images.entitysport.com/assets/uploads/2023/09/Afghan-XI.png",
                  "scores": "72/6",
                  "overs": "10",
                  "max_overs": "10",
                  win_per: teamBWinPer,
                },
                "teama_old": 128092,
                "teamb_old": 128572,
                "venue_id": 1213372,
                "toss": "{\"text\": \"Al Mulla Exchange - B elected to bat\", \"winner\": 128572, \"decision\": 1}",
                "live_odds": null,
                "tournament_name": "KCC T10 Summer Challengers Cup",
                "tournament_abbr": "KCC T10",
                "cid": 128778,
                "venue": "Kuwait City, Kuwait",
                "match_date": "2024-08-14",
                "ball": "",
                "slug": "afghan-xi-vs-al-mulla-exchange-b-afg-xi-vs-amb-78995",
                "tournament_slug": "kcc-t10-summer-challengers-cup-kcc-t10-128778",
                "dateStrings": {
                  "string1": "Today",
                  "string2": "12:30 AM"
                },
                "live_inning_number": 2,
                "last_five_overs": "32/5 6.40",
                "man_of_the_match": "Jeyaprakash Prasath",
                "man_of_the_series": "",
                "match_odds": null,
                "vote_info": {
                  "128572": {
                    "vote_cnt": 1,
                    "vote_per": 100
                  },
                  "isVoted": false,
                  "totalVotes": 1
                }
              },
              "info": {
                "batsmen": [
                  {
                    "name": "Wasim Qureshi",
                    "runs": 5,
                    "fours": 0,
                    "sixes": 0,
                    "batsman_id": 126694,
                    "balls_faced": 3,
                    "strike_rate": "166.67"
                  },
                  {
                    "name": "Krunal Patil",
                    "runs": 1,
                    "fours": 0,
                    "sixes": 0,
                    "batsman_id": 126702,
                    "balls_faced": 1,
                    "strike_rate": "100.00"
                  }
                ],
                "bowlers": [
                  {
                    "econ": "8.00",
                    "name": "Vishukant Tyagi",
                    "overs": 2,
                    "maidens": 0,
                    "wickets": 1,
                    "bowler_id": 135368,
                    "runs_conceded": 16
                  },
                  {
                    "econ": "4.00",
                    "name": "Shibu Omanakuttan",
                    "overs": 1,
                    "maidens": 0,
                    "wickets": 0,
                    "bowler_id": 129588,
                    "runs_conceded": 4
                  }
                ],
                "live_score": {
                  "runs": 72,
                  "overs": 10,
                  "target": 77,
                  "runrate": 7.2,
                  "wickets": 6,
                  "required_runrate": ""
                },
                "commentaries": [
                  {
                    "event": "overend",
                    "score": "72/6",
                    "over": 10,
                    "commentary": "End of over 10 (6 runs), Afghan XI 72/6",
                    "timestamp": 1723668160,
                    "odds": null,
                    "bats": [
                      {
                        "runs": 5,
                        "fours": 0,
                        "sixes": 0,
                        "batsman_id": 126694,
                        "balls_faced": 3,
                        "batsman_name": "Wasim Qureshi"
                      },
                      {
                        "runs": 1,
                        "fours": 0,
                        "sixes": 0,
                        "batsman_id": 126702,
                        "balls_faced": 1,
                        "batsman_name": "Krunal Patil"
                      }
                    ],
                    "runs": 6,
                    "bowls": [
                      {
                        "overs": 2,
                        "maidens": 0,
                        "wickets": 1,
                        "bowler_id": 135368,
                        "runs_conceded": 16,
                        "bowler_name": "Vishukant Tyagi"
                      },
                      {
                        "overs": 1,
                        "maidens": 0,
                        "wickets": 0,
                        "bowler_id": 129588,
                        "runs_conceded": 4,
                        "bowler_name": "Shibu Omanakuttan"
                      }
                    ],
                    "sr": 21
                  },
                  {
                    "event": "ball",
                    "score": 1,
                    "over": "9.6",
                    "commentary": "Vishukant Tyagi to Wasim Qureshi, 1 run, ",
                    "timestamp": 1723668150,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": 2,
                    "over": "9.5",
                    "commentary": "Vishukant Tyagi to Wasim Qureshi, 2 runs, ",
                    "timestamp": 1723668110,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": 2,
                    "over": "9.4",
                    "commentary": "Vishukant Tyagi to Wasim Qureshi, 2 runs, ",
                    "timestamp": 1723668075,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": 1,
                    "over": "9.3",
                    "commentary": "Vishukant Tyagi to Krunal Patil, 1 run, ",
                    "timestamp": 1723668013,
                    "odds": null
                  },
                  {
                    "event": "wicket",
                    "score": "w",
                    "over": "9.2",
                    "commentary": "Zafeer Anwar Ansari b V Tyagi Vishukant Tyagi to Zafeer Anwar Ansari, no run,  b V Tyagi",
                    "timestamp": 1723667916,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": 0,
                    "over": "9.1",
                    "commentary": "Vishukant Tyagi to Zafeer Anwar Ansari, no run, ",
                    "timestamp": 1723667859,
                    "odds": null
                  },
                  {
                    "event": "overend",
                    "score": "66/5",
                    "over": 9,
                    "commentary": "End of over 9 (5 runs), Afghan XI 66/5",
                    "timestamp": 1723667807,
                    "odds": null,
                    "bats": [
                      {
                        "runs": 3,
                        "fours": 0,
                        "sixes": 0,
                        "batsman_id": 120174,
                        "balls_faced": 4,
                        "batsman_name": "Irfanullah Sultanzai"
                      },
                      {
                        "runs": 2,
                        "fours": 0,
                        "sixes": 0,
                        "batsman_id": 133428,
                        "balls_faced": 3,
                        "batsman_name": "Zafeer Anwar Ansari"
                      }
                    ],
                    "runs": 5,
                    "bowls": [
                      {
                        "overs": 1,
                        "maidens": 0,
                        "wickets": 0,
                        "bowler_id": 129588,
                        "runs_conceded": 4,
                        "bowler_name": "Shibu Omanakuttan"
                      },
                      {
                        "overs": 2,
                        "maidens": 0,
                        "wickets": 2,
                        "bowler_id": 134370,
                        "runs_conceded": 17,
                        "bowler_name": "Jeyaprakash Prasath"
                      }
                    ],
                    "sr": 14
                  },
                  {
                    "event": "wicket",
                    "score": "w",
                    "over": "8.6",
                    "commentary": "Irfanullah Sultanzai runout (J Wilson / S John) Shibu Omanakuttan to Irfanullah Sultanzai, 1 run,  runout (J Wilson / S John)",
                    "timestamp": 1723667797,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": 1,
                    "over": "8.5",
                    "commentary": "Shibu Omanakuttan to Zafeer Anwar Ansari, 1 run, ",
                    "timestamp": 1723667685,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": "1lb",
                    "over": "8.4",
                    "commentary": "Shibu Omanakuttan to Irfanullah Sultanzai, 1 leg bye, ",
                    "timestamp": 1723667682,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": 1,
                    "over": "8.3",
                    "commentary": "Shibu Omanakuttan to Zafeer Anwar Ansari, 1 run, ",
                    "timestamp": 1723667627,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": 0,
                    "over": "8.2",
                    "commentary": "Shibu Omanakuttan to Zafeer Anwar Ansari, no run, ",
                    "timestamp": 1723667595,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": 1,
                    "over": "8.1",
                    "commentary": "Shibu Omanakuttan to Irfanullah Sultanzai, 1 run, ",
                    "timestamp": 1723667554,
                    "odds": null
                  },
                  {
                    "event": "overend",
                    "score": "61/4",
                    "over": 8,
                    "commentary": "End of over 8 (7 runs), Afghan XI 61/4",
                    "timestamp": 1723667509,
                    "odds": null,
                    "bats": [
                      {
                        "runs": 5,
                        "fours": 1,
                        "sixes": 0,
                        "batsman_id": 123802,
                        "balls_faced": 3,
                        "batsman_name": "Rabinsha Salam"
                      },
                      {
                        "runs": 1,
                        "fours": 0,
                        "sixes": 0,
                        "batsman_id": 120174,
                        "balls_faced": 1,
                        "batsman_name": "Irfanullah Sultanzai"
                      }
                    ],
                    "runs": 7,
                    "bowls": [
                      {
                        "overs": 2,
                        "maidens": 0,
                        "wickets": 2,
                        "bowler_id": 134370,
                        "runs_conceded": 17,
                        "bowler_name": "Jeyaprakash Prasath"
                      },
                      {
                        "overs": 1,
                        "maidens": 0,
                        "wickets": 0,
                        "bowler_id": 135368,
                        "runs_conceded": 10,
                        "bowler_name": "Vishukant Tyagi"
                      }
                    ],
                    "sr": 7
                  },
                  {
                    "event": "wicket",
                    "score": "w",
                    "over": "7.6",
                    "commentary": "Rabinsha Salam c J Wilson b J Prasath Jeyaprakash Prasath to Rabinsha Salam, no run,  c J Wilson b J Prasath",
                    "timestamp": 1723667499,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": 4,
                    "over": "7.5",
                    "commentary": "Jeyaprakash Prasath to Rabinsha Salam, Four, ",
                    "timestamp": 1723667452,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": 1,
                    "over": "7.4",
                    "commentary": "Jeyaprakash Prasath to Irfanullah Sultanzai, 1 run, ",
                    "timestamp": 1723667446,
                    "odds": null
                  },
                  {
                    "event": "wicket",
                    "score": "w",
                    "over": "7.3",
                    "commentary": "Imran Nawaz c & b J Prasath Jeyaprakash Prasath to Imran Nawaz, no run,  c & b J Prasath",
                    "timestamp": 1723667374,
                    "odds": null
                  },
                  {
                    "event": "ball",
                    "score": 1,
                    "over": "7.2",
                    "commentary": "Jeyaprakash Prasath to Rabinsha Salam, 1 run, ",
                    "timestamp": 1723667371,
                    "odds": null
                  },
                  {
                    "event": "wicket",
                    "score": "w",
                    "over": "7.1",
                    "commentary": "Fahimuddin Shareef runout (J Wilson / S John) Jeyaprakash Prasath to Fahimuddin Shareef, 1 run,  runout (J Wilson / S John)",
                    "timestamp": 1723667367,
                    "odds": null
                  },
                  {
                    "event": "overend",
                    "score": "54/1",
                    "over": 7,
                    "commentary": "End of over 7 (11 runs), Afghan XI 54/1",
                    "timestamp": 10,
                    "odds": null,
                    "bats": [
                      {
                        "runs": 13,
                        "fours": 0,
                        "sixes": 0,
                        "batsman_id": 126871,
                        "balls_faced": 18,
                        "batsman_name": "Fahimuddin Shareef"
                      },
                      {
                        "runs": 30,
                        "fours": 2,
                        "sixes": 2,
                        "batsman_id": 123846,
                        "balls_faced": 17,
                        "batsman_name": "Imran Nawaz"
                      }
                    ],
                    "runs": 11,
                    "bowls": [
                      {
                        "overs": 1,
                        "maidens": 0,
                        "wickets": 0,
                        "bowler_id": 135368,
                        "runs_conceded": 10,
                        "bowler_name": "Vishukant Tyagi"
                      },
                      {
                        "overs": 2,
                        "maidens": 0,
                        "wickets": 1,
                        "bowler_id": 135365,
                        "runs_conceded": 4,
                        "bowler_name": "Ramanathan Periyasamy"
                      }
                    ],
                    "sr": 0
                  }
                ]
              }
            }
          }
    )
}