import { useEffect } from "react";

export const useTikTik = (data, indexUpdater, interval = 6000) => {
    useEffect(() => {
        let timer;
        if (data && data.length > 1) {
            timer = setInterval(() => {
                indexUpdater(prev => {
                    const nextIndex = prev + 1;
                    if (nextIndex < data.length) {
                        return nextIndex;
                    } else {
                        return 0;
                    }
                })
            }, interval)
        }
        return () => {
            clearInterval(timer);
        }
    }, [data]);
}