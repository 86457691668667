import React from "react";
export const RunColors = {
    0: "bg-[#94A3AD]",
    1: "bg-[#4994EC]",
    2: "bg-[#5d6bd2]",
    3: "bg-[#4994EC]",
    4: "bg-[#4AA450]",
    6: "bg-[#985DD2]",
    w: "bg-[#E06C6C]",
    FH:"bg-[#FF3434]",
    NB:"bg-[#E19045]",
  };
const ShowOverScore = ({score,over}) => {
  return (
    <div className="mb-1 text-[16px] flex flex-col items-center">
      <div className="font-bold text-xs md:text-base text-[#050505]">{over}</div>
      <div
        className={`${RunColors[score] || "bg-blue-700"} ${
          score.length > 2 ? "text-xs" : "text-sm"
        } w-9 h-9 flex items-center justify-center rounded-full mt-3 md:mt-1 text-white mb-3`}
      >
        {score}
      </div>
    </div>
  );
};

export default ShowOverScore;
