import React from "react";
import { getTimeLabelForPastDateTime } from "../../../../helpers/dates";
import { Link } from "react-router-dom";
import { getNewsDetailsLinkPath, doScrollToTop } from "../../../../helpers/general";
import { ShimmerEffect } from "../../../common/ShimmerEffect";
import { shimmerData } from "./liveMatchesCarousel/helper";

const LatestNews = ({ data, isLoading, isError }) => {

    const homeNewsData = isLoading ? shimmerData : data?.data || {};
    const shimmerClassName = homeNewsData?.[0]?.isShimmer ? "bg-gray-300 loading text-opacity-0" : ""

    if (isError) {
        return <div></div>; 
    }
    return (
        <div className="bg-white md:rounded-xl md:border">
        <h2 className="md:text-lg pt-4 md:pt-0 text-sm my-4 font-bold text-center">📜 Latest News</h2>
            <div className="px-4">
                {homeNewsData.map((obj, i) => (
                    <Link key={i} to={!obj.isShimmer && getNewsDetailsLinkPath(obj.slug, obj.id, obj.type)} onClick={doScrollToTop}>
                        <div className={`my-0.5 md:my-0 grid md:grid-cols-8 grid-cols-10 items-center md:bg-transparent bg-[#F8F8F8] md:rounded-xl ${i === homeNewsData.length - 1  ? "rounded-b-2xl" : ""} ${i === 0 ? "rounded-t-2xl" : ""}`}>
                            <div className={"md:col-span-1 lg:col-span-3 col-span-2 my-4 ml-4 md:ml-2"}>
                                <ShimmerEffect src={obj.image} alt="newsImage" className={`md:w-[100px] w-[42px] h-[42px] md:h-[60px] rounded-[6px] ${shimmerClassName}`} />
                            </div>
                            <div className=" md:col-span-7 lg:col-span-5 col-span-8 md:my-4 my-2 md:ml-2">
                                <p
                                    className={`md:text-[14px] leading-4 text-[12px] font-semibold news-title ${shimmerClassName} min-h-8`}
                                    dangerouslySetInnerHTML={{ __html: obj.title }}
                                />
                                <p className={`md:text-[12px] text-[10px] leading-[14px] mt-2 font-semibold md:mt-4 text-[#6C6C6C] ${shimmerClassName}`}>
                                    {getTimeLabelForPastDateTime(obj.date)}
                                </p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>

            <>
                <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t mt-2 from-[#e2e2e2ab] to-transparent'>
                </div>
                <div className='text-[#3A32D1] text-center py-3 md:text-lg text-xs font-semibold'>
                    <Link
                       key='btnSeeMoreNews2'
                       to='/cricket-news'
                       onClick={doScrollToTop}
                    >
                        See More
                    </Link>
                </div>
            </>
        </div>
    );
};

export default LatestNews;