import React from "react";

const OverendCommentary = ({ data, teamName, score, ShimmerClassName }) => {
  return (
    <>
      <div className="md:grid md:grid-cols-5 items-center rounded-xl bg-[#EEF0F3] mt-6 w-full p-3 md:p-0 md:py-7 hidden">
        <div className="text-center border-b md:border-b-0 md:border-r border-[#D0D1D1] py-2 md:py-0 px-4">
          <div className="font-bold text-sm sm:text-base">{data.over}</div>
          <div className="sm:text-[14px] text-xs text-[#696969]">End Of Over</div>
        </div>
        <div className="text-center border-b md:border-b-0 md:border-r border-[#D0D1D1] py-2 md:py-0 px-4">
          <div className="text-[#050505] font-bold text-sm sm:text-base">{score.join(" ")}</div>
          <div className="sm:text-[14px] text-xs text-[#696969]">{`Run Scored: ${data.runs}`}</div>
        </div>
        <div className="text-center border-b md:border-b-0 md:border-r border-[#D0D1D1] py-2 md:py-0 px-4">
          <div className={`text-[#050505] font-bold text-sm sm:text-base min-h-5 sm:min-h-6 ${ShimmerClassName}`}>{`${teamName} Inn - ${data.score}`}</div>
          <div className="sm:text-[14px] text-xs text-[#696969]">{`Score After ${data.over} Overs`}</div>
        </div>
        <div className="text-center border-b md:border-b-0 md:border-r border-[#D0D1D1] py-2 md:py-0 px-4">
          {data.bats.map((batsmen, i) => (
            <div className="flex md:justify-between justify-center" key={`${batsmen} - ${i}`}>
              <span className={`sm:pr-2 text-start pr-1 md:text-base sm:text-sm text-xs text-[#696969] live-match min-w-20 min-h-4 sm:min-h-5 ${ShimmerClassName}`}>
                {batsmen.batsman_name}
              </span>
              <span className="md:text-base sm:text-sm text-xs font-bold">
                {batsmen.runs}({batsmen.balls_faced})
              </span>
            </div>
          ))}
        </div>
        <div className="text-center py-2 md:py-0 px-4">
          <div>
            <p className="md:text-base text-sm font-bold">
              {`
                 ${data.bowls[0].overs}-${data.bowls[0].maidens}-${data.bowls[0].runs_conceded}-${data.bowls[0].wickets}W
             `}
            </p>
          </div>
          <div className="sm:pr-1 pr-0.5 text-[#696969]">
            <p className={`md:text-base sm:text-sm text-xs live-match min-h-4 sm:min-h-5 ${ShimmerClassName}`}>
              {data.bowls[0].bowler_name}
            </p>
          </div>
        </div>
      </div>
      <div className="items-center mt-4 w-full md:hidden">
        <div className="bg-[#EEF0F3] rounded-t-xl p-3">
          <div className="text-center flex items-center pb-2 justify-between">
            <div className="flex items-center gap-2">
              <div className="font-bold text-xs">{data.over}</div>
              <div className="text-[10px] text-[#696969]">End Of Over</div>
            </div>
            <div className="text-[#050505] font-bold text-xs">{score.join(" ")}</div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-center flex items-center gap-2">
              <div className={`text-[#050505] font-bold text-xs sm:text-base min-h-5 sm:min-h-6 ${ShimmerClassName}`}>{`${data.score} - ${teamName} Inn `}</div>
              <div className="sm:text-[14px] text-[10px] text-[#696969]">{`Score After ${data.over} Overs`}</div>
            </div>
            <div className="text-[10px] text-[#696969]">{`${data.runs} Run`}</div>
          </div>
        </div>
        <div className="bg-[#EEF0F3] rounded-b-xl mt-[2px] p-3 flex items-center justify-between ">
          <div className="flex items-center ">
            {data.bats.map((batsmen, i) => (
              <div key={`${batsmen} - ${i}`}>
                <div className={`sm:pr-2 text-start pr-1 pb-2 text-[10px] text-[#696969] live-match min-w-20 min-h-4 sm:min-h-5 ${ShimmerClassName}`}>
                  {batsmen.batsman_name}
                </div>
                <div className="text-xs font-bold">
                  {batsmen.runs}({batsmen.balls_faced})
                </div>
              </div>
            ))}
          </div>
          <div className="text-end ">
            <div className="pb-2 pr-0.5 text-[#696969]">
              <p className={`text-[10px] live-match min-h-4 sm:min-h-5 ${ShimmerClassName}`}>
                {data.bowls[0].bowler_name}
              </p>
            </div>
            <div>
              <p className="text-xs font-bold">
                {`
                 ${data.bowls[0].overs}-${data.bowls[0].maidens}-${data.bowls[0].runs_conceded}-${data.bowls[0].wickets}W
             `}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverendCommentary;
