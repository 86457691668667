import { useEffect, useMemo, useRef, useState } from "react";
import { useHorizontalScroll } from "../../../../hooks/useHorizontalScroll";
import { days } from "../../../../helpers/constants";
import Calendar from "react-calendar";
import CalendarIcon from "../../../../Assets/calendar_month.svg";
import arrow from "../../../../Assets/arrow.svg";
import leftArrow from "../../../../Assets/arrow_left.svg";
import "react-calendar/dist/Calendar.css";
import { getDateFormate } from "./helper";
export const FilterButtons = ({ gameFormats, isSelect, onMatchTypeChange }) => {
  const containerRef = useRef(null);

  // This variable name is hack.. fix it..
  const selectedFormat = isSelect;

  useHorizontalScroll(
    containerRef,
    selectedFormat === "All" ? "ALL" : selectedFormat
  );

  return (
    <div className="md:bg-white rounded-b-xl py-1">
      <div
        ref={containerRef}
        className="overflow-auto hideScrollbar max-w-max"
      >
        <div className="gap-4 text-xs md:text-base p-1 w-max flex">
          <button
            className={`md:rounded-xl rounded-lg p-2 md:py-1.5 py-2.5 md:px-8 px-4 md:border ${isSelect === "All" ? "bg-[#3A32D1] border-[#3A32D1] text-white" : "bg-white"
              }`}
            onClick={() => onMatchTypeChange("All")}
            data-testid="ALL"
          >
            ALL
          </button>
          {gameFormats.map((type) => (
            <button
              key={type}
              className={`md:rounded-xl rounded-lg p-2 md:py-1.5 py-2.5 md:px-8 md:border px-4 ${isSelect === type ? "bg-[#3A32D1] text-white border-[#3A32D1]" : "bg-white"
                }`}
              onClick={() => onMatchTypeChange(type)}
              data-testid={type}
            >
              {type}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const CalendarBar = ({ getDateRange, dateRange, pageName, isLoading, onDateClick, uniqueDates = [] }) => {
  const fromDate = dateRange.fromDate;
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [showCalendar, setShowCalendar] = useState(false);
  const containerRef = useRef();
  const dates = useMemo(() => {
    const generatedDates = [];
    if (!fromDate) {
      return generatedDates;
    }
    const date = new Date(fromDate);
    for (let i = 0; i < 10; i++) {
      const nextDate = new Date(fromDate);

      if (pageName === "results") {
        nextDate.setDate(date.getDate() - i);
      } else {
        nextDate.setDate(date.getDate() + i);
      }
      generatedDates.push({
        date: getDateFormate(nextDate, "yyyy-mmm-dd"),
        pDate: getDateFormate(nextDate),
        day: nextDate.getDay(),
      });
    }
    return generatedDates;
  }, [fromDate, pageName]);

  useEffect(() => {
    if (containerRef.current && containerRef.current.scrollLeft !== undefined) {
      containerRef.current.scrollLeft = 0;
    }
    // Reset the selected date index when the date range changes
    setSelectedDateIndex(0);
    showCalendar && setShowCalendar(false);
  }, [dateRange]);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleCalendarClick = (date) => {
    const newDate = new Date(date);
    getDateRange(newDate);
    setShowCalendar(false);
  };

  const handleDateClick = (date, index) => {
    setSelectedDateIndex(index);
    onDateClick && onDateClick(getDateFormate(date, "YYYY-MM-DD"));
    showCalendar && setShowCalendar(false);
  };
  const minDate = pageName === "upcoming" ? new Date() : null;
  const maxDate = pageName === "results" ? new Date() : null;

  return (
    <div className="calendar">
      <div className="md:bg-[#ffffff] bg-[#312AB7] md:rounded-[20px] md:mb-6 relative">
        <div ref={containerRef} className="flex justify-between p-2 md:rounded-2xl overflow-x-auto hideScrollbar gap-3 min-h-[58px] md:border">
          {((fromDate !== getDateFormate(new Date()) && dates.length > 0) || pageName === "fantasy") && (
            <img
              src={leftArrow}
              alt="arrow"
              className="cursor-pointer"
              onClick={() => getDateRange(fromDate, "previous")}
            />
          )}
          {dates.map(({ date, day, pDate }, i) => {
            const isUniqueDate = uniqueDates.includes(getDateFormate(new Date(pDate), "yyyy-mm-dd"));
            return (
              <div
                key={i}
                title={date}
                className={`text-center cursor-pointer rounded-lg p-2 px-4 text-[#3A32D1] ${i === selectedDateIndex ? "bg-[#F5F9FF]" : "md:text-[#858585] text-white"}`}
                onClick={!isLoading ? () => handleDateClick(pDate, i) : undefined}
              >
                <div className="text-[12px] font-semibold flex gap-1 relative">
                  {days[day]}
                  <div className="text-[20px] -top-2 absolute -right-2">{isUniqueDate && "*"}</div>
                </div>
                <div className="text-[14px] font-bold">
                  {date.split("-")[2]}
                </div>
              </div>
            );
          })}
          {dates.length > 0 && (
            <img
              src={arrow}
              alt="arrow"
              className="cursor-pointer"
              onClick={!isLoading ? () => getDateRange(dateRange.toDate) : undefined}
            />
          )}
          {dates.length > 0 && (
            <img
              src={CalendarIcon}
              alt="icon"
              className="cursor-pointer sticky -right-2 md:bg-[#ffffff] bg-[#312AB7] border-l lg:ml-12 sm:px-4 px-2"
              onClick={!isLoading ? toggleCalendar : undefined}
            />
          )}
          {showCalendar && (
            <div className="absolute bg-white right-0 z-40 top-16">
              <Calendar
                value={fromDate}
                onClickDay={handleCalendarClick}
                next2Label={null}
                prev2Label={null}
                maxDate={maxDate}
                minDate={minDate}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


export default CalendarBar;