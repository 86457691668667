import React, { useState, useEffect, useCallback, useContext } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import { Link } from "react-router-dom";
import { ShimmerEffect } from "../../common/ShimmerEffect";
import SEOMeta from "../Home/common/SEOMeta";
import { doScrollToTop } from "../../../helpers/general";
import { MdChevronRight } from "react-icons/md";
import debounce from "lodash.debounce";
import AppContext, { URL_TYPES } from "src/context";
import { useLocation } from "react-router-dom";

const shimmerArray = Array(20).fill({ isShimmer: true });
const Teams = () => {
  const [pageNum, setPageNum] = useState(0);
  const [teamsData, setTeamsData] = useState([]);
  const [serachValue, setSerachValue] = useState("")
  const { data, error, isLoading } = useAxiosSWR(`/v1/teams?page=${pageNum}&search=${serachValue}`);

  const appContext = useContext(AppContext);
  const { pathname: pathName } = useLocation();
  useEffect(() => {
      const urlInfoObj = {
          type: URL_TYPES.TEAMS_HOME,
          path: pathName,
          reqState: null,
      };
      appContext.updateUrlInfo(urlInfoObj);
  }, [pathName]);


  useEffect(() => {
    if (data && data?.data) {
      setTeamsData((prevTeamsData) => [...prevTeamsData, ...data.data]);
    }
  }, [data]);
  const handleSearchChange = (value) => {
    setSerachValue(value);
    setTeamsData([]);
    setPageNum(0);
  }
  const debouncedSearch = useCallback(debounce(handleSearchChange, 250), []);
  const handleSeeMoreClick = () => setPageNum((prev) => prev + 1);
  if (error && pageNum === 0) return <div></div>;

  return (
    <div className="md:mx-0 mx-4">
      <SEOMeta slug="teams" />
      <h1 className="hidden">Teams</h1>
        <div className="p-4 mt-4 bg-white rounded-2xl border">
        <input type="text" className="border rounded-lg w-full p-2 px-4 border-[#DAE6F8]" placeholder="Search Team" onChange={(e) => debouncedSearch(e.target.value)}/>
        </div>
      <div className="mt-4 bg-white rounded-2xl">
        <div className="md:text-2xl text-lg font-semibold  md:py-6">
          <div className='md:min-h-[18px] md:min-w-[180px] md:p-0 px-5 md:py-4 pt-6 pb-2 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center'>
            <span className="custom-border-left mr-3 md:mr-6"></span>
            <h2 className='text-lg md:text-2xl'>All Teams</h2>
            <span className="custom-border-right ml-3 md:ml-6"></span>
          </div>
        </div>
        <div className="px-4 pb-4">
          <SelectedTeamPage data={isLoading && pageNum === 0 ? shimmerArray : teamsData} />
          {data?.data?.length > 0 && data?.data?.length >= 20 && (
            <div className="text-center p-2">
              <button onClick={handleSeeMoreClick} className="text-blue-700 text-sm">
                See more
              </button>
            </div>
          )}
        </div>
      </div>
      {isLoading && pageNum > 0 && <div className="mt-1"></div>}
      {error && <div></div>}
    </div>
  );
};
 
const TeamItem = ({ team }) => (
  <Link to={`/teams/${team?.slug}/home`} onClick={doScrollToTop}>
    <div className="flex items-center p-4 md:border md:border-[#DAE6F8] md:rounded-xl border-b">
      <div className="h-11 w-11 rounded-full overflow-hidden">
        {team?.isShimmer ? (
          <div className="bg-gray-300 h-full w-full loading"></div>
        ) : (
          <ShimmerEffect src={team.logo} alt="logo" width="45" height="45" />
        )}
      </div>
      <div className="ml-2 text-sm font-bold">
        {team?.isShimmer ? (
          <div className="bg-gray-300 loading h-3 w-24"></div>
        ) : (
          <span>
            {team.name} ({team.short_nname})
          </span>
        )}
      </div>
      <MdChevronRight className="ml-auto w-5 h-7 cursor-pointer" />
    </div>
  </Link>

);

const SelectedTeamPage = ({ data }) => (
  <div className="md:grid grid-cols-2 gap-4 pb-1">
    {data.map((team, i) => (
      <TeamItem key={i} team={team} />
    ))}
  </div>
);

export default Teams;
