import { useState, useCallback } from 'react';
import axiosInstance from '../../axiosInstance';

const usePost = () => {
  const [reqObj, setReqObj] = useState({
    isLoading: false,
    error: null,
    errorMessage: null,
    data: null,
  });
  
  // Just to set error manually from the consumer's side.   
  const setPostError = useCallback((error) => {
    setReqObj({ isLoading: false, error, data: null });
  }, []);

  const makeRequest = useCallback(async (url, data, customHeaders = {}) => {
    setReqObj({  isLoading: true, error : null, data: null });
    try {
      const response = await axiosInstance.post(url, data, {
        headers: {
          ...customHeaders,
        },
      });
      console.log("responce in use post",response)
      setReqObj({ isLoading: false, error: null, data: response });
      return response;
    } catch (error) {
      setReqObj({ isLoading: false, error, errorMessage: error?.response?.data?.message, data: null });
    }
  }, []);

  return { makeRequest, setPostError, ...reqObj };
};

export default usePost;


