export const NewsFAQs = ({ content }) => {
    if (!content) {
        return null;
    }
    return (
        <div className="mt-8 px-4">
            <div className="flex items-center min-w-[50px]">
                <span className="custom-border-left mr-3 md:mr-6"></span>
                <p className=' md:text-xl'>Frequently Asked Questions</p>
                <span className="custom-border-right ml-3 md:ml-6"></span>
            </div>
            <div data-testid="newsFaqs__html"
                className="md:px-[100px] my-5"
                dangerouslySetInnerHTML={{ __html: content }}
            ></div>
        </div>
    )
}