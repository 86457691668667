import React, { useEffect, useState } from 'react'
import { getSeriesMatchID } from './common/helper';
import { useParams } from 'react-router-dom';
import { useAxiosSWR } from "root/axiosInstance";
import VideoCommon from '../../../../common/VideoCommon';
import { getSanitizedArray } from '../../../../../helpers/general';
import { mockVideoData } from './common/ShimmerData';

const Video = () => {
    const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
    const { matchId } = getSeriesMatchID(seriesSlug, matchSlug);
    const [refreshInterval, setRefreshInterval] = useState(0);
    const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/videos`, {
      refreshInterval,
    });
    const LiveMatchStatus = data && data?.match?.status === "Live";
    useEffect(() => {
      if (LiveMatchStatus) {
        setRefreshInterval(5000);
      } else {
        setRefreshInterval(0);
      }
    }, [LiveMatchStatus]);
    const videoData = getSanitizedArray(isLoading ? mockVideoData : data?.info?.videos);
    if(error){
        return <div></div>
      }
    const shimmerClassName =  videoData?.[0]?.isShimmer ? "bg-gray-300 loading" : ""
    if(videoData.length === 0){
        return <div className="my-4 ml-4">No data available</div>
      }
  return (
    <div className='p-4'>
      <VideoCommon data={videoData} shimmerClassName={shimmerClassName} isLoading={isLoading}/>
    </div>
  )
}

export default Video;
