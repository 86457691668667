import { createContext } from 'react';

const AppContext = createContext();

export const URL_TYPES = {
    HOME: 'HOME',
    SCHEDULE_LIVE: 'SCHEDULE_LIVE',
    SCHEDULE_RESULT: 'SCHEDULE_RESULT',
    SCHEDULE_UPCOMING: 'SCHEDULE_UPCOMING',
    NEWS_HOME: 'NEWS_HOME',
    NEWS_DETAILS: 'NEWS_DETAILS',
    FANTASY_CATEGORY: 'FANTASY_CATEGORY',
    SERIES_HOME: 'SERIES_HOME',
    RANKING: 'RANKING',
    POINT_TABLE: 'POINT_TABLE',
    POINT_TABLE_OF_SERIES: 'POINT_TABLE_OF_SERIES',
    TEAMS_HOME: 'TEAMS_HOME',
    CATEGORY_NEWS: 'CATEGORY_NEWS',
    MATCH_INSTANCE: 'MATCH_INSTANCE',
    SERIES_INSTANCE: "SERIES_INSTANCE"
};

export function getDefaultAppContext() {
    return ({
        urlInfoByKey: {},
    })
}

export default AppContext;