import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

export const ShimmerEffect = ({ src, alt, className, width, height }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const imgRef = useRef(null);
  const observerRef = useRef(null);
  const defaultSrc = "https://cdn.cricketgully.com/images/add-a-heading-1bb1add3c0.png"; // Default image URL

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observerRef.current.disconnect();
        }
      });
    };

    observerRef.current = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (imgRef.current) {
      observerRef.current.observe(imgRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const loadImage = () => {
      const img = new Image();
      img.onload = () => {
        setImageLoaded(true);
      };
      img.onerror = () => {
        setImageLoaded(true);
      };
      img.src = src;
    };

    if (imgRef.current && !imageLoaded && isInView && src !== "") {
      setDimensions({
        width: imgRef.current.offsetWidth,
        height: imgRef.current.offsetHeight,
      });
      loadImage();
    }
  }, [src, imageLoaded, isInView]);

  return (
    <div style={{ position: 'relative' }} ref={imgRef} className={className}>
      {!imageLoaded || src === ""  ? (
        <div
          className={`absolute bg-gray-300 loading ${className}`}
          style={{
            width: dimensions.width || '100%',
            height: dimensions.height || '100%',
          }}
        ></div> 
      ) : (
        <img
          src={isInView ? src : defaultSrc}
          alt={ src !== "" ? alt : ""}
          height={height}
          width={width}
          onError={src !== "" ? (event) => {
            event.target.src = defaultSrc;
            event.onerror = null;
          } : null}
          className={`transition-opacity duration-500 ease-in-out object-cover ${imageLoaded ? "opacity-100 animate-fade-in" : "opacity-0"} ${className}`}
        />
      )}
    </div>
  );
};

ShimmerEffect.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};
