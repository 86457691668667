import React from 'react';
import { ShimmerEffect } from './common/ShimmerEffect';
import VideoIcon from './common/VideoIcon';
import YouTubeModal from './modals/YouTubeModal';
import { formatDate } from '../helpers/dates';

const VideosContainer = ({ videosData, title, open, setOpen, youTubeUrl, setYouTubeUrl, onClose, isLoading, hasError }) => {
    const handleOpenModal = (videoUrl) => {
        setOpen(true);
        setYouTubeUrl(videoUrl);
    };

    return (
        <div className="container mx-auto">
            {videosData.length > 0 ? (
                <>
                    {/* Single Video */}
                    {videosData.length > 0 && (
                        <div className="relative px-5 pb-6">
                            <div className='md:min-h-[18px] md:min-w-[180px] px-5 md:py-8 py-4 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center'>
                                <span className="custom-border-left mr-3 md:mr-6"></span>
                                <h1 className='text-lg md:text-2xl'>{title}</h1>
                                <span className="custom-border-right ml-3 md:ml-6"></span>
                            </div>
                            <div
                                onClick={() => handleOpenModal(videosData[0].video_url)}
                                className="cursor-pointer relative"
                            >
                                <ShimmerEffect
                                    src={videosData[0].thumb_url}
                                    className="w-full md:h-[408px] h-[207px] object-cover rounded-xl"
                                    alt="video_thumb_url"
                                />
                                <div
                                    className="absolute top-0 left-0 w-full h-full flex justify-center items-center"
                                >
                                    <VideoIcon />
                                </div>
                            </div>
                            <div>
                                <p className='mt-3 news-title leading-[22px]'>
                                    {videosData[0]?.title}
                                </p>
                                <p className='mt-2 text-sm leading-[16px] font-semibold text-[#6c6c6c96]'>
                                    {formatDate(videosData[0]?.createdAt)}
                                </p>
                            </div>
                        </div>
                    )}

                    {/*Remaining Videos */}
                    {videosData.length > 1 && (
                        <div className='grid grid-cols-2 items-stretch px-5'>
                            {videosData.slice(1).map((video, index) => (
                                <div key={video.id} className={`relative md:py-4 py-3 border-t ${index % 2 === 0 ? "md:pr-4 pr-3 border-r" : "md:pl-4 pl-3"}`}>
                                    <div
                                        onClick={() => handleOpenModal(video.video_url)}
                                        className="cursor-pointer relative"
                                    >
                                        <ShimmerEffect
                                            src={video.thumb_url}
                                            className="md:h-[207px] h-[110px] w-full rounded-xl object-cover"
                                            alt="video_thumb_url"
                                        />
                                        <div
                                            className="absolute top-0 left-0 w-full h-full flex justify-center items-center"
                                        >
                                            <VideoIcon />
                                        </div>
                                    </div>
                                    <div>
                                        <p className='mt-2 text-sm leading-4 min-h-8 md:text-base news-title md:min-h-12 md:leading-[22px]'>
                                            {video.title}
                                        </p>
                                        <p className='mt-1 text-[12px] md:text-sm leading-[16px] font-semibold text-[#6c6c6c96]'>
                                            {formatDate(video.createdAt)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {videosData.length === 0 && (
                        <p className="text-gray-600 text-center">No data available...</p>
                    )}

                    {!isLoading && <YouTubeModal open={open} onClose={onClose} videoUrl={youTubeUrl} />}
                </>
            ) : (
                <p className="text-center py-4">No data available...</p>
            )}
        </div>
    );
};

export default VideosContainer;
