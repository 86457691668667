import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAxiosSWR } from "root/axiosInstance";
import CommonPointTable from "../../common/PointTable";
import AppContext, { URL_TYPES } from "src/context";
import React, { useContext, useEffect } from "react";

export const PointsTableOfSeries = () => {
    const { seriesSlug } = useParams();
    const seriesId = useMemo(() => {
        if (!seriesSlug || seriesSlug.includes("-") === false) {
            return null;
        }

        const parts = seriesSlug.split("-");
        return parts[parts.length - 1];
    }, []);
    
    const { data: pointsTableData, isLoading: isFetching, error: pointsTableError } = useAxiosSWR(seriesId ? `/v1/series/${seriesId}/pointtable` : null);

    const appContext = useContext(AppContext);
    const { pathname: pathName } = useLocation();
    const seriesTitle = pointsTableData?.series?.title || "";
    useEffect(() => {
        const urlInfoObj = {
            type: URL_TYPES.POINT_TABLE_OF_SERIES,
            path: pathName,
            reqState: {
                data: {
                    title: seriesTitle,
                }
            },
        };
        appContext.updateUrlInfo(urlInfoObj);
    }, [pathName, seriesTitle]);

    if (!pointsTableData && !isFetching && !pointsTableError) {
        return null;
    }

    return (
        <>
            <div className="mt-4 bg-white rounded-2xl">
                <div className="md:text-2xl text-lg font-semibold  md:py-6">
                    <div className='md:min-h-[18px] md:min-w-[180px] md:p-0 px-2 md:py-2 pt-6 pb-2 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center'>
                        <span className="custom-border-left mr-3 md:mr-6"></span>
                        <h1 className='text-lg md:text-2xl'>{pointsTableData?.series?.title || "Series Points Table"}</h1>
                        <span className="custom-border-right ml-3 md:ml-6"></span>
                    </div>
                </div>
            </div>

            {pointsTableData && <div data-testid="common_point_table_container" className="mt-4 bg-white rounded-2xl p-5">
                <CommonPointTable data={pointsTableData} shimmerClassName="" />
            </div>}
        </>
    )
}