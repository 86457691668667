export const states = [
  { value: "", label: "Select your state" },
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "West Bengal", label: "West Bengal" },
  { value: "Bihar", label: "Bihar" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
];

export const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date string");
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  } catch (error) {
    console.error(`Error formatting date: ${error.message}`);
    return null;
  }
};